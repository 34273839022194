const langList =['zh-cn', 'en-us','md-mm'];

var locale = "zh-cn"; 

export default {
    langList: ['zh-cn', 'en-us','md-mm'],
	locale:"zh-cn",
    /**
     * 解析多语言
     * @param {Object} field 字段
     * @param {Object} route 路由对象
     */
    lang(field, route='') {//bugger;
        /* let name = "",
            module = ""
        if (route) {
            name = route.name
            module = route.meta.module
            if (route.path == "/" || route.name == "index") {
                name = "index"
                module = "index"
            }
        } else {
            name = vue.$route.name
            module = vue.$route.meta.module
        }

        if (!name || !module) return */
		var locale = localStorage.getItem('lang') || 'md-mm';
		// var locale = vue.$store.state.app.lang || 'zh-cn';
        var value = ""
        try {
			// debugger;
            // 公共语言包
			
            // var lang = require("../lang/" + locale + "/common.js");
			// console.log(lang);
			// console.log(lang.lang);
			var lang = require("../lang/" + locale + "/common.js").lang
            // 当前页面语言包
            /* let currentViewLang = require("../lang/" + locale + "/" + module + "/" + name + ".js").lang
		
            Object.assign(lang, currentViewLang) */
            var arr = field.split(".")
            if (arr.length > 1) {
				var value1 = lang;
                for (let i in arr) {
                    value1 = value1[arr[i]];
                }
				value=value1;
            } else {
                value = lang[field]
            }
        } catch (e) {
            value = field
        }
		console.log('当前翻译:'+"["+field+"]:"+value);
			// console.log('最终：'+value);
        /* if (arguments.length > 1) {
            //有参数,需要替换
            for (var i = 1; i < arguments.length; i++) {
                value = value.replace("{" + (i - 1) + "}", arguments[i])
            }
        } */

        if (value == undefined || (value == "title" && field == "title")) value = "" // field
        return value
    },
    //切换语言
    change(value) {//debugger;
        if (!vue) return
		// var $store = vue.$store;
		// // $store.state.app.lang= value;
		// var locale = $store.state.app.lang  = value//设置语言		
		// console.log(111);
		// console.log(locale);
		// // vue.$store.SET_LANG('SET_LANG',$store.state.app);
		// vue.$store.setLangValue(value);
  //       this.refresh()
		// var locale = value;
		localStorage.setItem('lang',value)		
		this.refresh()
    },
    //刷新标题
    refresh(route) {
        window.document.title = this.lang("title", route)
    },
    // 获取语言包列表
    list() {
        var list = []
        try {
            //公共语言包
            for (var i = 0; i < langList.length; i++) {
                let item = require("../lang/" + langList[i] + "/common.js").lang
                list.push({
                    name: item.common.name,
                    value: langList[i]
                })
            }
        } catch (e) {
            // "没有找到语言包:", '../../lang/' + locale + '/common.js'
        }
        return list
    },
    // 获取字段语言展示
    getLangField(field, route) {
        return this.lang(field, route) || ""
    }
}
