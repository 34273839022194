export const lang = {
	common: {
		name: '中文',
		language:"切换语言",
		currentlanguage:"当前语言"
	},
	components:{
		cargo:{
			cargo1:"人付款",
			cargo2:"库存剩余",
			cargo3:"自营",
			cargo4:"包邮",
			cargo5:"虚拟商品",
			cargo6:"收藏",
			cargo7:"加入购物车",
			cargo8:"收藏成功",
			cargo9:"商品标题"
		},
		message:{
			message1:"库存",
			message2:"销量",
			message3:"查看商品",
			message4:"订单号",
			message5:"查看订单",
			message6:"客服咨询",
			message7:"您有一个商品消息",
			message8:"您有一个订单消息",
			message9:"您有一个图片消息",
			message10:"没有聊天记录了",
			message11:"点击加载",
			message12:"客服在线",
			message13:"客服不在线",
			message14:"发送",
			message15:"最多一次可以发送150个字！",
			message16:"不能发送空白内容"
			
		},
		components1:"商品精选"
	},
	layout:{
		components:{
			components1:"购物车",
			components2:"我的订单",
			components3:"我的足迹",
			components4:"我的关注",
			components5:"我的优惠券",
			components6:"返回顶部",
			components7:"平台客服",
			components8:"您还未登录",
			components9:"友情链接",
			components10:"所有商品分类",
			components11:"商品",
			components12:"店铺",
			components13:"搜索",
			components14:"热门搜索",
			components15:"我的购物车",
			components16:"最新加入的商品",
			components17:"删除",
			components18:"共",
			components19:"种商品，总金额",
			components20:"元",
			components21:"去购物车",
			components22:"您的购物车是空的，赶快去逛逛，挑选商品吧！",
			components23:"商品",
			components24:"店铺",
			components25:"全国",
			components26:"切换城市",
			components27:"您好，请登录",
			components28:"退出",
			components29:"免费注册",
			components30:"我的订单",
			components31:"我的足迹",
			components32:"我的关注",
			components33:"帮助中心",
			components34:"公告",
			components35:"手机商城",
			components36:"欢迎您",
			components37:"所有商品分类",
			components38:"店铺首页",
			components39:"全部商品"
		},
		layout1:"欢迎登陆",
		layout2:"欢迎注册"
		
	},
	view:{
		auth:{
			find:{
				find1:"手机号不能为空",
				find2:"请输入正确的手机号",
				find3:"请输入新的登录密码",
				find4:"密码长度不能小于",
				find5:"位数",
				find6:"密码需包含",
				find7:"数字",
				find8:"、小写字母",
				find9:"、大写字母",
				find10:"、特殊字符",
				find11:"请输入确认密码",
				find12:"两次密码输入不一致",
				find13:"获取动态码",
				find14:"请输入验证码",
				find15:"请输入短信动态码",
				find16:"该手机号未注册",
				find17:"后可重新获取",
				find18:"输入手机号",
				find19:"验证身份",
				find20:"重置密码",
				find21:"完成",
				find22:"请输入注册手机号",
				find23:"下一步",
				find24:"请输入验证码",
				find25:"请输入短信动态码",
				find26:"下一步",
				find27:"请输入新的登录密码",
				find28:"请再次输入新密码",
				find29:"重置密码成功",
				find30:"重新登录"
			},
			login:{
				login1:"账号登录",
				login2:"请输入账号",
				login3:"请输入登录密码",
				login4:"请输入验证码",
				login5:"七天自动登录",
				login6:"忘记密码?",
				login7:"登录",
				login8:"没有账号?",
				login9:"立即注册",
				login10:"手机动态码登录",
				login11:"请输入手机号",
				login12:"请输入短信动态码",
				login13:"登录",
				login14:"立即注册",
				login15:"手机号不能为空",
				login16:"请输入正确的手机号",
				login17:"获取动态码",
				login18:"请输入登录账号",
				login19:"登录成功",
				login20:"后可重新获取"
			},
			auth1:"用户注册",
			auth2:"请输入用户名",
			auth3:"请输入密码",
			auth4:"请输入验证码",
			auth5:"阅读并同意",
			auth6:"服务协议",
			auth7:"注册",
			auth8:"已有账号",
			auth9:"立即登录",
			auth10:"请输入手机号",
			auth11:"请输入验证码",
			auth12:"请输入短信动态码",
			auth13:"手机号不能为空",
			auth14:"请输入正确的手机号",
			auth15:"请输入密码",
			auth16:"密码长度不能小于",
			auth17:"位数",
			auth18:"密码需包含",
			auth19:"数字",
			auth20:"、小写字母",
			auth21:"、大写字母",
			auth22:"、特殊字符",
			auth23:"获取动态码",
			auth24:"平台未启用注册",
			auth25:"请先阅读协议并勾选"
		},
		cms:{
			help:{
				help1:"首页",
				help2:"帮助列表",
				help3:"帮助详情",
				help4:"帮助"
			},
			noctice:{
				noctice1:"首页",
				noctice2:"公告列表",
				noctice3:"公告详情",
				noctice4:"公告",
				noctice5:"最新公告",
				noctice6:"商城公告",
				noctice7:"上一页",
				noctice8:"下一页"
			}
		},
		goods:{
			cart:{
				cart1:"确定要删除该商品吗?",
				cart2:"提示信息",
				cart3:"确定",
				cart4:"取消",
				cart5:"删除成功",
				cart6:"请选择要删除的商品",
				cart7:"确认要清空这些商品吗?",
				cart8:"商品信息",
				cart9:"价格",
				cart10:"数量",
				cart11:"小计",
				cart12:"操作",
				cart13:"自营",
				cart14:"删除",
				cart15:"失效商品",
				cart16:"件",
				cart17:"失效",
				cart18:"全选",
				cart19:"清除失效宝贝",
				cart20:"已选商品",
				cart21:"件",
				cart22:"合计（不含运费）",
				cart23:"结算",
				cart24:"您的购物车是空的，赶快去逛逛，挑选商品吧！"
			},
			detail:{
				detail1:"距离结束仅剩",
				detail2:"此视频暂无法播放，请稍后再试",
				detail3:"您还未登录",
				detail4:"不存在",
				detail5:"元",
				detail6:"件",
				detail7:"满",
				detail8:"减",
				detail9:"送",
				detail10:"积分",
				detail11:"送一张",
				detail12:"折优惠券",
				detail13:"优惠券",
				detail14:"包邮",
				detail15:"领取成功",
				detail16:"商品已售罄",
				detail17:"购买数量不能为0",
				detail18:"加入购物车成功",
				detail19:"商品限购",
				detail20:"现在已经购买",
				detail202:"还能购买",
				detail21:"活动已结束",
				detail22:"取消成功",
				detail23:"关注成功",
				detail24:"视频",
				detail25:"图片",
				detail26:"关注商品",
				detail27:"联系客服",
				detail28:"限时折扣",
				detail29:"天",
				detail30:"小时",
				detail31:"分钟",
				detail32:"秒",
				detail33:"销售价",
				detail34:"原价",
				detail35:"市场价",
				detail36:"累计评价",
				detail37:"累计销量",
				detail38:"运费",
				detail39:"快递免邮",
				detail40:"快递不免邮",
				detail41:"优惠券",
				detail42:"折",
				detail43:"满减",
				detail44:"配送至",
				detail45:"请选择配送地址",
				detail46:"请选择省",
				detail47:"请选择市",
				detail48:"请选择区/县",
				detail49:"服务",
				detail50:"由",
				detail51:"发货并提供售后服务",
				detail52:"数量",
				detail53:"库存",
				detail54:"由官方旗舰店发货并提供售后服务",
				detail55:"立即购买",
				detail56:"加入购物车",
				detail57:"库存不足",
				detail58:"手机购买",
				detail59:"保证服务",
				detail60:"满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费",
				detail61:"7天退换",
				detail62:"商品支持正品保障服务",
				detail63:"正品保障",
				detail64:"付款后2小时内发货",
				detail65:"两小时发货",
				detail66:"退货承诺",
				detail67:"试用中心",
				detail68:"实体验证",
				detail69:"如有商品质量问题、描述不符或未收到货等，您有权申请退款或退货，来回邮费由卖家承担",
				detail70:"消协保证",
				detail71:"自营",
				detail72:"店铺评分",
				detail73:"商品描述",
				detail74:"卖家服务",
				detail75:"发货速度",
				detail76:"分",
				detail77:"联系电话",
				detail78:"进店逛逛",
				detail79:"取消关注",
				detail80:"关注店铺",
				detail81:"组合套餐价",
				detail82:"立即购买",
				detail83:"商品详情",
				detail84:"商品属性",
				detail85:"商品评价",
				detail86:"全部评价",
				detail87:"店家回复",
				detail88:"追加评价",
				detail89:"上一页",
				detail90:"下一页",
				detail91:"该商品暂无评价哦",
				detail92:"售后保障"
			},
			list:{
				list1:"搜索结果",
				list2:"品牌",
				list3:"所有品牌",
				list4:"多选",
				list5:"综合",
				list6:"销量",
				list7:"价格",
				list8:"包邮",
				list9:"自营",
				list10:"最低价格",
				list11:"最高价格",
				list12:"确定",
				list13:"人付款",
				list14:"限时折扣",
				list15:"上一页",
				list16:"下一页",
				list17:"没有找到您想要的商品。换个条件试试吧",
				list18:"加入购物车成功",
				list19:"收藏成功",
				list20:"取消收藏成功"
			},
			goods1:"品牌专区",
			goods2:"暂无更多品牌,去首页看看吧",
			goods3:"上一页",
			goods4:"下一页",
			goods5:"暂无商品分类",
			goods6:"优惠券中心",
			goods7:"省钱更多，领券更多",
			goods8:"限时抢券",
			goods9:"叠加使用",
			goods10:"种类多样",
			goods11:"我的优惠券",
			goods12:"店铺优惠券",
			goods13:"平台优惠券",
			goods14:"折",
			goods15:"使用店铺",
			goods16:"无门槛优惠券",
			goods17:"满",
			goods18:"可使用",
			goods19:"领取之日起",
			goods20:"日内有效",
			goods21:"有效期至",
			goods22:"立即领取",
			goods23:"去使用",
			goods24:"暂无优惠券",
			goods25:"优惠券插件未安装",
			goods26:"领取成功",
			goods27:"不存在"
		},
		index:{
			index:{
				index1:"距离结束",
				index2:"商品",
				index3:"店铺",
				index4:"活动已结束",
				index5:"领取成功",
				index6:"不存在"	
			},
			components:{
				components1:"商品图片",
				components2:"品牌图片"
			},
			index1:"直接搜索",
			index2:"请输入城市名称",
			index3:"热门城市",
			index4:"按拼音首字母选择",
			index5:"全国",
			index6:"城市站点插件未安装",
			index7:"网站已关闭",
			index8:"返回上一页",
			index9:"返回首页",
			index10:"登录",
			index11:"注册",
			index12:"商城资讯",
			index13:"更多",
			index14:"商城服务",
			index15:"招商入驻",
			index16:"商家中心",
			index17:"门店管理",
			index18:"领券中心",
			index19:"更多",
			index20:"满",
			index21:"可用",
			index22:"立即领取",
			index23:"去使用",
			index24:"限时秒杀",
			index25:"更多商品",
			index26:"人付款",
			index27:"包邮",
			index28:"限时折扣",
			index29:"商品",
			index30:"店铺",
			index31:"搜索",
			index32:"我的购物车"
		},
		member:{
			security:{
				security1:"登录密码",
				security2:"互联网账号存在被盗风险，建议您定期更改密码以保护账户安全",
				security3:"修改",
				security4:"邮箱绑定",
				security5:"验证后，可用于快速找回登录密码及支付密码",
				security6:"手机验证",
				security7:"验证后，可用于快速找回登录密码及支付密码，接收账户余额变动提醒",
				security8:"修改登录密码",
				security9:"原密码",
				security10:"当前密码",
				security11:"新密码",
				security12:"确认密码",
				security13:"请确认新密码",
				security14:"保存",
				security15:"取消",
				security16:"验证码",
				security17:"请输入验证码",
				security18:"动态码",
				security19:"请输入动态码",
				security20:"点击“获取动态码”，将会向您已绑定的手机号",
				security21:"发送验证码",
				security22:"绑定邮箱",
				security23:"当前邮箱",
				security24:"邮箱",
				security25:"请输入邮箱",
				security26:"绑定手机号",
				security27:"当前手机号",
				security28:"手机号",
				security29:"绑定支付密码",
				security30:"请输入6位支付密码，建议不要使用重复或连续数字",
				security31:"验证码已发送至",
				security32:"新密码不能与原密码相同",
				security33:"请再次输入密码",
				security34:"两次输入密码不一致",
				security35:"请输入新密码",
				security36:"请输入正确的的邮箱",
				security37:"请输入正确的手机号",
				security38:"请输入原密码",
				security39:"请输入正确的邮箱",
				security40:"获取验证码",
				security41:"输入短信验证码",
				security42:"获取动态码",
				security43:"你还未绑定手机号，请先绑定手机号？",
				security44:"提示信息",
				security45:"确定",
				security46:"取消",
				security47:"修改密码成功",
				security48:"已发送",
				security49:"请勿重复点击",
				security50:"邮箱绑定成功",
				security51:"手机号绑定成功",
				security52:"请设置支付密码",
				security53:"请再次输入",
				security54:"请输入数字",
				security55:"修改支付密码成功",
				security56:"两次密码输入不一样"
			},
			member1:"我的可用余额",
			member2:"提现",
			member3:"充值",
			member4:"可提现余额",
			member5:"不可提现余额",
			member6:"来源",
			member7:"金额",
			member8:"详细说明",
			member9:"时间",
			member10:"上一页",
			member11:"下一页",
			member12:"编辑账户",
			member13:"姓名",
			member14:"请输入真实姓名",
			member15:"手机",
			member16:"请输入手机号",
			member17:"账号类型",
			member18:"请选择账号类型",
			member19:"银行名称",
			member20:"请输入银行名称",
			member21:"提现账号",
			member22:"请输入提现账号",
			member23:"保存",
			member24:"手机号不能为空",
			member25:"请输入正确的手机号",
			member26:"请输入真实姓名",
			member27:"请选择账号类型",
			member28:"请输入银行名称",
			member29:"请输入提现账号",
			member30:"账户列表",
			member31:"新增账户",
			member32:"默认",
			member33:"手机号码",
			member34:"提现账号",
			member35:"账号类型",
			member36:"银行名称",
			member37:"设为默认",
			member38:"编辑",
			member39:"删除",
			member40:"编辑收货地址",
			member41:"姓名",
			member42:"收货人姓名",
			member43:"手机",
			member44:"收货人手机号",
			member45:"电话",
			member46:"收货人固定电话",
			member47:"地址",
			member48:"请选择省",
			member49:"请选择市",
			member50:"请选择区/县",
			member51:"详细地址",
			member52:"是否默认",
			member53:"是",
			member54:"否",
			member55:"请输入收货人姓名",
			member56:"请输入详细地址",
			member57:"账户余额",
			member58:"提现申请",
			member59:"提现到",
			member60:"暂不支持微信提现，请选择支付宝",
			member61:"提现账户",
			member62:"请选择提现账户",
			member63:"提现金额",
			member64:"可提现余额为",
			member65:"最小提现金额为",
			member66:"手续费为",
			member67:"提现",
			member68:"请先添加提现方式",
			member69:"请输入提现金额",
			member70:"提现金额超出可提现金额",
			member71:"提现金额小于最低提现金额",
			member72:"提现申请成功",
			member73:"宝贝",
			member74:"您还没有关注商品哦",
			member75:"店铺",
			member76:"自营",
			member77:"商品描述",
			member78:"卖家服务",
			member79:"发货速度",
			member80:"联系电话",
			member81:"分",
			member82:"您还没有关注店铺哦！",
			member83:"取消关注成功",
			member84:"我的优惠券",
			member85:"店铺优惠券",
			member86:"平台优惠券",
			member87:"未使用",
			member88:"已使用",
			member89:"已过期",
			member90:"折",
			member91:"使用店铺",
			member92:"满",
			member93:"可用",
			member94:"无门槛优惠券",
			member95:"领取之日起",
			member96:"日内有效",
			member97:"有效期至",
			member98:"您还没有优惠券哦",
			member99:"优惠券插件未安装",
			member100:"您还没有使用过优惠券哦",
			member101:"您还没有过期优惠券哦",
			member102:"收货地址",
			member103:"添加收货地址",
			member104:"我的足迹",
			member105:"暂时没有足迹",
			member106:"会员中心",
			member107:"欢迎页",
			member108:"个人信息",
			member109:"账户安全",
			member110:"我的足迹",
			member111:"我的关注",
			member112:"交易中心",
			member113:"我的订单",
			member114:"退款/售后",
			member115:"核销台",
			member116:"核销记录",
			member117:"账户中心",
			member118:"账户余额",
			member119:"提现记录",
			member120:"我的优惠券",
			member121:"我的积分",
			member122:"账户列表",
			member123:"成长值",
			member124:"未登录",
			member125:"优惠券",
			member126:"积分",
			member127:"余额",
			member128:"我的关注",
			member129:"店铺关注",
			member130:"商品关注",
			member131:"我的订单",
			member132:"待付款",
			member133:"待发货",
			member134:"待收货",
			member135:"待评价",
			member136:"查看详情",
			member137:"您买的东西太少了，这里都空空的，快去挑选合适的商品吧",
			member138:"基本信息",
			member139:"账号",
			member140:"邮箱",
			member141:"去绑定",
			member142:"手机号",
			member143:"昵称",
			member144:"保存",
			member145:"头像预览",
			member146:"完善个人信息资料，上传头像图片有助于您结识更多的朋友。",
			member147:"头像最佳默认尺寸为120x120像素。",
			member148:"保存",
			member149:"请输入昵称",
			member150:"最大长度为30个字符",
			member151:"修改昵称成功",
			member152:"头像修改成功",
			member153:"我的积分",
			member154:"来源",
			member155:"积分",
			member156:"详细说明",
			member157:"时间",
			member158:"账户余额",
			member159:"充值套餐列表",
			member160:"充值套餐详情",
			member161:"额外赠送",
			member162:"积分",
			member163:"额外赠送",
			member164:"成长值",
			member165:"立即充值",
			member166:"套餐名称",
			member167:"面值",
			member168:"赠送积分",
			member169:"赠送成长值",
			member170:"操作",
			member171:"充值记录",
			member172:"套餐名称",
			member173:"面值",
			member174:"购买价格",
			member175:"赠送积分",
			member176:"赠送成长值",
			member177:"订单号",
			member178:"暂无相关订单",
			member179:"提现记录",
			member180:"账户类型",
			member181:"提现金额",
			member182:"提现时间",
			member183:"提现状态",
			member184:"操作",
			member185:"详情",
			member186:"暂无提现记录",
			member187:"提现详情",
			member188:"当前状态",
			member189:"交易号",
			member190:"手续费",
			member191:"申请时间",
			member192:"审核时间",
			member193:"银行名称",
			member194:"收款账号",
			member195:"拒绝理由",
			member196:"转账方式名称",
			member197:"转账时间"
		},
		order:{
			payment:{
				payment1:"收货地址",
				payment2:"添加收货地址",
				payment3:"编辑",
				payment4:"确定要删除该地址吗",
				payment5:"删除",
				payment6:"更多收货地址",
				payment7:"购买虚拟类商品需填写您的手机号，以方便商家与您联系",
				payment8:"手机号码",
				payment9:"请输入您的手机号码",
				payment10:"编辑收货地址",
				payment11:"姓名",
				payment12:"收货人姓名",
				payment13:"手机",
				payment14:"收货人手机号",
				payment15:"电话",
				payment16:"收货人固定电话（选填）",
				payment17:"地区",
				payment18:"请选择省",
				payment19:"请选择市",
				payment20:"请选择区/县",
				payment21:"详细地址",
				payment22:"取 消",
				payment23:"确 定",
				payment24:"添加发票信息",
				payment25:"发票类型",
				payment26:"发票抬头类型",
				payment27:"请填写抬头名称",
				payment28:"请填写纳税人识别号",
				payment29:"请填写发票邮寄地址",
				payment30:"请填写接收邮箱",
				payment31:"发票内容",
				payment32:"是否使用余额",
				payment33:"不使用余额",
				payment34:"使用余额",
				payment35:"商品",
				payment36:"价格",
				payment37:"数量",
				payment38:"小计",
				payment39:"配送方式",
				payment40:"请先添加收货地址",
				payment41:"商家未配置配送方式",
				payment42:"发票信息",
				payment43:"无需发票",
				payment44:"需要发票",
				payment45:"纸质发票",
				payment46:"电子发票",
				payment47:"个人",
				payment48:"企业",
				payment49:"修改",
				payment50:"请设置发票信息",
				payment51:"设置",
				payment52:"优惠券",
				payment53:"已选择优惠券，已优惠",
				payment54:"不使用优惠券",
				payment55:"店铺优惠",
				payment56:"满减",
				payment57:"满额包邮",
				payment58:"满",
				payment59:"包邮",
				payment60:"买家留言",
				payment61:"留言前建议先与商家协调一致",
				payment62:"平台优惠券",
				payment63:"已选择优惠券，已优惠",
				payment64:"不使用优惠券",
				payment65:"商品金额",
				payment66:"运费",
				payment67:"税费",
				payment68:"优惠",
				payment69:"店铺优惠券",
				payment70:"平台优惠券",
				payment71:"使用余额",
				payment72:"共",
				payment73:"件",
				payment74:"应付金额",
				payment75:"订单结算",
				payment76:"选择优惠券",
				payment77:"名称",
				payment78:"优惠",
				payment79:"折",
				payment80:"使用",
				payment81:"期限",
				payment82:"取消",
				payment83:"确认选择",
				payment84:"选择平台优惠券",
				payment85:"名称",
				payment86:"优惠",
				payment87:"使用",
				payment88:"满",
				payment89:"可用",
				payment90:"任意金额可用",
				payment91:"期限",
				payment92:"取消",
				payment93:"确认选择",
				payment94:"选择门店",
				payment95:"名称",
				payment96:"地址",
				payment97:"营业时间",
				payment98:"送达时间",
				payment99:"选择时间",
				payment100:"使用余额",
				payment101:"为了您的账户安全,请您先设置的支付密码",
				payment102:"可到'会员中心','账号安全','支付密码'中设置",
				payment103:"暂不设置",
				payment104:"立即设置",
				payment105:"支付密码",
				payment106:"忘记密码",
				payment107:"请输入手机号",
				payment108:"手机号格式错误",
				payment109:"纸质发票",
				payment110:"电子发票",
				payment111:"个人",
				payment112:"企业",
				payment113:"请输入收货人",
				payment114:"长度在 1 到 20 个字符",
				payment115:"请选择省",
				payment116:"请选择市",
				payment117:"请选择区/县",
				payment118:"请输入详细地址",
				payment119:"请选择发票类型",
				payment120:"请选择发票抬头类型",
				payment121:"请选择发票内容",
				payment122:"请填写抬头名称",
				payment123:"请填写纳税人识别号",
				payment124:"请填写发票邮寄地址",
				payment125:"请填写发票接收邮箱",
				payment126:"选择配送时间",
				payment127:"请填写完信息",
				payment128:"未获取到创建订单所需数据！",
				payment129:"满",
				payment130:"可用",
				payment131:"任意金额可用",
				payment132:"订单提交中...",
				payment133:"请输入您的手机号码",
				payment134:"请输入正确的手机号码",
				payment135:"请先选择您的收货地址",
				payment136:"未设置配送方式",
				payment137:"店铺",
				payment138:"没有可提货的门店",
				payment139:"支付中"
			},
			order1:"退款/售后",
			order2:"商品信息",
			order3:"退款金额",
			order4:"退款类型",
			order5:"退款状态",
			order6:"操作",
			order7:"退款编号",
			order8:"退款成功",
			order9:"退款中",
			order10:"退款",
			order11:"退货",
			order12:"退款详情",
			order13:"暂无相关订单",
			order14:"上一页",
			order15:"下一页",
			order16:"退款/售后",
			order17:"退款详情",
			order18:"平台维权",
			order19:"商品",
			order20:"数量",
			order21:"金额",
			order22:"退款金额",
			order23:"退款原因",
			order24:"请选择",
			order25:"退款说明",
			order26:"请输入退款说明（选填）",
			order27:"提交",
			order28:"协商记录",
			order29:"协商记录",
			order30:"买家",
			order31:"卖家",
			order32:"平台",
			order33:"商品",
			order34:"数量",
			order35:"退款金额",
			order36:"退款原因",
			order37:"退款金额",
			order38:"退款编号",
			order39:"申请时间",
			order40:"退款时间",
			order41:"未获取到该订单项退款信息",
			order42:"请选择退款原因",
			order43:"撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请",
			order44:"提示",
			order45:"确认撤销",
			order46:"暂不撤销",
			order47:"撤销成功",
			order48:"订单列表",
			order49:"订单详情",
			order50:"订单状态",
			order51:"需付款",
			order52:"评价",
			order53:"追评",
			order54:"订单信息",
			order55:"店铺",
			order56:"订单类型",
			order57:"订单编号",
			order58:"订单交易号",
			order59:"配送方式",
			order60:"创建时间",
			order61:"关闭时间",
			order62:"支付方式",
			order63:"支付时间",
			order64:"店铺活动",
			order65:"买家留言",
			order66:"收货信息",
			order67:"收货人",
			order68:"手机号码",
			order69:"收货地址",
			order70:"发票信息",
			order71:"发票类型",
			order72:"纸质发票",
			order73:"电子发票",
			order74:"个人",
			order75:"企业",
			order76:"发票抬头类型",
			order77:"发票抬头",
			order78:"发票内容",
			order79:"发票邮寄地址地址",
			order80:"发票接收邮箱",
			order81:"商品信息",
			order82:"单价",
			order83:"数量",
			order84:"小计",
			order85:"操作",
			order86:"退款",
			order87:"查看退款",
			order88:"商品金额",
			order89:"运费",
			order90:"税费",
			order91:"订单调整",
			order92:"优惠",
			order93:"优惠券金额",
			order94:"平台优惠券金额",
			order95:"使用余额",
			order96:"实付款",
			order97:"未获取到订单信息",
			order98:"订单列表",
			order99:"订单详情",
			order100:"订单状态",
			order101:"需付款",
			order102:"评价",
			order103:"追评",
			order104:"订单信息",
			order105:"店铺",
			order106:"订单类型",
			order107:"订单编号",
			order108:"订单交易号",
			order109:"配送方式",
			order110:"创建时间",
			order111:"关闭时间",
			order112:"支付方式",
			order113:"支付时间",
			order114:"店铺活动",
			order115:"买家留言",
			order116:"收货信息",
			order117:"收货人",
			order118:"手机号码",
			order119:"收货地址",
			order120:"发票信息",
			order121:"发票类型",
			order122:"纸质发票",
			order123:"电子发票",
			order124:"个人",
			order125:"企业",
			order126:"发票抬头类型",
			order127:"发票抬头",
			order128:"发票内容",
			order129:"发票邮寄地址地址",
			order130:"发票接收邮箱",
			order131:"商品信息",
			order132:"单价",
			order133:"数量",
			order134:"小计",
			order135:"操作",
			order136:"退款",
			order137:"查看退款",
			order138:"商品金额",
			order139:"运费",
			order140:"税费",
			order141:"订单调整",
			order142:"优惠",
			order143:"优惠券",
			order144:"使用余额",
			order145:"实付款",
			order146:"未获取到订单信息",
			order147:"订单列表",
			order148:"订单详情",
			order149:"订单状态",
			order150:"需付款",
			order151:"评价",
			order152:"追评",
			order153:"自提点",
			order154:"提货码",
			order155:"营业时间",
			order156:"联系方式",
			order157:"详细地址",
			order158:"商品信息",
			order159:"单价",
			order160:"数量",
			order161:"小计",
			order162:"操作",
			order163:"订单信息",
			order164:"店铺",
			order165:"订单类型",
			order166:"订单编号",
			order167:"订单交易号",
			order168:"配送方式",
			order169:"创建时间",
			order170:"关闭时间",
			order171:"支付方式",
			order172:"支付时间",
			order173:"店铺活动",
			order174:"买家留言",
			order175:"收货信息",
			order176:"收货人",
			order177:"手机号码",
			order178:"收货地址",
			order179:"发票信息",
			order180:"发票类型",
			order181:"纸质发票",
			order182:"电子发票",
			order183:"个人",
			order184:"企业",
			order185:"发票抬头类型",
			order186:"发票抬头",
			order187:"发票内容",
			order188:"发票邮寄地址地址",
			order189:"发票接收邮箱",
			order190:"商品信息",
			order191:"单价",
			order192:"数量",
			order193:"小计",
			order194:"操作",
			order195:"退款",
			order196:"查看退款",
			order197:"商品金额",
			order198:"运费",
			order199:"税费",
			order200:"订单调整",
			order201:"优惠",
			order202:"优惠券",
			order203:"使用余额",
			order204:"实付款",
			order205:"未获取到订单信息",
			order206:"订单列表",
			order207:"订单详情",
			order208:"订单状态",
			order209:"需付款",
			order210:"评价",
			order211:"追评",
			order212:"核销信息",
			order213:"核销码",
			order214:"核销状态：已核销",
			order215:"核销时间",
			order216:"订单信息",
			order217:"店铺",
			order218:"订单类型",
			order219:"订单编号",
			order220:"订单交易号",
			order221:"配送方式",
			order222:"创建时间",
			order223:"关闭时间",
			order224:"支付方式",
			order225:"支付时间",
			order226:"店铺活动",
			order227:"买家留言",
			order228:"收货信息",
			order229:"收货人",
			order230:"手机号码",
			order231:"收货地址",
			order232:"发票信息",
			order233:"发票类型",
			order234:"纸质发票",
			order235:"电子发票",
			order236:"个人",
			order237:"企业",
			order238:"发票抬头类型",
			order239:"发票抬头",
			order240:"发票内容",
			order241:"发票邮寄地址地址",
			order242:"发票接收邮箱",
			order243:"商品信息",
			order244:"单价",
			order245:"数量",
			order246:"小计",
			order247:"操作",
			order248:"退款",
			order249:"查看退款",
			order250:"商品金额",
			order251:"运费",
			order252:"税费",
			order253:"订单调整",
			order254:"优惠",
			order255:"优惠券",
			order256:"使用余额",
			order257:"实付款",
			order258:"未获取到订单信息",
			order259:"描述相符",
			order260:"好评",
			order261:"中评",
			order262:"差评",
			order263:"请在此处输入您的评价",
			order264:"请在此处输入您的追评",
			order265:"共6张，还能上传",
			order266:"张",
			order267:"配送服务",
			order268:"描述相符",
			order269:"服务态度",
			order270:"匿名",
			order271:"提交",
			order272:"上传图片最大数量为6张",
			order273:"商品的评价不能为空哦",
			order274:"商品的评价不能为空哦",
			order275:"评价成功",
			order276:"核销记录",
			order277:"商品信息",
			order278:"单价",
			order279:"数量",
			order280:"核销员",
			order281:"暂无相关订单",
			order282:"上一页",
			order283:"下一页",
			order284:"核销记录",
			order285:"核销验证",
			order286:"数量",
			order287:"核销类型",
			order288:"核销状态：已核销",
			order289:"核销人员",
			order290:"核销时间",
			order291:"确认使用",
			order292:"核销台",
			order293:"输入核销码",
			order294:"核销",
			order295:"请输入核销码",
			order296:"确认",
			order297:"退款/售后",
			order298:"退款详情",
			order299:"如果商家拒绝，你可重新发起申请",
			order300:"如果商家同意，将通过申请并退款给你",
			order301:"如果商家逾期未处理，平台将自动通过申请并退款给你",
			order302:"如果商家确认收货将会退款给你",
			order303:"如果商家拒绝收货，该次退款将会关闭，你可以重新发起退款",
			order304:"协商记录",
			order305:"退货地址",
			order306:"商品",
			order307:"数量",
			order308:"退款金额",
			order309:"退款方式",
			order310:"仅退款",
			order311:"退款退货",
			order312:"退款原因",
			order313:"退款金额",
			order314:"退款编号",
			order315:"申请时间",
			order316:"退款时间",
			order317:"平台维权",
			order318:"输入发货物流",
			order319:"物流公司",
			order320:"请输入物流公司",
			order321:"物流单号",
			order322:"请输入物流单号",
			order323:"物流说明",
			order324:"选填",
			order325:"取 消",
			order326:"确 定",
			order327:"未获取到该订单项退款信息",
			order328:"请输入物流公司",
			order329:"请输入物流单号",
			order330:"未获取到该订单项退款信息",
			order331:"撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请",
			order332:"提示",
			order333:"确认撤销",
			order334:"暂不撤销",
			order335:"撤销成功",
			order336:"我的订单",
			order337:"订单详情",
			order338:"退款",
			order339:"商品",
			order340:"数量",
			order341:"金额",
			order342:"退款类型",
			order343:"退款无需退货",
			order344:"退货退款",
			order345:"退款金额",
			order346:"退款原因",
			order347:"请选择",
			order348:"退款说明",
			order349:"请输入退款说明（选填）",
			order350:"提交",
			order351:"未获取到该订单项退款信息",
			order352:"申请成功",
			order353:"请选择退款原因",
			order354:"订单列表",
			order355:"物流详情",
			order356:"运单号码",
			order357:"物流公司",
			order358:"未获取到订单包裹信息",
			order359:"我的订单",
			order360:"全部订单",
			order361:"商品信息",
			order362:"单价",
			order363:"数量",
			order364:"实付款",
			order365:"订单状态",
			order366:"操作",
			order367:"订单号",
			order368:"订单详情",
			order369:"评价",
			order370:"追评",
			order371:"暂无相关订单",
			order372:"上一页",
			order373:"支付凭证",
			order374:"待确认"
		},
		pay:{
			pay1:"您的订单已提交成功，正在等待处理",
			pay2:"应付金额",
			pay3:"订单信息",
			pay4:"交易单号",
			pay5:"订单内容",
			pay6:"订单金额",
			pay7:"创建时间",
			pay8:"支付方式",
			pay9:"商家未配置支付方式",
			pay10:"立即支付",
			pay11:"完成支付前请根据您的情况点击下面的按钮",
			pay12:"返回首页",
			pay13:"已完成支付",
			pay14:"请用微信扫码支付",
			pay15:"支付宝支付",
			pay16:"微信支付",
			pay17:"支付成功",
			pay18:"支付失败",
			pay19:"支付金额",
			pay20:"会员中心",
			pay21:"回到首页",
			pay22:"未获取到支付信息",
			pay23: '上传支付凭证',
			pay24: '请耐心等待客服确认付款信息!',
			pay25: '账户名称',
			pay26: '开户银行',
			pay27: '银行账号'
		},
		promotion:{
			combo:{
				payment:{
					payment1:"收货地址",
					payment2:"添加收货地址",
					payment3:"编辑",
					payment4:"确定要删除该地址吗？",
					payment5:"删除",
					payment6:"更多收货地址",
					payment7:"购买虚拟类商品需填写您的手机号，以方便商家与您联系",
					payment8:"手机号码",
					payment9:"请输入您的手机号码",
					payment10:"添加收货地址",
					payment11:"编辑收货地址",
					payment12:"姓名",
					payment13:"收货人姓名",
					payment14:"手机",
					payment15:"收货人手机号",
					payment16:"电话",
					payment17:"收货人固定电话（选填）",
					payment18:"地区",
					payment19:"请选择省",
					payment20:"请选择市",
					payment21:"请选择区/县",
					payment22:"详细地址",
					payment23:"定位小区、街道、写字楼",
					payment24:"取 消",
					payment25:"确 定",
					payment26:"添加发票信息",
					payment27:"发票类型",
					payment28:"发票抬头类型",
					payment29:"请填写抬头名称",
					payment30:"请填写纳税人识别号",
					payment31:"请填写发票邮寄地址",
					payment32:"请填写接收邮箱",
					payment33:"发票内容",
					payment34:"办公用品",
					payment35:"数码",
					payment36:"是否使用余额",
					payment37:"不使用余额",
					payment38:"使用余额",
					payment39:"商品",
					payment40:"商品",
					payment41:"价格",
					payment42:"数量",
					payment43:"配送方式",
					payment44:"请先添加收货地址",
					payment45:"商家未配置配送方式",
					payment46:"优惠券",
					payment47:"已选择优惠券，已优惠",
					payment48:"不使用优惠券",
					payment49:"店铺优惠",
					payment50:"满减",
					payment51:"满额包邮",
					payment52:"活动优惠",
					payment53:"组合套餐",
					payment54:"优惠",
					payment55:"买家留言",
					payment56:"留言前建议先与商家协调一致",
					payment57:"发票",
					payment58:"不开票",
					payment59:"开票",
					payment60:"平台优惠券",
					payment61:"商品金额",
					payment62:"运费",
					payment63:"税费",
					payment64:"优惠",
					payment65:"店铺优惠券",
					payment66:"平台优惠券",
					payment67:"使用余额",
					payment68:"件，应付金额",
					payment69:"订单结算",
					payment70:"名称",
					payment71:"地址",
					payment72:"营业时间",
					payment73:"选择平台优惠券",
					payment74:"名称",
					payment75:"优惠",
					payment76:"使用",
					payment77:"满",
					payment771:"可用",
					payment78:"任意金额可用",
					payment79:"期限",
					payment80:"取消",
					payment81:"确认选择",
					payment82:"选择优惠券",
					payment83:"送达时间",
					payment84:"选择时间",
					payment85:"关闭",
					payment86:"确认选择",
					payment87:"使用余额",
					payment88:"为了您的账户安全,请您先设置的支付密码",
					payment89:"可到'会员中心','账号安全','支付密码'中设置",
					payment90:"暂不设置",
					payment91:"立即设置",
					payment92:"支付密码",
					payment93:"忘记密码",
					payment94:"请输入手机号",
					payment95:"手机号格式错误",
					payment96:"纸质发票",
					payment97:"电子发票",
					payment98:"个人",
					payment99:"企业",
					payment100:"请输入收货人",
					payment101:"长度在 1 到 20 个字符",
					payment102:"请选择省",
					payment103:"请选择市",
					payment104:"请选择区/县",
					payment105:"请输入详细地址",
					payment106:"请选择发票类型",
					payment107:"请选择发票抬头类型",
					payment108:"请选择发票内容",
					payment109:"请填写抬头名称",
					payment110:"请填写纳税人识别号",
					payment111:"请填写发票邮寄地址",
					payment112:"请填写发票接收邮箱",
					payment113:"未获取到创建订单所需数据",
					payment114:"满",
					payment115:"可用",
					payment116:"任意金额可用",
					payment117:"订单提交中",
					payment118:"请先选择您的收货地址",
					payment119:"店铺未设置配送方式",
					payment120:"店铺没有可提货的门店,请选择其他配送方式",
					payment121:"支付中"
				},
				combo1:"商品信息",
				combo2:"价格",
				combo3:"数量",
				combo4:"库存不足，剩余",
				combo5:"购买数量",
				combo6:"为您节省",
				combo7:"套餐价",
				combo8:"立即购买"
			},
			groupbuy:{
				detail:{
					detail1:"距离结束仅剩",
					detail2:"此视频暂无法播放，请稍后再试",
					detail3:"团购插件未安装",
					detail4:"活动已结束",
					detail5:"件",
					detail6:"您还未登录",
					detail7:"商品已售罄",
					detail8:"购买数量不能为0",
					detail9:"团购活动已结束",
					detail10:"取消成功",
					detail11:"关注成功",
					detail12:"视频",
					detail13:"图片",
					detail14:"关注商品",
					detail15:"联系客服",
					detail16:"团购",
					detail17:"天",
					detail18:"小时",
					detail19:"分钟",
					detail20:"秒",
					detail21:"团购价",
					detail22:"原价",
					detail23:"累计评价",
					detail24:"累计销量",
					detail25:"快递免邮",
					detail26:"快递不免邮",
					detail27:"配送至",
					detail28:"请选择配送地址",
					detail29:"请选择省",
					detail30:"请选择市",
					detail31:"请选择区/县",
					detail32:"服务",
					detail33:"发货并提供售后服务",
					detail34:"数量",
					detail35:"库存",
					detail36:"起购",
					detail37:"立即抢购",
					detail38:"该商品已下架",
					detail39:"二维码图片",
					detail40:"保证服务",
					detail41:"满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费",
					detail42:"7天退换",
					detail43:"商品支持正品保障服务",
					detail44:"正品保障",
					detail45:"付款后2小时内发货",
					detail46:"两小时发货",
					detail47:"退货承诺",
					detail48:"试用中心",
					detail49:"实体验证",
					detail50:"如有商品质量问题、描述不符或未收到货等，您有权申请退款或退货，来回邮费由卖家承担",
					detail51:"消协保证",
					detail52:"自营",
					detail53:"店铺评分",
					detail54:"商品描述",
					detail55:"卖家服务",
					detail56:"发货速度",
					detail57:"联系电话",
					detail58:"进店逛逛",
					detail59:"取消关注",
					detail60:"关注店铺",
					detail61:"商品详情",
					detail62:"商品属性",
					detail63:"商品评价",
					detail64:"全部评价",
					detail65:"店家回复",
					detail66:"追加评价",
					detail67:"上一页",
					detail68:"下一页",
					detail69:"该商品暂无评价哦"
				},
				payment:{
					payment1:"收货地址",
					payment2:"添加收货地址",
					payment3:"编辑",
					payment4:"删除",
					payment5:"更多收货地址",
					payment6:"购买虚拟类商品需填写您的手机号，以方便商家与您联系",
					payment7:"手机号码",
					payment8:"请输入您的手机号码",
					payment9:"添加收货地址",
					payment10:"编辑收货地址",
					payment11:"姓名",
					payment12:"收货人姓名",
					payment13:"手机",
					payment14:"收货人手机号",
					payment15:"电话",
					payment16:"收货人固定电话（选填）",
					payment17:"地区",
					payment18:"请选择省",
					payment19:"请选择市",
					payment20:"请选择区/县",
					payment21:"详细地址",
					payment22:"定位小区、街道、写字楼",
					payment23:"取 消",
					payment24:"确 定",
					payment25:"添加发票信息",
					payment26:"发票类型",
					payment27:"发票抬头类型",
					payment28:"请填写抬头名称",
					payment29:"请填写纳税人识别号",
					payment30:"请填写发票邮寄地址",
					payment31:"请填写接收邮箱",
					payment32:"发票内容",
					payment33:"办公用品",
					payment34:"数码",
					payment35:"取 消",
					payment36:"确 定",
					payment37:"是否使用余额",
					payment38:"不使用余额",
					payment39:"使用余额",
					payment40:"价格",
					payment41:"数量",
					payment42:"配送方式",
					payment43:"请先添加收货地址",
					payment44:"商家未配置配送方式",
					payment45:"店铺活动",
					payment46:"团购",
					payment47:"件起,享团购价",
					payment48:"买家留言",
					payment49:"留言前建议先与商家协调一致",
					payment50:"发票",
					payment51:"不开票",
					payment52:"开票",
					payment53:"商品金额",
					payment54:"运费",
					payment55:"税费",
					payment56:"优惠",
					payment57:"使用余额",
					payment58:"件，应付金额",
					payment59:"订单结算",
					payment60:"选择门店",
					payment61:"名称",
					payment62:"地址",
					payment63:"营业时间",
					payment64:"送达时间",
					payment65:"选择时间",
					payment66:"关闭",
					payment67:"确认选择",
					payment68:"使用余额",
					payment69:"为了您的账户安全,请您先设置的支付密码",
					payment70:"可到'会员中心','账号安全','支付密码'中设置",
					payment71:"暂不设置",
					payment72:"立即设置",
					payment73:"支付密码",
					payment74:"忘记密码",
					payment75:"请输入手机号",
					payment76:"手机号格式错误",
					payment77:"纸质发票",
					payment78:"电子发票",
					payment79:"个人",
					payment80:"企业",
					payment81:"请输入收货人",
					payment82:"长度在 1 到 20 个字符",
					payment83:"请选择省",
					payment84:"请选择市",
					payment85:"请选择区/县",
					payment86:"请输入详细地址",
					payment87:"请选择发票类型",
					payment88:"请选择发票抬头类型",
					payment89:"请选择发票内容",
					payment90:"请填写抬头名称",
					payment91:"请填写纳税人识别号",
					payment92:"请填写发票邮寄地址",
					payment93:"请填写发票接收邮箱",
					payment94:"选择配送时间",
					payment95:"未获取到创建订单所需数据",
					payment96:"订单提交中",
					payment97:"请输入您的手机号码",
					payment98:"请输入正确的手机号码",
					payment99:"请先选择您的收货地址",
					payment100:"店铺",
					payment101:"未设置配送方式",
					payment102:"没有可提货的门店,请选择其他配送方式",
					payment103:"支付中"
				},
				groupbuy1:"团购进行中",
				groupbuy2:"团购价",
				groupbuy3:"暂无正在进行团购的商品，去首页看看吧",
				groupbuy4:"上一页",
				groupbuy5:"下一页",
				groupbuy6:"团购插件未安装"
			},
			seckill:{
				detail:{
					detail1:"距离结束仅剩",
					detail2:"此视频暂无法播放，请稍后再试",
					detail3:"秒杀插件未安装",
					detail4:"限时秒杀活动还未开始",
					detail5:"您还未登录",
					detail6:"商品已售罄",
					detail7:"购买数量不能为0",
					detail8:"活动已结束",
					detail9:"限时秒杀活动已结束",
					detail10:"取消成功",
					detail11:"关注成功",
					detail12:"视频",
					detail13:"图片",
					detail14:"关注商品",
					detail15:"联系客服",
					detail16:"限时秒杀",
					detail17:"天",
					detail18:"小时",
					detail19:"分钟",
					detail20:"秒",
					detail21:"秒杀价",
					detail22:"原价",
					detail23:"累计评价",
					detail24:"累计销量",
					detail25:"运费",
					detail26:"快递免邮",
					detail27:"快递不免邮",
					detail28:"配送至",
					detail29:"请选择配送地址",
					detail30:"请选择省",
					detail31:"请选择市",
					detail32:"请选择区/县",
					detail33:"服务",
					detail34:"发货并提供售后服务",
					detail35:"数量",
					detail36:"库存",
					detail37:"立即抢购",
					detail38:"该商品已下架",
					detail39:"商家服务",
					detail40:"保证服务",
					detail41:"满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费",
					detail42:"7天退换",
					detail43:"正品保障",
					detail44:"两小时发货",
					detail45:"退货承诺",
					detail46:"试用中心",
					detail47:"实体验证",
					detail48:"如有商品质量问题、描述不符或未收到货等，您有权申请退款或退货，来回邮费由卖家承担",
					detail49:"消协保证",
					detail50:"自营",
					detail51:"店铺评分",
					detail52:"商品描述",
					detail53:"卖家服务",
					detail54:"发货速度",
					detail55:"分",
					detail56:"联系电话",
					detail57:"进店逛逛",
					detail58:"取消关注",
					detail59:"关注店铺",
					detail60:"商品详情",
					detail61:"商品属性",
					detail62:"商品评价",
					detail63:"全部评价",
					detail64:"店家回复",
					detail65:"追加评价",
					detail66:"店家回复",
					detail67:"上一页",
					detail68:"下一页",
					detail69:"该商品暂无评价哦"
				},
				list:{
					list1:"敬请期待",
					list2:"即将开始",
					list3:"抢购中",
					list4:"已结束",
					list5:"敬请期待",
					list6:"秒杀价",
					list7:"立即抢购",
					list8:"上一页",
					list9:"下一页",
					list10:"暂无正在进行秒杀的商品，去首页看看吧",
					list11:"距离结束仅剩",
					list12:"秒杀插件未安装",
					list13:"秒杀已结束'",
					list14:"秒杀未开始",
					list15:"活动已结束秒杀活动还未开启，敬请期待"
				},
				payment:{
					payment1:"收货地址",
					payment2:"添加收货地址",
					payment3:"编辑",
					payment4:"确定要删除该地址吗",
					payment5:"删除",
					payment6:"更多收货地址",
					payment7:"购买虚拟类商品需填写您的手机号，以方便商家与您联系",
					payment8:"手机号码",
					payment9:"请输入您的手机号码",
					payment10:"添加收货地址",
					payment11:"编辑收货地址",
					payment12:"姓名",
					payment13:"收货人姓名",
					payment14:"手机",
					payment15:"收货人手机号",
					payment16:"电话",
					payment17:"收货人固定电话（选填）",
					payment18:"地区",
					payment19:"请选择省",
					payment20:"请选择市",
					payment21:"请选择区/县",
					payment22:"定位小区、街道、写字楼",
					payment23:"取 消",
					payment24:"确 定",
					payment25:"添加发票信息",
					payment26:"发票类型",
					payment27:"发票抬头类型",
					payment28:"请填写抬头名称",
					payment29:"请填写纳税人识别号",
					payment30:"请填写发票邮寄地址",
					payment31:"请填写接收邮箱",
					payment32:"发票内容",
					payment33:"办公用品",
					payment34:"数码",
					payment35:"取 消",
					payment36:"确 定",
					payment37:"是否使用余额",
					payment38:"不使用余额",
					payment39:"使用余额",
					payment40:"商品",
					payment41:"价格",
					payment42:"数量",
					payment43:"配送方式",
					payment44:"请先添加收货地址",
					payment45:"商家未配置配送方式",
					payment46:"店铺活动",
					payment47:"限时秒杀",
					payment48:"买家留言",
					payment49:"发票",
					payment50:"不开票",
					payment51:"开票",
					payment52:"商品金额",
					payment53:"运费",
					payment54:"税费",
					payment55:"优惠",
					payment56:"使用余额",
					payment57:"件，应付金额",
					payment58:"订单结算",
					payment59:"名称",
					payment60:"地址",
					payment61:"营业时间",
					payment62:"送达时间",
					payment63:"选择时间",
					payment64:"关闭",
					payment65:"确认选择",
					payment66:"使用余额",
					payment67:"为了您的账户安全,请您先设置的支付密码",
					payment68:"可到'会员中心,'账号安全','支付密码'中设置",
					payment69:"暂不设置",
					payment70:"立即设置",
					payment71:"忘记密码",
					payment72:"请输入手机号",
					payment73:"手机号格式错误",
					payment74:"纸质发票",
					payment75:"电子发票",
					payment76:"个人",
					payment77:"企业",
					payment78:"请输入收货人",
					payment79:"长度在 1 到 20 个字符",
					payment80:"请选择省",
					payment81:"请选择市",
					payment82:"请选择区/县",
					payment83:"请输入详细地址",
					payment84:"请选择发票类型",
					payment85:"请选择发票抬头类型",
					payment86:"请选择发票内容",
					payment87:"请填写抬头名称",
					payment88:"请填写纳税人识别号",
					payment89:"请填写发票邮寄地址",
					payment90:"请填写发票接收邮箱选择配送时间未获取到创建订单所需数据！", 
					payment91:"未获取到创建订单所需数据",
					payment92:"订单提交中",
					payment93:"请输入您的手机号码",
					payment94:"请输入正确的手机号码",
					payment95:"请先选择您的收货地址",
					payment96:"店铺",
					payment97:"未设置配送方式",
					payment98:"店铺",
					payment99:"没有可提货的门店,请选择其他配送方式'"
				}
			
			},
			topic:{
				topic1:"专题活动详情",
				topic2:"商品详情"
			}
		},
		shop:{
			components:{
				components1:"所在地",
				components2:"销量",
				components3:"关注会员"
			},
			list:{
				list1:"描述相符",
				list2:"服务态度",
				list3:"发货速度",
				list4:"电话",
				list5:"地址",
				list6:"取消关注",
				list7:"关注店铺",
				list8:"搜索 商品",
				list9:"搜索结果:",
				list10:"综合",
				list11:"销量",
				list12:"价格",
				list13:"包邮",
				list14:"最低价格",
				list15:"最高价格",
				list16:"确定",
				list17:"人付款",
				list18:"包邮",
				list19:"限时折扣",
				list20:"没有找到您想要的商品。换个条件试试吧",
				list21:"上一页",
				list22:"下一页",
				list23:"缺少店铺id",
				list24:"取消关注成功",
				list25:"token不存在",
				list26:"关注成功"
			},
			shop1:"自营",
			shop2:"描述相符",
			shop3:"服务态度",
			shop4:"发货速度",
			shop5:"电话",
			shop6:"地址",
			shop7:"取消关注",
			shop8:"关注店铺",
			shop9:"搜索 商品",
			shop10:"本店推荐",
			shop11:"限时折扣",
			shop12:"上一页",
			shop13:"下一页",
			shop14:"取消关注成功",
			shop15:"token不存在",
			shop16:"关注成功",
			shop17:"搜索结果",
			shop18:"综合",
			shop19:"销量",
			shop20:"信用",
			shop21:"搜索 店铺",
			shop22:"主营",
			shop23:"评分",
			shop24:"人关注",
			shop25:"没有找到您想要的店铺。换个条件试试吧",
			shop26:"上一页",
			shop27:"下一页",
			live:{
				live1:"直播列表",
				live2:"该直播已结束，请观看其他直播",
				live3:"该直播还未开始，请等待主播开启直播",
				live4:"直播商品列表",
				live5:"暂无商品",
				live6:"未直播",
				live7:"正在直播",
				live8:"直播结束"			
			}
		}
	}
}
