export const lang = {
	common: {
		name: 'မြန်မာ',
		language:"ဘာသာစကား",
		currentlanguage:"လက်ရှိဘာသာစကား"
	},
	components:{
		cargo:{
			cargo1:"လူတွေကပိုက်ဆံပေးတယ်",
			cargo2:"စာရင်းကျန်သည်",
			cargo3:"ကိုယ်ပိုင်လုပ်ငန်းလုပ်သော",
			cargo4:"အခမဲ့ပို့ပေးသည်",
			cargo5:"virtual ရောင်းကုန်",
			cargo6:"စုဆောင်းသည်",
			cargo7:"လှည်းထဲသို့ထည့်ပါ",
			cargo8:"စုဆောင်းမှုအောင်မြင်သည်",
			cargo9:"ကုန်ပစ္စည်းခေါင်းစဉ်"
		},
		message:{
			message1:"ကုန်ပစ္စည်းလက်ဝယ်ရှိ",
			message2:"အရောင်း",
			message3:"ထုတ်ကုန်ကြည့်ပါ",
			message4:"အော်ဒါနံပါတ်",
			message5:"အမိန့်ကိုစစ်ဆေးပါ",
			message6:"ဖောက်သည် ၀ န်ဆောင်မှုအကြံပေးခြင်း",
			message7:"မင်းမှာကုန်ပစ္စည်းသတင်းတစ်ခုရှိတယ်",
			message8:"သင့်မှာယူမှုသတင်းစကားရှိသည်",
			message9:"မင်းမှာပုံတစ်ပုံရှိတယ်",
			message10:"ချတ်မှတ်တမ်းမရှိပါ",
			message11:"တင်ရန်နှိပ်ပါ",
			message12:"အွန်လိုင်း ၀ န်ဆောင်မှု",
			message13:"ဖောက်သည် ၀ န်ဆောင်မှုသည်အွန်လိုင်းမဟုတ်ပါ",
			message14:"ပို့ပါ",
			message15:"တစ်ကြိမ်လျှင်စာလုံးရေ ၁၅၀ အထိပို့နိုင်သည်။",
			message16:"အကြောင်းအရာအလွတ်မပို့နိုင်ပါ"
			
		},
		components1:"ကုန်ပစ္စည်းရွေးချယ်ခြင်း"
	},
	layout:{
		components:{
			components1:"စျေးဝယ်လှည်း",
			components2:"ငါ၏အမိန့်",
			components3:"ငါ့ခြေရာ",
			components4:"ငါ့အာရုံ",
			components5:"ငါ့လျှော့စျေးကူပွန်",
			components6:"အပေါ်သို့ပြန်သွားပါ",
			components7:"ပလက်ဖောင်းဖောက်သည် ၀ န်ဆောင်မှု",
			components8:"သင်လော့ဂ်အင်မဝင်ပါ",
			components9:"လင့်ခ်များ",
			components10:"ထုတ်ကုန်အမျိုးအစားအားလုံး",
			components11:"ကုန်ပစ္စည်း",
			components12:"ဆိုင်",
			components13:"ရှာဖွေရေး",
			components14:"နာမည်ကြီးရှာဖွေမှုများ",
			components15:"ငါ့စျေးဝယ်လှည်း",
			components16:"အသစ်ထည့်ထားသောထုတ်ကုန်များ",
			components17:"ဖျက်ပါ",
			components18:"ဘုံ",
			components19:"ကုန်ပစ္စည်းအမျိုးအစားများ၊ စုစုပေါင်းပမာဏ",
			components20:"Ks",
			components21:"ဈေးဝယ်လှည်းသို့သွားပါ",
			components22:"မင်းရဲ့ဈေးဝယ်လှည်းကဘာမှမရှိဘူး၊ မြန်မြန်တက်ပြီးစျေး ၀ ယ်သွားပြီးထုတ်ကုန်တွေကိုရွေးပါ။",
			components23:"ကုန်ပစ္စည်း",
			components24:"ဆိုင်",
			components25:"တစ်နိုင်ငံလုံး",
			components26:"မြို့ပြောင်းပါ",
			components27:"မင်္ဂလာပါ၊ ကျေးဇူးပြု၍ ဝင်ပါ",
			components28:"ထွက်သည်",
			components29:"အခမဲ့စာရင်းသွင်းပါ",
			components30:"ငါ၏အမိန့်",
			components31:"ငါ့ခြေရာ",
			components32:"ငါ့အာရုံ",
			components33:"အကူအညီဌာန",
			components34:"ကြေငြာချက်",
			components35:"APP",
			components36:"ကြိုဆိုပါတယ်",
			components37:"ထုတ်ကုန်အမျိုးအစားအားလုံး",
			components38:"အိမ်ဝယ်ပါ",
			components39:"ထုတ်ကုန်အားလုံး"
		},
		layout1:"welcome",
		layout2:"welcome"
		
	},
	view:{
		auth:{
			find:{
				find1:"ဖုန်းနံပါတ်အလွတ်မရပါ",
				find2:"ကျေးဇူးပြု၍ မှန်ကန်သောဖုန်းနံပါတ်ကိုထည့်ပါ",
				find3:"ကျေးဇူးပြုပြီး login password အသစ်ထည့်ပါ",
				find4:"စကားဝှက်အရှည်သည်ထိုထက်မနည်းနိုင်ပါ",
				find5:"ဂဏန်းအရေအတွက်",
				find6:"စကားဝှက်ပါဝင်ရမည်",
				find7:"နံပါတ်",
				find8:"、စာလုံးအသေးများ",
				find9:"、စာလုံးကြီး",
				find10:"、အထူးဇာတ်ကောင်များ",
				find11:"ကျေးဇူးပြု၍ အတည်ပြုစကားဝှက်ကိုရိုက်ထည့်ပါ",
				find12:"စကားဝှက်ထည့်သွင်းမှုနှစ်ခုသည်မကိုက်ညီပါ",
				find13:"ပြောင်းလဲနေသောကုဒ်ကိုရယူပါ",
				find14:"ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ထည့်ပါ",
				find15:"ကျေးဇူးပြု၍ SMS dynamic code ကိုရိုက်ထည့်ပါ",
				find16:"ဖုန်းနံပါတ်ကမှတ်ပုံတင်မထားဘူး",
				find17:"နောက်ပိုင်းတွင်ပြန်လည်ရယူနိုင်သည်",
				find18:"မိုဘိုင်းလ်ဖုန်းနံပါတ်ထည့်ပါ",
				find19:"အထောက်အထားစိစစ်ပါ",
				find20:"လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်",
				find21:"ပြီးအောင်လုပ်ပါ",
				find22:"ကျေးဇူးပြု၍ မှတ်ပုံတင်ထားသောမိုဘိုင်းလ်နံပါတ်ကိုထည့်ပါ",
				find23:"နောက်တစ်ဆင့်",
				find24:"ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ထည့်ပါ",
				find25:"ကျေးဇူးပြု၍ SMS dynamic code ကိုရိုက်ထည့်ပါ",
				find26:"နောက်တစ်ဆင့်",
				find27:"ကျေးဇူးပြုပြီး login password အသစ်ထည့်ပါ",
				find28:"ကျေးဇူးပြု၍ စကားဝှက်အသစ်ကိုထပ်မံထည့်ပါ",
				find29:"စကားဝှက်ကိုပြန်လည်ပြင်ဆင်ပြီးပါပြီ",
				find30:"ပြန်လည်မှတ်ပုံတင်ပါ"
			},
			login:{
				login1:"အကောင့်ဝင်ပါ",
				login2:"ကျေးဇူးပြု၍ အသုံးပြုသူအမည်ထည့်ပါ",
				login3:"ကျေးဇူးပြု၍ သင်၏စကားဝှက်ကိုရိုက်ထည့်ပါ",
				login4:"ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ထည့်ပါ",
				login5:"ခုနစ်ရက်အတွင်းအလိုအလျောက် ၀ င်ပါ",
				login6:"password မေ့နေလား။",
				login7:"လော့ဂ်အင်",
				login8:"အကောင့်မရှိဘူးလား။",
				login9:"ယခုဝင်မည်",
				login10:"မိုဘိုင်း dynamic code ကို login",
				login11:"ကျေးဇူးပြု၍ ဖုန်းနံပါတ်ထည့်ပါ",
				login12:"ကျေးဇူးပြု၍ SMS dynamic code ကိုရိုက်ထည့်ပါ",
				login13:"လော့ဂ်အင်",
				login14:"ယခုဝင်မည်",
				login15:"ဖုန်းနံပါတ်အလွတ်မရပါ",
				login16:"ကျေးဇူးပြု၍ မှန်ကန်သောဖုန်းနံပါတ်ကိုထည့်ပါ",
				login17:"ပြောင်းလဲနေသောကုဒ်ကိုရယူပါ",
				login18:"ကျေးဇူးပြု၍ သင်၏ login account ကိုထည့်ပါ",
				login19:"login အောင်မြင်သည်",
				login20:"နောက်ပိုင်းတွင်ပြန်လည်ရယူနိုင်သည်"
			},
			auth1:"အသုံးပြုသူမှတ်ပုံတင်ခြင်း",
			auth2:"ကျေးဇူးပြုပြီး user name ထည့်ပါ",
			auth3:"ကျေးဇူးပြု၍ စကားဝှက်ကိုရိုက်ထည့်ပါ",
			auth4:"ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ထည့်ပါ",
			auth5:"ဖတ်ပြီးသဘောတူတယ်",
			auth6:"ဝန်ဆောင်မှုသဘောတူညီချက်",
			auth7:"မှတ်ပုံတင်ပါ",
			auth8:"အကောင့်ရှိပြီးသားလား",
			auth9:"ချက်ချင်း log in ဝင်ပါ",
			auth10:"ကျေးဇူးပြု၍ ဖုန်းနံပါတ်ထည့်ပါ",
			auth11:"ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ထည့်ပါ",
			auth12:"ကျေးဇူးပြု၍ SMS dynamic code ကိုရိုက်ထည့်ပါ",
			auth13:"ဖုန်းနံပါတ်အလွတ်မရပါ",
			auth14:"ကျေးဇူးပြု၍ မှန်ကန်သောဖုန်းနံပါတ်ကိုထည့်ပါ",
			auth15:"ကျေးဇူးပြု၍ စကားဝှက်ကိုရိုက်ထည့်ပါ",
			auth16:"စကားဝှက်အရှည်သည်ထိုထက်မနည်းနိုင်ပါ",
			auth17:"ဂဏန်းအရေအတွက်",
			auth18:"စကားဝှက်ပါဝင်ရမည်",
			auth19:"နံပါတ်",
			auth20:"、စာလုံးအသေးများ",
			auth21:"、စာလုံးကြီး",
			auth22:"、အထူးဇာတ်ကောင်များ",
			auth23:"ပြောင်းလဲနေသောကုဒ်ကိုရယူပါ",
			auth24:"မှတ်ပုံတင်ရန်ပလက်ဖောင်းကိုဖွင့်မထားပါ",
			auth25:"ကျေးဇူးပြု၍ သဘောတူညီချက်ကိုဖတ်ပြီးအမှန်ခြစ်ပေးပါ"
		},
		cms:{
			help:{
				help1:"ရှေ့စာမျက်နှာ",
				help2:"အကူအညီစာရင်း",
				help3:"အကူအညီအသေးစိတ်",
				help4:"ကူညီကြပါ"
			},
			noctice:{
				noctice1:"ရှေ့စာမျက်နှာ",
				noctice2:"ကြေငြာစာရင်း",
				noctice3:"ကြေငြာချက်အသေးစိတ်",
				noctice4:"ကြေငြာချက်",
				noctice5:"နောက်ဆုံးကြေငြာချက်",
				noctice6:"ကုန်တိုက်ကြေငြာချက်",
				noctice7:"ယခင်စာမျက်နှာ",
				noctice8:"နောက်စာမျက်နှာ"
			}
		},
		goods:{
			cart:{
				cart1:"သင်ဤထုတ်ကုန်ကိုဖျက်လိုသည်မှာသေချာသလား။",
				cart2:"သတင်းအချက်အလက်များ",
				cart3:"သေချာပါတယ်",
				cart4:"ပယ်ဖျက်ပါ",
				cart5:"အောင်မြင်စွာဖျက်လိုက်ပါပြီ",
				cart6:"ကျေးဇူးပြု၍ ဖျက်မည့်ထုတ်ကုန်ကိုရွေးပါ",
				cart7:"ဤအရာများကိုသင်သွန်ပစ်လိုသည်မှာသေချာသလား။",
				cart8:"ကုန်စည်သတင်းအချက်အလက်",
				cart9:"စျေးနှုန်း",
				cart10:"ပမာဏ",
				cart11:"စုစုပေါင်း",
				cart12:"လည်ပတ်",
				cart13:"ကိုယ်ပိုင်လုပ်ငန်းလုပ်သော",
				cart14:"ဖျက်ပါ",
				cart15:"သက်တမ်းလွန်ပစ္စည်းများ",
				cart16:"အပိုင်းပိုင်း",
				cart17:"ပယ်ဖျက်ခြင်း",
				cart18:"အားလုံးကိုရွေးပါ",
				cart19:"မမှန်ကန်သည့်ကလေးကိုရှင်းပစ်ပါ",
				cart20:"ရွေးချယ်ထားသောကုန်ပစ္စည်း",
				cart21:"အပိုင်းပိုင်း",
				cart22:"စုစုပေါင်း (ကုန်စည်မပါ)",
				cart23:"အခြေချသည်",
				cart24:"မင်းရဲ့ဈေးဝယ်လှည်းကဘာမှမရှိဘူး၊ မြန်မြန်တက်ပြီးစျေး ၀ ယ်သွားပြီးထုတ်ကုန်တွေကိုရွေးပါ။"
			},
			detail:{
				detail1:"အဆုံးအထိသာကျန်တော့သည်",
				detail2:"ဤဗီဒီယိုကိုယာယီမရနိုင်ပါ၊ ကျေးဇူးပြု၍ နောက်မှထပ်ကြိုးစားပါ",
				detail3:"သင်လော့ဂ်အင်မဝင်ပါ",
				detail4:"မတည်ရှိပါဘူး",
				detail5:"Ks",
				detail6:"အပိုင်းပိုင်း",
				detail7:"အပြည့်",
				detail8:"လျှော့ချ",
				detail9:"ကယ်",
				detail10:"အရေးပါတဲ့",
				detail11:"တစ်ခုပေးပါ",
				detail12:"လျှော့စျေးကူပွန်",
				detail13:"ကူပွန်",
				detail14:"အခမဲ့ပို့ပေးသည်",
				detail15:"အောင်မြင်စွာလက်ခံရရှိခဲ့သည်",
				detail16:"ပစ္စည်းကုန်သွားပါပြီ",
				detail17:"၀ ယ်ယူနိုင်သောပမာဏကို ၀ ယ ်၍ မရပါ",
				detail18:"လှည်းထဲသို့ထည့်ခြင်းအောင်မြင်သည်",
				detail19:"ကုန်စည်ဝယ်ယူမှုကန့်သတ်ချက်",
				detail20:"အခုဝယ်ပြီးပြီ",
				detail21:"ပွဲကပြီးသွားပြီ",
				detail22:"အောင်မြင်မှုကိုပယ်ဖျက်ပါ",
				detail23:"လိုက်ခဲ့သည်",
				detail24:"ဗီဒီယို",
				detail25:"ပုံ",
				detail26:"ကုန်ပစ္စည်းကိုလိုက်နာပါ",
				detail27:"Customer Service ကိုဆက်သွယ်ပါ",
				detail28:"လျှော့စျေးများကိုကန့်သတ်ပါ",
				detail29:"ကောင်းကင်",
				detail30:"နာရီ",
				detail31:"မိနစ်",
				detail32:"ဒုတိယအကြိမ်",
				detail33:"ရောင်းဈေး",
				detail34:"မူရင်းဈေး",
				detail35:"ဈေးကွက်ပေါက်ဈေး",
				detail36:"စုဆောင်းအကဲဖြတ်ခြင်း",
				detail37:"စုဆောင်းရောင်းချခြင်း",
				detail38:"ကုန်စည်",
				detail39:"အမြန်ပို့ဆောင်ခအခမဲ့ပါ",
				detail40:"ချောသည်မလွဲမသွေဖြစ်သည်",
				detail41:"ကူပွန်",
				detail42:"ခေါက်",
				detail43:"အပြည့်အဝလျှော့ချ",
				detail44:"သို့ပို့ဆောင်",
				detail45:"ကျေးဇူးပြု၍ ပေးပို့ရမည့်လိပ်စာကိုရွေးပါ",
				detail46:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
				detail47:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
				detail48:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
				detail49:"ဝန်ဆောင်မှု",
				detail50:"မူတည်ပါတယ်",
				detail51:"သင်္ဘောနှင့်ရောင်းချပြီး ၀ န်ဆောင်မှုကိုပေးသည်",
				detail52:"ပမာဏ",
				detail53:"ကုန်ပစ္စည်းလက်ဝယ်ရှိ",
				detail54:"တရားဝင်အထင်ကရစတိုးဆိုင်မှပို့ဆောင်ပြီးရောင်းအား ၀ န်ဆောင်မှုကိုပေးသည်",
				detail55:"အခုဝယ်ပါ",
				detail56:"လှည်းထဲသို့ထည့်ပါ",
				detail57:"ပစ္စည်းပြတ်လပ်မှု",
				detail58:"မိုဘိုင်းဖုန်းဝယ်ယူခြင်း",
				detail59:"အာမခံဝန်ဆောင်မှု",
				detail60:"",
				detail61:"",
				detail62:"ကုန်စည်စစ်စစ် ၀ န်ဆောင်မှုအာမခံပါသည်",
				detail63:"စစ်မှန်သောလုံခြုံရေး",
				detail64:"ငွေပေးချေပြီး ၂ နာရီအတွင်းပို့ဆောင်ပေးပါသည်",
				detail65:"နှစ်နာရီအတွင်း ပို့ဆောင်ပေးပါသည်။",
				detail66:"",
				detail67:"အစမ်းစင်တာ",
				detail68:"အဖွဲ့အစည်းအတည်ပြုခြင်း",
				detail69:"",
				detail70:"စားသုံးသူအသင်း၏ အာမခံချက်",
				detail71:"ကိုယ်ပိုင်လုပ်ငန်းလုပ်သော",
				detail72:"စတိုးဆိုင် အဆင့်သတ်မှတ်ချက်",
				detail73:"ကုန်ပစ္စည်းအကြောင်းအရာ",
				detail74:"ရောင်းသူ ၀ န်ဆောင်မှု",
				detail75:"ပေးပို့မြန်နှုန်း",
				detail76:"မိနစ်",
				detail77:"ဆက်သွယ်ရန်နံပါတ်",
				detail78:"စတိုးဆိုင်ထဲသို့ ၀ င ်၍ လမ်းလျှောက်ပါ",
				detail79:"မှာယူမှုကိုပယ်ဖျက်ပါ",
				detail80:"ဆိုင်ကိုလိုက်ကြည့်ပါ",
				detail81:"ပေါင်းစပ်အထုပ်စျေးနှုန်း",
				detail82:"အခုဝယ်ပါ",
				detail83:"ကုန်ပစ္စည်းအသေးစိတ်",
				detail84:"ကုန်စည်ဂုဏ်ရည်",
				detail85:"ထုတ်ကုန်ပြန်လည်သုံးသပ်ခြင်း။",
				detail86:"အားလုံးသုံးသပ်ချက်",
				detail87:"ဆိုင်မှအကြောင်းပြန်သည်",
				detail88:"အပိုဆောင်းအကဲဖြတ်",
				detail89:"ယခင်စာမျက်နှာ",
				detail90:"နောက်စာမျက်နှာ",
				detail91:"ဤထုတ်ကုန်အတွက် သုံးသပ်ချက်မရှိပါ။",
				detail92:"ရောင်းပြီးနောက်အာမခံ"
			},
			list:{
				list1:"ရှာဖွေမှုရလဒ်များ",
				list2:"အမှတ်တံဆိပ်",
				list3:"အမှတ်တံဆိပ်အားလုံး",
				list4:"ရွေးချယ်စရာမျိုးစုံ",
				list5:"ကျယ်ကျယ်ပြန့်ပြန့်",
				list6:"အရောင်း",
				list7:"စျေးနှုန်း",
				list8:"အခမဲ့ပို့ပေးသည်",
				list9:"ကိုယ်ပိုင်လုပ်ငန်းလုပ်သော",
				list10:"အနိမ့်ဆုံးစျေးနှုန်း",
				list11:"အမြင့်ဆုံးစျေးနှုန်း",
				list12:"သေချာပါတယ်",
				list13:"လူတွေကပိုက်ဆံပေးတယ်",
				list14:"လျှော့စျေးများကိုကန့်သတ်ပါ",
				list15:"ယခင်စာမျက်နှာ",
				list16:"နောက်စာမျက်နှာ",
				list17:"သင်ရှာနေသောကုန်ပစ္စည်းကိုမတွေ့ပါ။ အခြားအခြေအနေတစ်ခုကို စမ်းကြည့်ပါ။",
				list18:"လှည်းထဲသို့ထည့်ခြင်းအောင်မြင်သည်",
				list19:"စုဆောင်းမှုအောင်မြင်သည်",
				list20:"စိတ်ကြိုက်အောင်မြင်သွားပြီ"
			},
			goods1:"အမှတ်တံဆိပ်ဇုန်",
			goods2:"အမှတ်တံဆိပ်များ မရှိတော့သည်ကို ကြည့်ရန် ပင်မစာမျက်နှာသို့ သွားပါ။",
			goods3:"ယခင်စာမျက်နှာ",
			goods4:"နောက်စာမျက်နှာ",
			goods5:"ကုန်ပစ္စည်းအမျိုးအစားမရှိပါ။",
			goods6:"ကူပွန်စင်တာ",
			goods7:"ငွေပိုစုပါ၊ ကူပွန်များများရယူပါ",
			goods8:"ကူပွန်များကိုအချိန်အကန့်အသတ်ဖြင့်ရယူပါ",
			goods9:"ထပ်ဆင့်အသုံးပြုခြင်း",
			goods10:"အမျိုးမျိုး",
			goods11:"ငါ့လျှော့စျေးကူပွန်",
			goods12:"ဆိုင်ကူပွန်များ",
			goods13:"ပလပ်ဖောင်းကူပွန်",
			goods14:"ခေါက်",
			goods15:"ဆိုင်ကိုသုံးပါ",
			goods16:"ကန့်သတ်ကူပွန်မရှိပါ။",
			goods17:"အပြည့်",
			goods18:"အသုံးဝင်ပါစေ။",
			goods19:"စုဆောင်းသည့်နေ့မှစ၍",
			goods20:"ရက်များအတွက် အကျုံးဝင်ပါသည်။",
			goods21:"အထိအကျုံးဝင်သည်",
			goods22:"ယခုချက်ချင်းရယူပါ",
			goods23:"အသုံးပြုရန်",
			goods24:"ကူပွန်များမရှိပါ",
			goods25:"ကူပွန်ပလပ်အင်ကိုထည့်သွင်းမထားပါ",
			goods26:"အောင်မြင်စွာလက်ခံရရှိခဲ့သည်",
			goods27:"မတည်ရှိပါဘူး"
		},
		index:{
			index:{
				index1:"အကွာအဝေးအဆုံး",
				index2:"ကုန်ပစ္စည်း",
				index3:"ဆိုင်",
				index4:"ပွဲကပြီးသွားပြီ",
				index5:"အောင်မြင်စွာလက်ခံရရှိခဲ့သည်",
				index6:"မတည်ရှိပါဘူး"	
			},
			components:{
				components1:"ထုတ်ကုန်ပုံ",
				components2:"အမှတ်တံဆိပ်ပုံ"
			},
			index1:"တိုက်ရိုက်ရှာဖွေခြင်း",
			index2:"ကျေးဇူးပြု၍ မြို့အမည်ကို ထည့်ပါ။",
			index3:"နာမည်ကြီးမြို့များ",
			index4:"ပင်ယင်၏ပထမစာလုံးဖြင့် ရွေးပါ။",
			index5:"တစ်နိုင်ငံလုံး",
			index6:"City site plugin ကို ထည့်သွင်းမထားပါ။",
			index7:"ဝဘ်ဆိုက်ကကျနေတယ်",
			index8:"ယခင်စာမျက်နှာသို့ ပြန်သွားရန်",
			index9:"ပင်မစာမျက်နှာသို့ပြန်သွားရန်",
			index10:"လော့ဂ်အင်",
			index11:"မှတ်ပုံတင်ပါ",
			index12:"ကုန်တိုက်သတင်းအချက်အလက်",
			index13:"နောက်ထပ်",
			index14:"ကုန်တိုက်ဝန်ဆောင်မှု",
			index15:"ကုန်သည်တွေ အခြေချတယ်။",
			index16:"ကုန်သည်စင်တာ",
			index17:"စတိုးဆိုင်စီမံခန့်ခွဲမှု",
			index18:"ကူပွန်စင်တာ",
			index19:"နောက်ထပ်",
			index20:"အပြည့်",
			index21:"ရနိုင်ပါတယ်",
			index22:"ယခုချက်ချင်းရယူပါ",
			index23:"အသုံးပြုရန်",
			index24:"အချိန်အကန့်အသတ်ရှိသည်",
			index25:"ပိုပြီးထုတ်ကုန်များ",
			index26:"လူတွေကပိုက်ဆံပေးတယ်",
			index27:"အခမဲ့ပို့ပေးသည်",
			index28:"လျှော့စျေးများကိုကန့်သတ်ပါ",
			index29:"ကုန်ပစ္စည်း",
			index30:"ဆိုင်",
			index31:"ရှာဖွေရေး",
			index32:"ငါ့စျေးဝယ်လှည်း"
		},
		member:{
			security:{
				security1:"ဝင်ရောက်ရန်စကားဝှက်",
				security2:"အင်တာနက်အကောင့်များ ခိုးယူခံရမည့်အန္တရာယ်ရှိသည်။ သင့်အကောင့်လုံခြုံရေးကို ကာကွယ်ရန် သင့်စကားဝှက်ကို ပုံမှန်ပြောင်းရန် အကြံပြုထားသည်။",
				security3:"ပြန်လည်ပြင်ဆင်ပါ",
				security4:"စာတိုက်ပုံး စည်းနှောင်ခြင်း။",
				security5:"အတည်ပြုပြီးနောက်၎င်းသည် login password နှင့်ငွေပေးချေမှုစကားဝှက်ကိုအမြန်ပြန်လည်ရယူရန်သုံးနိုင်သည်",
				security6:"ဖုန်းအတည်ပြုခြင်း",
				security7:"အတည်ပြုပြီးနောက်၎င်းသည် login password နှင့်ငွေပေးချေမှုစကားဝှက်ကိုအမြန်ပြန်လည် ရယူ၍ အကောင့်လက်ကျန်အပြောင်းအလဲများ၏သတိပေးချက်များကိုလက်ခံရယူရန်သုံးနိုင်သည်",
				security8:"အကောင့်ဝင်စကားဝှက်ကို ပြင်ဆင်ပါ။",
				security9:"စကားဝှက်အဟောင်း",
				security10:"လက်ရှိစကားဝှက်",
				security11:"စကားဝှက်အသစ်",
				security12:"စကားဝှက်အတည်ပြုခြင်း",
				security13:"စကားဝှက်အသစ်ကို အတည်ပြုပါ။",
				security14:"ကယ်ပါ",
				security15:"ပယ်ဖျက်ပါ",
				security16:"အတည်ပြုရန်ကုတ်",
				security17:"ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ထည့်ပါ",
				security18:"ဒိုင်းနမစ်ကုဒ်",
				security19:"ကျေးဇူးပြု၍ ပြောင်းလဲနေသောကုဒ်ကိုထည့်ပါ",
				security20:"ပြောင်းလဲနေသောကုဒ်ကိုရယူပါ ကိုနှိပ်ပါ၊ သင်၏ချည်နှောင်ထားသောမိုဘိုင်းလ်နံပါတ်သို့သင့်ကိုပို့လိမ့်မည်",
				security21:"အတည်ပြုကုဒ်ကို ပေးပို့ပါ။",
				security22:"စာတိုက်ပုံးကိုချိတ်ပါ",
				security23:"လက်ရှိ စာတိုက်ပုံး",
				security24:"မေးလ်",
				security25:"ကျေးဇူးပြု၍ သင့်အီးမေးလ်ကို ထည့်သွင်းပါ။",
				security26:"မိုဘိုင်းလ်ဖုန်းနံပါတ်ကို ချိတ်ပါ။",
				security27:"လက်ရှိဖုန်းနံပါတ်",
				security28:"ဖုန်းနံပါတ်",
				security29:"ငွေပေးချေမှုစကားဝှက်ကိုချိတ်ပါ",
				security30:"ကျေးဇူးပြု၍ ဂဏန်း ၆ လုံးပါသောငွေပေးချေမှုစကားဝှက်ကိုထည့်ပါ၊ ၎င်းကိုထပ်ခါထပ်ခါသို့မဟုတ်ဆက်တိုက်နံပါတ်များမသုံးရန်အကြံပြုသည်",
				security31:"အတည်ပြုကုဒ်သို့ပို့ပြီးပါပြီ",
				security32:"စကားဝှက်အသစ်သည် မူရင်းစကားဝှက်နှင့် မတူနိုင်ပါ။",
				security33:"ကျေးဇူးပြု၍ စကားဝှက်ကိုထပ်မံထည့်ပါ",
				security34:"စကားဝှက်နှစ်ခုသည်မကိုက်ညီပါ",
				security35:"စကားဝှက်အသစ်ကို ထည့်ပါ။",
				security36:"ကျေးဇူးပြု၍ မှန်ကန်သောအီးမေးလ်လိပ်စာကိုထည့်ပါ",
				security37:"ကျေးဇူးပြု၍ မှန်ကန်သောဖုန်းနံပါတ်ကိုထည့်ပါ",
				security38:"ကျေးဇူးပြု၍ မူရင်းစကားဝှက်ကိုထည့်ပါ",
				security39:"ကျေးဇူးပြု၍ သင်၏ မှားယွင်းသော အီးမေးလ်ကို ထည့်ပါ။",
				security40:"အတည်ပြုကုဒ်ကို ရယူပါ။",
				security41:"SMS အတည်ပြုကုဒ်ကိုရိုက်ထည့်ပါ",
				security42:"ပြောင်းလဲနေသောကုဒ်ကိုရယူပါ",
				security43:"မင်းရဲ့မိုဘိုင်းလ်ဖုန်းနံပါတ်ကိုမင်းမချည်ရသေးဘူး၊ ကျေးဇူးပြုပြီးမင်းရဲ့မိုဘိုင်းလ်ဖုန်းနံပါတ်ကိုအရင်ချိတ်ပေးပါ။",
				security44:"သတင်းအချက်အလက်များ",
				security45:"သေချာပါတယ်",
				security46:"ပယ်ဖျက်ပါ",
				security47:"စကားဝှက်ကိုအောင်မြင်စွာပြောင်းပါ",
				security48:"ပေးပို့ထားပြီးဖြစ်သည်။",
				security49:"ထပ်ခါထပ်ခါ မနှိပ်ပါနဲ့။",
				security50:"စာတိုက်ပုံးကိုချိတ်တွဲခြင်းအောင်မြင်သည်",
				security51:"မိုဘိုင်းလ်ဖုန်းနံပါတ်ကို အောင်မြင်စွာ ချိတ်ဆွဲပါ။",
				security52:"ကျေးဇူးပြု၍ ငွေပေးချေမှုစကားဝှက်တစ်ခုသတ်မှတ်ပါ",
				security53:"ထပ်ထည့်ပါ။",
				security54:"ကျေးဇူးပြု၍ နံပါတ်ရိုက်ထည့်ပါ",
				security55:"ငွေပေးချေမှုစကားဝှက်ကိုပြုပြင်ရာတွင်အောင်မြင်သည်",
				security56:"စကားဝှက်နှစ်ခုသည် တူညီမည်မဟုတ်ပါ။"
			},
			member1:"ကျွန်ုပ်ရရှိနိုင်သောလက်ကျန်ငွေ",
			member2:"ဆုတ်ခွာ",
			member3:"အားပြန်သွင်းပါ",
			member4:"ထုတ်ယူနိုင်သော လက်ကျန်",
			member5:"ထုတ်ယူ၍မရသော လက်ကျန်",
			member6:"အရင်းအမြစ်",
			member7:"ပမာဏ",
			member8:"အသေးစိတ်ဖော်ပြချက်",
			member9:"အချိန်",
			member10:"ယခင်စာမျက်နှာ",
			member11:"နောက်စာမျက်နှာ",
			member12:"အကောင့်ကိုတည်းဖြတ်ပါ",
			member13:"နာမည်",
			member14:"ကျေးဇူးပြု၍ သင်၏အမည်အမှန်ကိုထည့်ပါ",
			member15:"ဆဲလ်ဖုန်း",
			member16:"ကျေးဇူးပြု၍ ဖုန်းနံပါတ်ထည့်ပါ",
			member17:"အကောင့်အမျိုးအစား",
			member18:"ကျေးဇူးပြုပြီးအကောင့်အမျိုးအစားရွေးပါ",
			member19:"ဘဏ်အမည်",
			member20:"ကျေးဇူးပြု၍ ဘဏ်နာမည်ထည့်ပါ",
			member21:"ငွေထုတ်အကောင့်",
			member22:"ကျေးဇူးပြု၍ ငွေထုတ်အကောင့်ကို ထည့်ပါ။",
			member23:"ကယ်ပါ",
			member24:"ဖုန်းနံပါတ်အလွတ်မရပါ",
			member25:"ကျေးဇူးပြု၍ မှန်ကန်သောဖုန်းနံပါတ်ကိုထည့်ပါ",
			member26:"ကျေးဇူးပြု၍ သင်၏အမည်အမှန်ကိုထည့်ပါ",
			member27:"ကျေးဇူးပြုပြီးအကောင့်အမျိုးအစားရွေးပါ",
			member28:"ကျေးဇူးပြု၍ ဘဏ်နာမည်ထည့်ပါ",
			member29:"ကျေးဇူးပြု၍ ငွေထုတ်အကောင့်ကို ထည့်ပါ။",
			member30:"အကောင့်စာရင်း",
			member31:"အကောင့်ထည့်ပါ",
			member32:"ပုံသေ",
			member33:"မိုဘိုင်းဖုန်းနံပါတ်",
			member34:"ငွေထုတ်အကောင့်",
			member35:"အကောင့်အမျိုးအစား",
			member36:"ဘဏ်အမည်",
			member37:"Default အဖြစ် သတ်မှတ်သည်။",
			member38:"တည်းဖြတ်",
			member39:"ဖျက်ပါ",
			member40:"ပို့ဆောင်ရေးလိပ်စာကို တည်းဖြတ်ပါ။",
			member41:"နာမည်",
			member42:"ပို့ဆောင်သူအမည်",
			member43:"ဆဲလ်ဖုန်း",
			member44:"ပို့ဆောင်သူ၏မိုဘိုင်းဖုန်းနံပါတ်",
			member45:"တယ်လီဖုန်း",
			member46:"Consignee ၏ ပုံသေတယ်လီဖုန်း",
			member47:"လိပ်စာ",
			member48:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
			member49:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
			member50:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
			member51:"လိပ်စာ",
			member52:"ပုံသေ",
			member53:"ဟုတ်တယ်",
			member54:"မဟုတ်ဘူး",
			member55:"ကျေးဇူးပြု၍ တင်ပို့သူ၏အမည်ကို ထည့်သွင်းပါ။",
			member56:"ကျေးဇူးပြု၍ အသေးစိတ်လိပ်စာထည့်ပါ",
			member57:"အကောင့်လက်ကျန်ငွေ",
			member58:"ငွေထုတ်လျှောက်လွှာ",
			member59:"သို့ငွေထုတ်ပါ",
			member60:"",
			member61:"ငွေထုတ်အကောင့်",
			member62:"ကျေးဇူးပြု၍ ငွေထုတ်အကောင့်တစ်ခုကိုရွေးပါ",
			member63:"ငွေထုတ်ပမာဏ",
			member64:"ထုတ်ယူနိုင်သော လက်ကျန်ငွေ",
			member65:"အနိမ့်ဆုံးငွေထုတ်ပမာဏသည်",
			member66:"ကိုင်တွယ်ခက",
			member67:"ဆုတ်ခွာ",
			member68:"ကျေးဇူးပြု၍ ငွေထုတ်နည်းလမ်းကိုအရင်ထည့်ပါ",
			member69:"ကျေးဇူးပြု၍ ငွေထုတ်သည့်ပမာဏကို ထည့်ပါ။",
			member70:"ထုတ်ယူနိုင်သောပမာဏသည်ရရှိနိုင်သောပမာဏထက်ကျော်လွန်သည်",
			member71:"ထုတ်ယူသည့်ပမာဏသည် အနိမ့်ဆုံး ထုတ်ယူသည့်ပမာဏထက် နည်းပါသည်။",
			member72:"အောင်မြင်သော ငွေထုတ်လျှောက်လွှာ",
			member73:"ကလေးလေး",
			member74:"မင်းထုတ်ကုန်ကိုမလိုက်နာသေးဘူး",
			member75:"ဆိုင်",
			member76:"ကိုယ်ပိုင်လုပ်ငန်းလုပ်သော",
			member77:"ကုန်ပစ္စည်းအကြောင်းအရာ",
			member78:"ရောင်းသူ ၀ န်ဆောင်မှု",
			member79:"ပေးပို့မြန်နှုန်း",
			member80:"ဆက်သွယ်ရန်နံပါတ်",
			member81:"မိနစ်",
			member82:"မင်းဆိုင်ကို မလိုက်သေးဘူးလား။",
			member83:"အောင်မြင်စွာ လိုက်မကြည့်ပါ",
			member84:"ငါ့လျှော့စျေးကူပွန်",
			member85:"ဆိုင်ကူပွန်များ",
			member86:"ပလပ်ဖောင်းကူပွန်",
			member87:"အသုံးမပြုသော",
			member88:"အသုံးပြုခံ့",
			member89:"သက်တမ်းကုန်သွားပြီ",
			member90:"ခေါက်",
			member91:"ဆိုင်ကိုသုံးပါ",
			member92:"အပြည့်",
			member93:"ရနိုင်ပါတယ်",
			member94:"ကန့်သတ်ကူပွန်မရှိပါ။",
			member95:"စုဆောင်းသည့်နေ့မှစ၍",
			member96:"ရက်များအတွက် အကျုံးဝင်ပါသည်။",
			member97:"အထိအကျုံးဝင်သည်",
			member98:"မင်းမှာကူပွန်မရှိသေးဘူး",
			member99:"ကူပွန်ပလပ်အင်ကိုထည့်သွင်းမထားပါ",
			member100:"သင်ကူပွန်ကို အသုံးမပြုရသေးပါဘူး။",
			member101:"သင့်တွင် သက်တမ်းကုန်ကူပွန်များ မရသေးပါ။",
			member102:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
			member103:"ပို့ဆောင်ရေးလိပ်စာထည့်ပါ",
			member104:"ငါ့ခြေရာ",
			member105:"လောလောဆယ် ခြေရာတွေ မရှိတော့ဘူး။",
			member106:"အဖွဲ့ဝင်စင်တာ",
			member107:"ကြိုဆိုသောစာမျက်နှာ",
			member108:"ကိုယ်ပိုင်သတင်းအချက်အလက်များ",
			member109:"အကောင့်လုံခြုံရေး",
			member110:"ငါ့ခြေရာ",
			member111:"ငါ့အာရုံ",
			member112:"ကုန်သွယ်မှုစင်တာ",
			member113:"ငါ၏အမိန့်",
			member114:"ငွေပြန်အမ်း/ရောင်းပြီးနောက်",
			member115:"ပယ်ဖျက်ရေးဘူတာ",
			member116:"ပယ်ဖျက်ခြင်းမှတ်တမ်းများ",
			member117:"အကောင့်ဗဟို",
			member118:"အကောင့်လက်ကျန်ငွေ",
			member119:"ငွေထုတ်မှတ်တမ်း",
			member120:"ငါ့လျှော့စျေးကူပွန်",
			member121:"ငါ့ရမှတ်များ",
			member122:"အကောင့်စာရင်း",
			member123:"တိုးတက်မှုတန်ဖိုး",
			member124:"လော့ဂ်အင်မဝင်ပါ",
			member125:"ကူပွန်",
			member126:"အရေးပါတဲ့",
			member127:"ပါရာစီတမော",
			member128:"ငါ့အာရုံ",
			member129:"ဆိုင်သတိထားပါ",
			member130:"ကုန်စည်သတိထားပါ",
			member131:"ငါ၏အမိန့်",
			member132:"ငွေပေးချေမှုကိုဆိုင်းငံ့ထားသည်",
			member133:"ပေးပို့ရန်",
			member134:"လက်ခံရရှိရန်",
			member135:"အကဲဖြတ်ပါ",
			member136:"အသေးစိတ်အချက်အလက်များကိုစစ်ဆေးပါ။",
			member137:"သင်သည် ပစ္စည်းအနည်းငယ်သာဝယ်သည်၊ ဤတွင်အားလုံးသည်အလွတ်ဖြစ်ပြီး၊ မှန်ကန်သောထုတ်ကုန်များကိုရွေးချယ်ပါ။",
			member138:"အခြေခံအချက်အလက်",
			member139:"အကောင့်",
			member140:"မေးလ်",
			member141:"ပြန်ဖြုတ်ပါ",
			member142:"ဖုန်းနံပါတ်",
			member143:"အမည်ပြောင်",
			member144:"ကယ်ပါ",
			member145:"ကိုယ်ပွားအစမ်းကြည့်ခြင်း",
			member146:"မင်းရဲ့ကိုယ်ရေးကိုယ်တာအချက်အလတ်တွေကိုပိုကောင်းအောင်လုပ်ပြီးမင်းသူငယ်ချင်းတွေပိုတွေ့ဖို့ပရိုဖိုင်ပုံကိုတင်လိုက်ပါ။",
			member147:"ရုပ်ပွားတော်၏ အကောင်းဆုံး ပုံသေအရွယ်အစားမှာ 120x120 ပစ်ဇယ်ဖြစ်သည်။",
			member148:"ကယ်ပါ",
			member149:"ကျေးဇူးပြု၍ နာမည်ပြောင်တစ်ခုထည့်ပါ",
			member150:"အများဆုံးအရှည်မှာ စာလုံးရေ 30 ဖြစ်သည်။",
			member151:"နာမည်ပြောင်ကိုပြုပြင်ရာမှာအောင်မြင်ခဲ့တယ်",
			member152:"Avatar ကိုအောင်မြင်စွာပြုပြင်ပြီးပါပြီ",
			member153:"ငါ့ရမှတ်များ",
			member154:"အရင်းအမြစ်",
			member155:"အရေးပါတဲ့",
			member156:"အသေးစိတ်ဖော်ပြချက်",
			member157:"အချိန်",
			member158:"အကောင့်လက်ကျန်ငွေ",
			member159:"အားပြန်သွင်းသည့်ပက်ကေ့ဂျ်များစာရင်း",
			member160:"ငွေဖြည့်အထုပ်အသေးစိတ်",
			member161:"အပိုလက်ဆောင်",
			member162:"အရေးပါတဲ့",
			member163:"အပိုလက်ဆောင်",
			member164:"တိုးတက်မှုတန်ဖိုး",
			member165:"အခုပဲ အားပြန်သွင်းလိုက်ပါ။",
			member166:"အထုပ်နာမည်",
			member167:"အပေါ်ယံတန်ဖိုး",
			member168:"လက်ဆောင်အချက်များ",
			member169:"လက်ဆောင်တန်ဖိုး",
			member170:"လည်ပတ်",
			member171:"ပြန်လည်အားသွင်းမှတ်တမ်း",
			member172:"အထုပ်နာမည်",
			member173:"အပေါ်ယံတန်ဖိုး",
			member174:"စျေးနှုန်း",
			member175:"လက်ဆောင်အချက်များ",
			member176:"လက်ဆောင်တန်ဖိုး",
			member177:"အော်ဒါနံပါတ်",
			member178:"ဆက်စပ်အမိန့်မရှိပါ။",
			member179:"ဆက်စပ်အမိန့်မရှိပါ။",
			member180:"အကောင့်အမျိုးအစား",
			member181:"ငွေထုတ်ပမာဏ",
			member182:"ငွေထုတ်ချိန်",
			member183:"ငွေထုတ်အခြေအနေ",
			member184:"လည်ပတ်",
			member185:"အသေးစိတ်",
			member186:"ငွေထုတ်မှတ်တမ်းမရှိပါ",
			member187:"ငွေထုတ်အသေးစိတ်",
			member188:"လက်ရှိအခြေအနေ",
			member189:"ငွေလွှဲနံပါတ်",
			member190:"ကိုင်တွယ်ခ",
			member191:"လျှောက်လွှာအချိန်",
			member192:"အချိန်ကိုပြန်သုံးသပ်ပါ",
			member193:"ဘဏ်အမည်",
			member194:"အကောင့်လက်ခံခြင်း။",
			member195:"ငြင်းပယ်ရသည့်အကြောင်းရင်း",
			member196:"လွှဲပြောင်းနည်းလမ်းအမည်",
			member197:"လွှဲပြောင်းချိန်"
		},
		order:{
			payment:{
				payment1:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
				payment2:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
				payment3:"တည်းဖြတ်",
				payment4:"သင်ဤလိပ်စာကိုဖျက်လိုသည်မှာသေချာပါသလား",
				payment5:"ဖျက်ပါ",
				payment6:"နောက်ထပ်ပို့ဆောင်ရေးလိပ်စာများ",
				payment7:"အတုကုန်ပစ္စည်းများ ၀ ယ်ယူရန်ကုန်သည်သည်သင့်အားဆက်သွယ်နိုင်ရန်သင်၏မိုဘိုင်းလ်ဖုန်းနံပါတ်ကိုဖြည့်ရန်လိုသည်",
				payment8:"မိုဘိုင်းဖုန်းနံပါတ်",
				payment9:"ကျေးဇူးပြု၍ သင်၏ဖုန်းနံပါတ်ကိုထည့်ပါ",
				payment10:"ပို့ဆောင်ရေးလိပ်စာကို တည်းဖြတ်ပါ။",
				payment11:"နာမည်",
				payment12:"ပို့ဆောင်သူအမည်",
				payment13:"ဆဲလ်ဖုန်း",
				payment14:"ပို့ဆောင်သူ၏မိုဘိုင်းဖုန်းနံပါတ်",
				payment15:"တယ်လီဖုန်း",
				payment16:"Consignee ၏ ပုံသေတယ်လီဖုန်း (ချန်လှပ်ထားနိုင်သည်)",
				payment17:"ဧရိယာ",
				payment18:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
				payment19:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
				payment20:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
				payment21:"လိပ်စာ",
				payment22:"ပယ်ဖျက်ပါ",
				payment23:"သေချာပါတယ်",
				payment24:"ငွေတောင်းခံလွှာအချက်အလက်များထည့်ပါ",
				payment25:"ငွေတောင်းခံလွှာအမျိုးအစား",
				payment26:"ငွေတောင်းခံလွှာခေါင်းစီးအမျိုးအစား",
				payment27:"ကျေးဇူးပြု၍ ခေါင်းစဉ်ကိုဖြည့်ပါ",
				payment28:"ကျေးဇူးပြု၍ အခွန်ထမ်းသက်သေခံနံပါတ်ဖြည့်ပါ",
				payment29:"ငွေတောင်းခံလွှာပို့ရန်လိပ်စာကိုဖြည့်ပါ",
				payment30:"လက်ခံရရှိသောအီးမေးလ်လိပ်စာကိုဖြည့်ပါ။",
				payment31:"ငွေတောင်းခံလွှာအကြောင်းအရာ",
				payment32:"ဟန်ချက်ညီအောင် သုံးသလား",
				payment33:"လက်ကျန်ငွေကိုမသုံးပါနှင့်",
				payment34:"လက်ကျန်ကိုသုံးပါ။",
				payment35:"ကုန်ပစ္စည်း",
				payment36:"စျေးနှုန်း",
				payment37:"ပမာဏ",
				payment38:"စုစုပေါင်း",
				payment39:"ပေးပို့ခြင်းနည်းလမ်း",
				payment40:"ကျေးဇူးပြု၍ ပို့ဆောင်ရေးလိပ်စာကို ဦးစွာထည့်ပါ။",
				payment41:"ကုန်သည်သည် ပေးပို့မှုနည်းလမ်းကို သတ်မှတ်မထားပါ။",
				payment42:"ငွေတောင်းခံလွှာအချက်အလက်",
				payment43:"ငွေတောင်းခံလွှာ မလိုအပ်ပါ။",
				payment44:"ရွတ်ဆိုရန်လိုအပ်သည်",
				payment45:"စာရွက်ပြေစာ",
				payment46:"အီလက်ထရွန်းနစ်ငွေတောင်းခံလွှာ",
				payment47:"ပုဂ္ဂိုလ်ရေး",
				payment48:"စီးပွားရေးလုပ်ငန်း",
				payment49:"ပြန်လည်ပြင်ဆင်ပါ",
				payment50:"ငွေတောင်းခံလွှာအချက်အလက်များသတ်မှတ်ပါ",
				payment51:"တည်ဆောက်သည်",
				payment52:"ကူပွန်",
				payment53:"ကူပွန်ကို ရွေးပြီး လျှော့ပေးတယ်။",
				payment54:"ကူပွန်များမရှိပါ",
				payment55:"ဈေးရောင်းစျေးဝယ်",
				payment56:"အပြည့်အဝလျှော့ချ",
				payment57:"အခမဲ့ပို့ပေးသည်",
				payment58:"အပြည့်",
				payment59:"အခမဲ့ပို့ပေးသည်",
				payment60:"ဝယ်သူမက်ဆေ့ခ်ျ",
				payment61:"စာမထွက်ခင်ကုန်သည်နှင့်ညှိနှိုင်းရန်အကြံပြုသည်",
				payment62:"ပလပ်ဖောင်းကူပွန်",
				payment63:"ကူပွန်ကို ရွေးပြီး လျှော့ပေးတယ်။",
				payment64:"ကူပွန်များမရှိပါ",
				payment65:"ကုန်ပစ္စည်းပမာဏ",
				payment66:"ကုန်စည်",
				payment67:"အခွန်များ",
				payment68:"လျှော့စျေး",
				payment69:"ဆိုင်ကူပွန်များ",
				payment70:"ပလပ်ဖောင်းကူပွန်",
				payment71:"လက်ကျန်ကိုသုံးပါ။",
				payment72:"ဘုံ",
				payment73:"အပိုင်းပိုင်း",
				payment74:"ပေးချေရမည့်ပမာဏများ",
				payment75:"အမိန့်ပြေစာ",
				payment76:"ကူပွန်တစ်ခုကိုရွေးပါ",
				payment77:"နာမည်",
				payment78:"လျှော့စျေး",
				payment79:"ခေါက်",
				payment80:"အသုံးပြု",
				payment81:"ဝေါဟာရ",
				payment82:"ပယ်ဖျက်ပါ",
				payment83:"ရွေးချယ်မှုကို အတည်ပြုပါ။",
				payment84:"ပလက်ဖောင်းကူပွန်ကို ရွေးပါ။",
				payment85:"နာမည်",
				payment86:"လျှော့စျေး",
				payment87:"အသုံးပြု",
				payment88:"အပြည့်",
				payment89:"ရနိုင်ပါတယ်",
				payment90:"မည်သည့်ပမာဏမဆို ရနိုင်ပါသည်။",
				payment91:"ဝေါဟာရ",
				payment92:"ပယ်ဖျက်ပါ",
				payment93:"ရွေးချယ်မှုကို အတည်ပြုပါ။",
				payment94:"စတိုးဆိုင်ကို ရွေးပါ။",
				payment95:"နာမည်",
				payment96:"လိပ်စာ",
				payment97:"အလုပ်ချိန်",
				payment98:"ပို့ဆောင်ချိန်",
				payment99:"ရွေးချယ်ရေးကာလ",
				payment100:"လက်ကျန်ကိုသုံးပါ။",
				payment101:"သင့်အကောင့်လုံခြုံရေးအတွက်၊ ငွေပေးချေမှုစကားဝှက်ကို ဦးစွာသတ်မှတ်ပါ။",
				payment102:"၎င်းကို အဖွဲ့ဝင်စင်တာ, အကောင့်လုံခြုံရေး, ငွေပေးချေမှုစကားဝှက် တွင်သတ်မှတ်နိုင်သည်။",
				payment103:"ယာယီမသတ်မှတ်ထားဘူး။",
				payment104:"ယခုသတ်မှတ်ပါ",
				payment105:"ငွေပေးချေမှုစကားဝှက်",
				payment106:"password မေ့သွားတယ်",
				payment107:"ကျေးဇူးပြု၍ ဖုန်းနံပါတ်ထည့်ပါ",
				payment108:"ပုံစံမမှန်သော ဖုန်းနံပါတ်",
				payment109:"စာရွက်ပြေစာ",
				payment110:"အီလက်ထရွန်းနစ်ငွေတောင်းခံလွှာ",
				payment111:"ပုဂ္ဂိုလ်ရေး",
				payment112:"စီးပွားရေးလုပ်ငန်း",
				payment113:"ကျေးဇူးပြု၍ ကုန်ပို့သူအား ထည့်သွင်းပါ။",
				payment114:"အရှည် 1 မှ 20 လုံး",
				payment115:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
				payment116:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
				payment117:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
				payment118:"ကျေးဇူးပြု၍ အသေးစိတ်လိပ်စာထည့်ပါ",
				payment119:"ကျေးဇူးပြု၍ ငွေတောင်းခံလွှာအမျိုးအစားကို ရွေးချယ်ပါ။",
				payment120:"ကျေးဇူးပြု၍ ငွေတောင်းခံလွှာခေါင်းစီးအမျိုးအစားကို ရွေးချယ်ပါ။",
				payment121:"ငွေတောင်းခံလွှာပါအကြောင်းအရာကိုရွေးပါ",
				payment122:"ကျေးဇူးပြု၍ ခေါင်းစဉ်ကိုဖြည့်ပါ",
				payment123:"ကျေးဇူးပြု၍ အခွန်ထမ်းသက်သေခံနံပါတ်ဖြည့်ပါ",
				payment124:"ငွေတောင်းခံလွှာပို့ရန်လိပ်စာကိုဖြည့်ပါ",
				payment125:"ကျေးဇူးပြု၍ ပြေစာလက်ခံရေးစာတိုက်ပုံးကို ဖြည့်ပါ။",
				payment126:"ပို့ဆောင်ချိန်ကိုရွေးချယ်ပါ",
				payment127:"ကျေးဇူးပြု၍ အချက်အလက်များဖြည့်ပါ",
				payment128:"မှာယူမှုတစ်ခုဖန်တီးရန် လိုအပ်သောဒေတာကို မရရှိပါ။",
				payment129:"အပြည့်",
				payment130:"ရနိုင်ပါတယ်",
				payment131:"မည်သည့်ပမာဏမဆို ရနိုင်ပါသည်။",
				payment132:"အော်ဒါတင်ခြင်း ...",
				payment133:"ကျေးဇူးပြု၍ သင်၏ဖုန်းနံပါတ်ကိုထည့်ပါ",
				payment134:"ကျေးဇူးပြု၍ မှန်ကန်သောဖုန်းနံပါတ်ကိုထည့်ပါ",
				payment135:"ကျေးဇူးပြု၍ သင်၏ပေးပို့လိပ်စာကို ဦးစွာရွေးချယ်ပါ။",
				payment136:"ပေးပို့သည့်နည်းလမ်းကို မသတ်မှတ်ထားပါ။",
				payment137:"ဆိုင်",
				payment138:"လာယူဖို့ဆိုင်မရှိဘူး",
				payment139:"ငွေပေးချေမှုများ"
			},
			order1:"ငွေပြန်အမ်း/ရောင်းပြီးနောက်",
			order2:"ကုန်စည်သတင်းအချက်အလက်",
			order3:"ပြန်အမ်းငွေပမာဏ",
			order4:"ပြန်အမ်းငွေအမျိုးအစား",
			order5:"ပြန်အမ်းငွေအခြေအနေ",
			order6:"လည်ပတ်",
			order7:"ပြန်အမ်းငွေနံပါတ်",
			order8:"ငွေပြန်အမ်းခြင်းအောင်မြင်သည်",
			order9:"ငွေပြန်အမ်းခြင်း",
			order10:"ငွေပြန်အမ်းသည်",
			order11:"ကုန်ပစ္စည်းပြန်ပေးသည်။",
			order12:"ပြန်အမ်းငွေအသေးစိတ်",
			order13:"ဆက်စပ်အမိန့်မရှိပါ။",
			order14:"ယခင်စာမျက်နှာ",
			order15:"နောက်စာမျက်နှာ",
			order16:"ငွေပြန်အမ်း/ရောင်းပြီးနောက်",
			order17:"ပြန်အမ်းငွေအသေးစိတ်",
			order18:"ပလပ်ဖောင်းအခွင့်အရေးများကို အကာအကွယ်ပေးခြင်း",
			order19:"ကုန်ပစ္စည်း",
			order20:"ပမာဏ",
			order21:"ပမာဏ",
			order22:"ပြန်အမ်းငွေပမာဏ",
			order23:"ပြန်လာဘို့အကြောင်းပြချက်",
			order24:"ကျေးဇူးပြု။ ရွေးချယ်ပါ",
			order25:"ပြန်အမ်းငွေ ညွှန်ကြားချက်များ",
			order26:"ကျေးဇူးပြု၍ ပြန်အမ်းငွေဖော်ပြချက် (ချန်လှပ်ထားနိုင်သည်)",
			order27:"တင်ပြသည်",
			order28:"ညှိနှိုင်းမှုမှတ်တမ်း",
			order29:"ညှိနှိုင်းမှုမှတ်တမ်း",
			order30:"ဝယ်သူ",
			order31:"ရောင်းသူ",
			order32:"ပလက်ဖောင်း",
			order33:"ကုန်ပစ္စည်း",
			order34:"ပမာဏ",
			order35:"ပြန်အမ်းငွေပမာဏ",
			order36:"ပြန်လာဘို့အကြောင်းပြချက်",
			order37:"ပြန်အမ်းငွေပမာဏ",
			order38:"ပြန်အမ်းငွေနံပါတ်",
			order39:"လျှောက်လွှာအချိန်",
			order40:"ငွေပြန်အမ်းပါ",
			order41:"ဤလိုင်းအကြောင်းအရာအတွက် ပြန်အမ်းငွေ အချက်အလက်မရှိပါ။",
			order42:"ပြန်အမ်းငွေအတွက်အကြောင်းပြချက်ကိုရွေးပါ",
			order43:"ဖျက်သိမ်းပြီးနောက်၊ လျှောက်လွှာကို ပိတ်ပါမည်။ နောက်ဆက်တွဲတွင် ပြဿနာများရှိနေသေးပါက ထပ်မံလျှောက်ထားနိုင်ပါသည်။",
			order44:"သဲလွန်စ",
			order45:"ပယ်ဖျက်ခြင်းကို အတည်ပြုပါ။",
			order46:"ရုပ်သိမ်းတာ မဟုတ်ဘူး။",
			order47:"ရုပ်သိမ်းခြင်းအောင်မြင်သည်",
			order48:"အော်ဒါစာရင်း",
			order49:"အော်ဒါအသေးစိတ်",
			order50:"အမိန့်အဆင့်အတန်း",
			order51:"ပေးဆောင်ရသည်။",
			order52:"အကဲဖြတ်ခြင်း။",
			order53:"ပြန်လည်သုံးသပ်ခြင်း",
			order54:"အမိန့်သတင်းအချက်အလက်",
			order55:"ဆိုင်",
			order56:"အော်ဒါအမျိုးအစား",
			order57:"အော်ဒါနံပါတ်",
			order58:"အော်ဒါအရောင်းအဝယ်နံပါတ်",
			order59:"ပေးပို့ခြင်းနည်းလမ်း",
			order60:"ဖန်တီးမှုအချိန်",
			order61:"ပိတ်ချိန်",
			order62:"ငွေပေးချေစနစ်",
			order63:"ငွေပေးချေမှုအချိန်",
			order64:"ပွဲများသိမ်းထားသည်",
			order65:"ဝယ်သူမက်ဆေ့ခ်ျ",
			order66:"ပေးပို့သတင်းအချက်အလက်",
			order67:"လက်ခံသူ",
			order68:"မိုဘိုင်းဖုန်းနံပါတ်",
			order69:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
			order70:"ငွေတောင်းခံလွှာအချက်အလက်",
			order71:"ငွေတောင်းခံလွှာအမျိုးအစား",
			order72:"စာရွက်ပြေစာ",
			order73:"အီလက်ထရွန်းနစ်ငွေတောင်းခံလွှာ",
			order74:"ပုဂ္ဂိုလ်ရေး",
			order75:"စီးပွားရေးလုပ်ငန်း",
			order76:"ငွေတောင်းခံလွှာခေါင်းစီးအမျိုးအစား",
			order77:"ပြေ",
			order78:"ငွေတောင်းခံလွှာအကြောင်းအရာ",
			order79:"发票邮寄地址地址",
			order80:"发票接收邮箱",
			order81:"ကုန်စည်သတင်းအချက်အလက်",
			order82:"တစ်ခုချင်းစျေးနှုန်း",
			order83:"ပမာဏ",
			order84:"စုစုပေါင်း",
			order85:"လည်ပတ်",
			order86:"ငွေပြန်အမ်းသည်",
			order87:"ပြန်အမ်းငွေကိုစစ်ဆေးပါ",
			order88:"ကုန်ပစ္စည်းပမာဏ",
			order89:"ကုန်စည်",
			order90:"အခွန်များ",
			order91:"အော်ဒါများတော့",
			order92:"လျှော့စျေး",
			order93:"ကူပွန်ပမာဏ",
			order94:"ပလက်ဖောင်းကူပွန်ပမာဏ",
			order95:"လက်ကျန်ကိုသုံးပါ။",
			order96:"စစ်မှန်သောငွေပေးချေမှု",
			order97:"မှာယူမှုအချက်အလက်များမရရှိပါ",
			order98:"အော်ဒါစာရင်း",
			order99:"အော်ဒါအသေးစိတ်",
			order100:"အမိန့်အဆင့်အတန်း",
			order101:"ပေးဆောင်ရသည်။",
			order102:"အကဲဖြတ်ခြင်း။",
			order103:"ပြန်လည်သုံးသပ်ခြင်း",
			order104:"အမိန့်သတင်းအချက်အလက်",
			order105:"ဆိုင်",
			order106:"အော်ဒါအမျိုးအစား",
			order107:"အော်ဒါနံပါတ်",
			order108:"အော်ဒါအရောင်းအဝယ်နံပါတ်",
			order109:"ပေးပို့ခြင်းနည်းလမ်း",
			order110:"ဖန်တီးမှုအချိန်",
			order111:"ပိတ်ချိန်",
			order112:"ငွေပေးချေစနစ်",
			order113:"ငွေပေးချေမှုအချိန်",
			order114:"ပွဲများသိမ်းထားသည်",
			order115:"ဝယ်သူမက်ဆေ့ခ်ျ",
			order116:"ပေးပို့သတင်းအချက်အလက်",
			order117:"လက်ခံသူ",
			order118:"မိုဘိုင်းဖုန်းနံပါတ်",
			order119:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
			order120:"ပြေစာအချက်အလက်",
			order121:"ငွေတောင်းခံလွှာအမျိုးအစား",
			order122:"စာရွက်ပြေစာ",
			order123:"အီလက်ထရွန်းနစ်ငွေတောင်းခံလွှာ",
			order124:"ကိုယ်ရေးကိုယ်တာ",
			order125:"စီးပွားရေးလုပ်ငန်း",
			order126:"ငွေတောင်းခံလွှာခေါင်းစီးအမျိုးအစား",
			order127:"ပြေ",
			order128:"ငွေတောင်းခံလွှာအကြောင်းအရာ",
			order129:"ပြေစာပို့ရမည့်လိပ်စာ",
			order130:"ပြေစာလက်ခံစာတိုက်ပုံး",
			order131:"ကုန်စည်သတင်းအချက်အလက်",
			order132:"တစ်ခုချင်းစျေးနှုန်း",
			order133:"ပမာဏ",
			order134:"စုစုပေါင်း",
			order135:"လည်ပတ်",
			order136:"ပြန်အမ်းငွေ",
			order137:"ပြန်အမ်းငွေကိုစစ်ဆေးပါ",
			order138:"ကုန်ပစ္စည်းပမာဏ",
			order139:"ကုန်စည်",
			order140:"အခွန်များ",
			order141:"အော်ဒါများတော့",
			order142:"လျှော့စျေး",
			order143:"ကူပွန်",
			order144:"လက်ကျန်ကိုသုံးပါ။",
			order145:"စစ်မှန်သောငွေပေးချေမှု",
			order146:"မှာယူမှုအချက်အလက်များမရရှိပါ",
			order147:"အော်ဒါစာရင်း",
			order148:"အော်ဒါအသေးစိတ်",
			order149:"အမိန့်အဆင့်အတန်း",
			order150:"ပေးဆောင်ရသည်။",
			order151:"အကဲဖြတ်ခြင်း။",
			order152:"ပြန်လည်သုံးသပ်ခြင်း",
			order153:"ကိုယ်ပိုင်အမှတ်",
			order154:"ယူရန်ကုဒ်",
			order155:"အလုပ်ချိန်",
			order156:"ဆက်သွယ်ရန်အချက်အလက်များ",
			order157:"လိပ်စာ",
			order158:"ကုန်စည်သတင်းအချက်အလက်",
			order159:"တစ်ခုချင်းစျေးနှုန်း",
			order160:"ပမာဏ",
			order161:"စုစုပေါင်း",
			order162:"လည်ပတ်",
			order163:"အမိန့်သတင်းအချက်အလက်",
			order164:"ဆိုင်",
			order165:"အော်ဒါအမျိုးအစား",
			order166:"အော်ဒါနံပါတ်",
			order167:"အော်ဒါအရောင်းအဝယ်နံပါတ်",
			order168:"ပေးပို့ခြင်းနည်းလမ်း",
			order169:"ဖန်တီးမှုအချိန်",
			order170:"ပိတ်ချိန်",
			order171:"ငွေပေးချေစနစ်",
			order172:"ငွေပေးချေမှုအချိန်",
			order173:"ပွဲများသိမ်းထားသည်",
			order174:"ဝယ်သူမက်ဆေ့ခ်ျ",
			order175:"ပေးပို့သတင်းအချက်အလက်",
			order176:"လက်ခံသူ",
			order177:"မိုဘိုင်းဖုန်းနံပါတ်",
			order178:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
			order179:"ပြေစာအချက်အလက်",
			order180:"ငွေတောင်းခံလွှာအမျိုးအစား",
			order181:"စာရွက်ပြေစာ",
			order182:"အီလက်ထရွန်းနစ်ငွေတောင်းခံလွှာ",
			order183:"ကိုယ်ရေးကိုယ်တာ",
			order184:"စီးပွားရေးလုပ်ငန်း",
			order185:"ငွေတောင်းခံလွှာခေါင်းစီးအမျိုးအစား",
			order186:"ပြေ",
			order187:"ငွေတောင်းခံလွှာအကြောင်းအရာ",
			order188:"ပြေစာပို့ရမည့်လိပ်စာ",
			order189:"ပြေစာလက်ခံစာတိုက်ပုံး",
			order190:"ကုန်စည်သတင်းအချက်အလက်",
			order191:"တစ်ခုချင်းစျေးနှုန်း",
			order192:"ပမာဏ",
			order193:"စုစုပေါင်း",
			order194:"လည်ပတ်",
			order195:"ပြန်အမ်းငွေ",
			order196:"ပြန်အမ်းငွေကိုစစ်ဆေးပါ",
			order197:"ကုန်ပစ္စည်းပမာဏ",
			order198:"ကုန်စည်",
			order199:"အခွန်များ",
			order200:"အော်ဒါများတော့",
			order201:"လျှော့စျေး",
			order202:"ကူပွန်",
			order203:"လက်ကျန်ကိုသုံးပါ။",
			order204:"စစ်မှန်သောငွေပေးချေမှု",
			order205:"မှာယူမှုအချက်အလက်များမရရှိပါ",
			order206:"အော်ဒါစာရင်း",
			order207:"အော်ဒါအသေးစိတ်",
			order208:"အမိန့်အဆင့်အတန်း",
			order209:"ပေးဆောင်ရသည်။",
			order210:"အကဲဖြတ်ခြင်း။",
			order211:"ပြန်လည်သုံးသပ်ခြင်း",
			order212:"အချက်အလက်များထုတ်ယူပါ",
			order213:"အတည်ပြုရန်ကုတ်",
			order214:"အတည်ပြုခြင်းအခြေအနေ - အတည်ပြုခြင်း",
			order215:"စာထုတ်ချိန်",
			order216:"အမိန့်သတင်းအချက်အလက်",
			order217:"ဆိုင်",
			order218:"အော်ဒါအမျိုးအစား",
			order219:"အော်ဒါနံပါတ်",
			order220:"အော်ဒါအရောင်းအဝယ်နံပါတ်",
			order221:"ပေးပို့ခြင်းနည်းလမ်း",
			order222:"ဖန်တီးမှုအချိန်",
			order223:"ပိတ်ချိန်",
			order224:"ငွေပေးချေစနစ်",
			order225:"ငွေပေးချေမှုအချိန်",
			order226:"ပွဲများသိမ်းထားသည်",
			order227:"ဝယ်သူမက်ဆေ့ခ်ျ",
			order228:"ပေးပို့သတင်းအချက်အလက်",
			order229:"လက်ခံသူ",
			order230:"မိုဘိုင်းဖုန်းနံပါတ်",
			order231:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
			order232:"ပြေစာအချက်အလက်",
			order233:"ငွေတောင်းခံလွှာအမျိုးအစား",
			order234:"စာရွက်ပြေစာ",
			order235:"အီလက်ထရွန်းနစ်ငွေတောင်းခံလွှာ",
			order236:"ကိုယ်ရေးကိုယ်တာ",
			order237:"စီးပွားရေးလုပ်ငန်း",
			order238:"ငွေတောင်းခံလွှာခေါင်းစီးအမျိုးအစား",
			order239:"ပြေ",
			order240:"ငွေတောင်းခံလွှာအကြောင်းအရာ",
			order241:"ပြေစာပို့ရမည့်လိပ်စာ",
			order242:"ပြေစာလက်ခံစာတိုက်ပုံး",
			order243:"ကုန်စည်သတင်းအချက်အလက်",
			order244:"တစ်ခုချင်းစျေးနှုန်း",
			order245:"ပမာဏ",
			order246:"စုစုပေါင်း",
			order247:"လည်ပတ်",
			order248:"ပြန်အမ်းငွေ",
			order249:"ပြန်အမ်းငွေကိုစစ်ဆေးပါ",
			order250:"ကုန်ပစ္စည်းပမာဏ",
			order251:"ကုန်စည်",
			order252:"အခွန်များ",
			order253:"အော်ဒါများတော့",
			order254:"လျှော့စျေး",
			order255:"ကူပွန်",
			order256:"လက်ကျန်ကိုသုံးပါ။",
			order257:"စစ်မှန်သောငွေပေးချေမှု",
			order258:"မှာယူမှုအချက်အလက်များမရရှိပါ",
			order259:"ဖော်ပြချက်ကိုက်ညီမှု",
			order260:"ချီးမွမ်းပါ",
			order261:"ပျမ်းမျှ",
			order262:"အနုတ်အဆင့်သတ်မှတ်ခြင်း",
			order263:"ကျေးဇူးပြုပြီးမင်းဒီမှာအမှတ်ပေးပါ",
			order264:"ကျေးဇူးပြုပြီးမင်းရဲ့သုံးသပ်ချက်ကိုဒီမှာထည့်ပါ",
			order265:"စုစုပေါင်းဓာတ်ပုံ ၆ ပုံ၊ တင်နေဆဲဖြစ်သည်",
			order266:"ဖွင့်သည်",
			order267:"Delivery ဝန်ဆောင်မှု",
			order268:"ဖော်ပြချက်ကိုက်ညီမှု",
			order269:"ဝန်ဆောင်မှုသဘောထား",
			order270:"အမည်မသိ",
			order271:"တင်ပြသည်",
			order272:"တင်ထားသောပုံများအများဆုံးအရေအတွက်သည် ၆ ဖြစ်သည်",
			order273:"ထုတ်ကုန်သုံးသပ်ချက်များကို ဗလာမဖြစ်ရပါ။",
			order274:"ထုတ်ကုန်သုံးသပ်ချက်များကို ဗလာမဖြစ်ရပါ။",
			order275:"အောင်မြင်သောအကဲဖြတ်",
			order276:"ပယ်ဖျက်ခြင်းမှတ်တမ်းများ",
			order277:"ကုန်စည်သတင်းအချက်အလက်",
			order278:"တစ်ခုချင်းစျေးနှုန်း",
			order279:"ပမာဏ",
			order280:"စိစစ်သူ",
			order281:"ဆက်စပ်အမိန့်မရှိပါ။",
			order282:"ယခင်စာမျက်နှာ",
			order283:"နောက်စာမျက်နှာ",
			order284:"ပယ်ဖျက်ခြင်းမှတ်တမ်းများ",
			order285:"နုတ်ထွက်ကြောင်းအတည်ပြုခြင်း",
			order286:"ပမာဏ",
			order287:"Write-off အမျိုးအစား",
			order288:"အတည်ပြုခြင်းအခြေအနေ - အတည်ပြုခြင်း",
			order289:"ဖြတ်ရေးဝန်ထမ်း",
			order290:"စာထုတ်ချိန်",
			order291:"အသုံးပြုမှုကိုအတည်ပြုပါ",
			order292:"ပယ်ဖျက်ရေးဘူတာ",
			order293:"အတည်ပြုကုဒ်ကိုရိုက်ထည့်ပါ",
			order294:"အကြွေးလျှော်ပစ်ခြင်း",
			order295:"ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ထည့်ပါ။",
			order296:"အတည်ပြုပါ",
			order297:"ငွေပြန်အမ်း/ရောင်းပြီးနောက်",
			order298:"ပြန်အမ်းငွေအသေးစိတ်",
			order299:"ကုန်သည်က ငြင်းဆိုပါက ပြန်လည်လျှောက်ထားနိုင်ပါသည်။",
			order300:"",
			order301:"",
			order302:"",
			order303:"",
			order304:"ညှိနှိုင်းမှုမှတ်တမ်း",
			order305:"လိပ်စာပြန်ပေး",
			order306:"ကုန်စည်",
			order307:"ပမာဏ",
			order308:"ပြန်အမ်းငွေပမာဏ",
			order309:"ငွေပြန်အမ်းခြင်းနည်းလမ်း",
			order310:"ပြန်အမ်းပေးရုံသာ",
			order311:"ပြန်အမ်း/ပြန်",
			order312:"ပြန်လာဘို့အကြောင်းပြချက်",
			order313:"ပြန်အမ်းငွေပမာဏ",
			order314:"ပြန်အမ်းငွေနံပါတ်",
			order315:"လျှောက်လွှာအချိန်",
			order316:"ငွေပြန်အမ်းပါ",
			order317:"ပလပ်ဖောင်းအခွင့်အရေးများကို အကာအကွယ်ပေးခြင်း",
			order318:"ပို့ဆောင်ပို့ဆောင်ရေးကိုထည့်ပါ",
			order319:"ထောက်ပံ့ပို့ဆောင်ရေးကုမ္ပဏီ",
			order320:"ကျေးဇူးပြု၍ ထောက်ပံ့ပို့ဆောင်ရေးကုမ္ပဏီသို့ ဝင်ပါ။",
			order321:"ပို့ဆောင်မှုနံပါတ်",
			order322:"ကျေးဇူးပြု၍ ထောက်ပံ့ပို့ဆောင်ရေး မှာယူမှုနံပါတ်ကို ထည့်ပါ။",
			order323:"ချောပို့အချက်အလက်",
			order324:"ရွေးချယ်ခွင့်",
			order325:"မလုပ်တော့",
			order326:"သေချာပါတယ်",
			order327:"ဤလိုင်းအကြောင်းအရာအတွက် ပြန်အမ်းငွေ အချက်အလက်မရှိပါ။",
			order328:"ကျေးဇူးပြု၍ ထောက်ပံ့ပို့ဆောင်ရေးကုမ္ပဏီသို့ ဝင်ပါ။",
			order329:"ကျေးဇူးပြု၍ ထောက်ပံ့ပို့ဆောင်ရေး မှာယူမှုနံပါတ်ကို ထည့်ပါ။",
			order330:"ဤလိုင်းအကြောင်းအရာအတွက် ပြန်အမ်းငွေ အချက်အလက်မရှိပါ။",
			order331:"ဖျက်သိမ်းပြီးနောက်၊ လျှောက်လွှာကို ပိတ်ပါမည်။ နောက်ဆက်တွဲတွင် ပြဿနာများရှိနေသေးပါက ထပ်မံလျှောက်ထားနိုင်ပါသည်။",
			order332:"သဲလွန်စ",
			order333:"ပယ်ဖျက်ခြင်းကို အတည်ပြုပါ။",
			order334:"ရုပ်သိမ်းတာ မဟုတ်ဘူး။",
			order335:"ရုပ်သိမ်းခြင်းအောင်မြင်သည်",
			order336:"ငါ၏အမိန့်",
			order337:"အော်ဒါအသေးစိတ်",
			order338:"ပြန်အမ်းငွေ",
			order339:"ကုန်စည်",
			order340:"ပမာဏ",
			order341:"ပမာဏ",
			order342:"ပြန်အမ်းငွေအမျိုးအစား",
			order343:"ပြန်အမ်းငွေမလိုအပ်ပါ",
			order344:"မရတဲ့အရာ",
			order345:"ပြန်အမ်းငွေပမာဏ",
			order346:"ပြန်လာဘို့အကြောင်းပြချက်",
			order347:"ကျေးဇူးပြု။ ရွေးချယ်ပါ",
			order348:"ပြန်အမ်းငွေ ညွှန်ကြားချက်များ",
			order349:"ကျေးဇူးပြု၍ ပြန်အမ်းငွေဖော်ပြချက် (ချန်လှပ်ထားနိုင်သည်)",
			order350:"တင်ပြသည်",
			order351:"ဤလိုင်းအကြောင်းအရာအတွက် ပြန်အမ်းငွေ အချက်အလက်မရှိပါ။",
			order352:"အောင်မြင်သောလျှောက်လွှာ",
			order353:"ပြန်အမ်းငွေအတွက်အကြောင်းပြချက်ကိုရွေးပါ",
			order354:"အော်ဒါစာရင်း",
			order355:"ထောက်ပံ့ပို့ဆောင်ရေးအသေးစိတ်",
			order356:"Waybill နံပါတ်",
			order357:"ထောက်ပံ့ပို့ဆောင်ရေးကုမ္ပဏီ",
			order358:"မှာယူမှု ပက်ကေ့ဂျ် အချက်အလက်ကို မရရှိခဲ့ပါ။",
			order359:"ငါ၏အမိန့်",
			order360:"အမိန့်အားလုံး",
			order361:"ကုန်စည်သတင်းအချက်အလက်",
			order362:"တစ်ခုချင်းစျေးနှုန်း",
			order363:"ပမာဏ",
			order364:"စစ်မှန်သောငွေပေးချေမှု",
			order365:"အမိန့်အဆင့်အတန်း",
			order366:"လည်ပတ်",
			order367:"အော်ဒါနံပါတ်",
			order368:"အော်ဒါအသေးစိတ်",
			order369:"အကဲဖြတ်ခြင်း။",
			order370:"ပြန်လည်သုံးသပ်ခြင်း",
			order371:"ဆက်စပ်အမိန့်မရှိပါ။",
			order372:"ယခင်စာမျက်နှာ",
			order373:"စျေးကွက်ချက်",
			order374:"အသိအမှတ်ပြုရန်"
		},
		pay:{
			pay1:"သင်၏မှာယူမှုကိုအောင်မြင်စွာတင်သွင်းပြီးဆောင်ရွက်ရန်စောင့်ဆိုင်းနေပါသည်",
			pay2:"ပေးချေရမည့်ပမာဏများ",
			pay3:"အမိန့်သတင်းအချက်အလက်",
			pay4:"ငွေလွှဲနံပါတ်",
			pay5:"အကြောင်းအရာကို မှာယူပါ။",
			pay6:"order ပမာဏ",
			pay7:"ဖန်တီးမှုအချိန်",
			pay8:"ငွေပေးချေစနစ်",
			pay9:"ကုန်သည်သည် ငွေပေးချေမှုနည်းလမ်းကို စီစဉ်မထားပါ။",
			pay10:"ချက်ချင်းပေးချေပါ",
			pay11:"ငွေပေးချေမှုမပြီးမီသင်၏အခြေအနေအတိုင်းအောက်ပါခလုတ်ကိုနှိပ်ပါ",
			pay12:"ပင်မစာမျက်နှာသို့ပြန်သွားရန်",
			pay13:"ငွေပေးချေမှုပြီးစီးခဲ့သည်",
			pay14:"请用微信扫码支付",
			pay15:"支付宝支付",
			pay16:"微信支付",
			pay17:"ငွေပေးချေမှုအောင်မြင်သည်",
			pay18:"ငွေပေးချေမှုမအောင်မြင်ပါ",
			pay19:"ငွေပေးချေမှုပမာဏ",
			pay20:"အဖွဲ့ဝင်စင်တာ",
			pay21:"ပင်မစာမျက်နှာသို့ပြန်သွားရန်",
			pay22:"ငွေပေးချေမှုအချက်အလက်ကို မရရှိပါ။",
			pay23: 'ငွေပေးချေမှုဘောက်ချာကိုတင်ပါ',
			pay24: 'ငွေပေးချေမှုအချက်အလက်များအတည်ပြုရန်ဖောက်သည် ၀ န်ဆောင်မှုအတွက်စိတ်ရှည်ရှည်စောင့်ပါ!',
			pay25: 'ဘဏ်အမည်',
			pay26: 'ဘဏ်အကောင့်',
			pay27: 'ဘဏ်အကောင့်'
		},
		promotion:{
			combo:{
				payment:{
					payment1:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
					payment2:"ပို့ဆောင်ရေးလိပ်စာထည့်ပါ",
					payment3:"တည်းဖြတ်",
					payment4:"ဤလိပ်စာကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
					payment5:"ဖျက်ပါ။",
					payment6:"နောက်ထပ်ပို့ဆောင်ရေးလိပ်စာများ",
					payment7:"အတုကုန်ပစ္စည်းများ ၀ ယ်ယူရန်ကုန်သည်သည်သင့်အားဆက်သွယ်နိုင်ရန်သင်၏မိုဘိုင်းလ်ဖုန်းနံပါတ်ကိုဖြည့်ရန်လိုသည်",
					payment8:"မိုဘိုင်းဖုန်းနံပါတ်",
					payment9:"ကျေးဇူးပြု၍ သင်၏ဖုန်းနံပါတ်ကိုထည့်ပါ",
					payment10:"ပို့ဆောင်ရေးလိပ်စာထည့်ပါ",
					payment11:"ပို့ဆောင်ရေးလိပ်စာကို တည်းဖြတ်ပါ။",
					payment12:"နာမည်",
					payment13:"ပို့ဆောင်သူအမည်",
					payment14:"ဆဲလ်ဖုန်း",
					payment15:"ပို့ဆောင်သူ၏မိုဘိုင်းဖုန်းနံပါတ်",
					payment16:"တယ်လီဖုန်း",
					payment17:"Consignee ၏ ပုံသေတယ်လီဖုန်း (ချန်လှပ်ထားနိုင်သည်)",
					payment18:"ဧရိယာ",
					payment19:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
					payment20:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
					payment21:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
					payment22:"လိပ်စာ",
					payment23:"ရပ်ရွာများ၊ လမ်းများ၊ နှင့် ရုံးအဆောက်အဦများကို ရှာဖွေပါ။",
					payment24:"မလုပ်တော့",
					payment25:"သေချာပါတယ်",
					payment26:"添加发票信息",
					payment27:"发票类型",
					payment28:"发票抬头类型",
					payment29:"请填写抬头名称",
					payment30:"请填写纳税人识别号",
					payment31:"请填写发票邮寄地址",
					payment32:"请填写接收邮箱",
					payment33:"发票内容",
					payment34:"ရုံးသုံးပစ္စည်းများ",
					payment35:"ဒစ်ဂျစ်တယ်",
					payment36:"ဟန်ချက်ညီအောင် သုံးသလား",
					payment37:"လက်ကျန်ငွေကိုမသုံးပါနှင့်",
					payment38:"လက်ကျန်ကိုသုံးပါ။",
					payment39:"ကုန်စည်",
					payment40:"ကုန်စည်",
					payment41:"စျေးနှုန်း",
					payment42:"ပမာဏ",
					payment43:"ပေးပို့ခြင်းနည်းလမ်း",
					payment44:"ကျေးဇူးပြု၍ ပို့ဆောင်ရေးလိပ်စာကို ဦးစွာထည့်ပါ။",
					payment45:"ကုန်သည်သည် ပေးပို့မှုနည်းလမ်းကို သတ်မှတ်မထားပါ။",
					payment46:"ကူပွန်",
					payment47:"ကူပွန်ကို ရွေးပြီး လျှော့ပေးတယ်။",
					payment48:"ကူပွန်များမရှိပါ",
					payment49:"ဈေးရောင်းစျေးဝယ်",
					payment50:"အပြည့်အဝလျှော့ချ",
					payment51:"အခမဲ့ပို့ပေးသည်",
					payment52:"လုပ်ဆောင်ချက်ကမ်းလှမ်းချက်များ",
					payment53:"ပေါင်းစပ်အထုပ်",
					payment54:"လျှော့စျေး",
					payment55:"ဝယ်သူမက်ဆေ့ခ်ျ",
					payment56:"စာမထွက်ခင်ကုန်သည်နှင့်ညှိနှိုင်းရန်အကြံပြုသည်",
					payment57:"发票",
					payment58:"不开票",
					payment59:"开票",
					payment60:"ပလပ်ဖောင်းကူပွန်",
					payment61:"ကုန်ပစ္စည်းပမာဏ",
					payment62:"ကုန်စည်ပို့ဆောင်ရေး",
					payment63:"အခွန်များ",
					payment64:"လျှော့စျေး",
					payment65:"ဆိုင်ကူပွန်များ",
					payment66:"ပလပ်ဖောင်းကူပွန်",
					payment67:"လက်ကျန်ကိုသုံးပါ။",
					payment68:"အပိုင်းပိုင်း၊ ပေးချေရမည့်ပမာဏ",
					payment69:"အမိန့်ပြေစာ",
					payment70:"နာမည်",
					payment71:"လိပ်စာ",
					payment72:"အလုပ်ချိန်",
					payment73:"ပလက်ဖောင်းကူပွန်ကို ရွေးပါ။",
					payment74:"နာမည်",
					payment75:"လျှော့စျေး",
					payment76:"အသုံးပြု",
					payment77:"满{{ scope.row.at_least }}可用",
					payment78:"မည်သည့်ပမာဏမဆို ရနိုင်ပါသည်။",
					payment79:"ဝေါဟာရ",
					payment80:"မလုပ်တော့",
					payment81:"ရွေးချယ်မှုကို အတည်ပြုပါ။",
					payment82:"ကူပွန်တစ်ခုကိုရွေးပါ",
					payment83:"ပို့ဆောင်ချိန်",
					payment84:"ရွေးချယ်ရေးကာလ",
					payment85:"ပိတ်ခြင်း",
					payment86:"ရွေးချယ်မှုကို အတည်ပြုပါ။",
					payment87:"လက်ကျန်ကိုသုံးပါ။",
					payment88:"သင့်အကောင့်လုံခြုံရေးအတွက်၊ ငွေပေးချေမှုစကားဝှက်ကို ဦးစွာသတ်မှတ်ပါ။",
					payment89:"၎င်းကို အဖွဲ့ဝင်စင်တာ, အကောင့်လုံခြုံရေး, ငွေပေးချေမှုစကားဝှက် တွင်သတ်မှတ်နိုင်သည်။",
					payment90:"ယာယီမသတ်မှတ်ထားဘူး။",
					payment91:"ယခုသတ်မှတ်ပါ",
					payment92:"ငွေပေးချေမှုစကားဝှက်",
					payment93:"password မေ့သွားတယ်",
					payment94:"ကျေးဇူးပြု၍ ဖုန်းနံပါတ်ထည့်ပါ။",
					payment95:"ပုံစံမမှန်သော ဖုန်းနံပါတ်",
					payment96:"纸质发票",
					payment97:"အီလက်ထရွန်းနစ်ငွေတောင်းခံလွှာ",
					payment98:"ကိုယ်ရေးကိုယ်တာ",
					payment99:"စီးပွားရေးလုပ်ငန်း",
					payment100:"ကျေးဇူးပြု၍ ကုန်ပို့သူအား ထည့်သွင်းပါ။",
					payment101:"အရှည် 1 မှ 20 လုံး",
					payment102:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
					payment103:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
					payment104:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
					payment105:"ကျေးဇူးပြု၍ အသေးစိတ်လိပ်စာထည့်ပါ",
					payment106:"请选择发票类型",
					payment107:"请选择发票抬头类型",
					payment108:"请选择发票内容",
					payment109:"ကျေးဇူးပြု၍ ခေါင်းစဉ်ကိုဖြည့်ပါ",
					payment110:"ကျေးဇူးပြု၍ အခွန်ထမ်းသက်သေခံနံပါတ်ဖြည့်ပါ",
					payment111:"ငွေတောင်းခံလွှာပို့ရန်လိပ်စာကိုဖြည့်ပါ",
					payment112:"ကျေးဇူးပြု၍ ပြေစာလက်ခံရေးစာတိုက်ပုံးကို ဖြည့်ပါ။",
					payment113:"အော်ဒါဖန်တီးရန်လိုအပ်သောဒေတာများမရရှိခဲ့ပါ",
					payment114:"အပြည့်",
					payment115:"ရနိုင်ပါတယ်",
					payment116:"မည်သည့်ပမာဏမဆို ရနိုင်ပါသည်။",
					payment117:"အော်ဒါတင်ခြင်း",
					payment118:"ကျေးဇူးပြု၍ သင်၏ပေးပို့လိပ်စာကို ဦးစွာရွေးချယ်ပါ။",
					payment119:"စတိုးဆိုင်သည် ပေးပို့မှုနည်းလမ်းကို မသတ်မှတ်ထားပေ။",
					payment120:"ကုန်ပစ္စည်းကို ကောက်ယူနိုင်သည့် စတိုးဆိုင်မရှိပါ၊ ကျေးဇူးပြု၍ အခြားပေးပို့သည့်နည်းလမ်းကို ရွေးချယ်ပါ။",
					payment121:"ငွေပေးချေမှုများ"
				},
				combo1:"ကုန်စည်သတင်းအချက်အလက်",
				combo2:"စျေးနှုန်း",
				combo3:"ပမာဏ",
				combo4:"စာရင်းမလုံလောက်ခြင်း၊ ပိုလျှံခြင်း။",
				combo5:"ဝယ်ယူမှုပမာဏ",
				combo6:"သင့်အတွက် ကယ်တင်ပါ။",
				combo7:"အထုပ်ဈေးနှုန်း",
				combo8:"အခုဝယ်ပါ"
			},
			groupbuy:{
				detail:{
					detail1:"အဆုံးအထိသာ ကျန်တော့သည်။",
					detail2:"ဤဗီဒီယိုကို ခေတ္တမရနိုင်ပါ၊ ကျေးဇူးပြု၍ နောက်မှ ထပ်စမ်းကြည့်ပါ။",
					detail3:"အုပ်စုဝယ်ယူမှုပလပ်အင်ကို ထည့်သွင်းမထားပါ။",
					detail4:"ပွဲပြီးသွားပါပြီ။",
					detail5:"အပိုင်းပိုင်း",
					detail6:"သင်လော့ဂ်အင်မဝင်ပါ",
					detail7:"ပစ္စည်းကုန်သွားပါပြီ",
					detail8:"ဝယ်ယူသည့်ပမာဏသည် ၀ယ်မရပါ။",
					detail9:"အုပ်စုလိုက် ဝယ်ယူမှု လုပ်ဆောင်ချက် ပြီးဆုံးသွားပါပြီ။",
					detail10:"အောင်မြင်မှုကိုပယ်ဖျက်ပါ",
					detail11:"လိုက်ခဲ့သည်",
					detail12:"ဗီဒီယို",
					detail13:"ပုံ",
					detail14:"ကုန်ပစ္စည်းကိုလိုက်နာပါ",
					detail15:"Customer Service ကိုဆက်သွယ်ပါ",
					detail16:"အုပ်စုလိုက်ဝယ်",
					detail17:"ကောင်းကင်",
					detail18:"နာရီ",
					detail19:"မိနစ်",
					detail20:"ဒုတိယအကြိမ်",
					detail21:"အုပ်စုလိုက်စျေးနှုန်း",
					detail22:"မူရင်းဈေး",
					detail23:"စုစည်းအကဲဖြတ်ခြင်း။",
					detail24:"စုဆောင်းရောင်းချခြင်း",
					detail25:"အမြန်ပို့ဆောင်ခ အခမဲ့",
					detail26:"ချောသည်မလွဲမသွေဖြစ်သည်",
					detail27:"သို့ပေးပို့ပါ။",
					detail28:"ကျေးဇူးပြု၍ ပေးပို့ရမည့်လိပ်စာကို ရွေးချယ်ပါ။",
					detail29:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
					detail30:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
					detail31:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
					detail32:"ဝန်ဆောင်မှု",
					detail33:"သင်္ဘောနှင့်ရောင်းချပြီးနောက်ဝန်ဆောင်မှုပေးဆောင်",
					detail34:"ပမာဏ",
					detail35:"ကုန်ပစ္စည်းလက်ဝယ်ရှိ",
					detail36:"စတင်ဝယ်ယူပါ",
					detail37:"ချက်ချင်းကောက်ကိုင်လိုက်သည်",
					detail38:"ဤထုတ်ကုန်ကိုဖယ်ရှားလိုက်သည်",
					detail39:"QR ကုဒ်ပုံ",
					detail40:"အာမခံဝန်ဆောင်မှု",
					detail41:"",
					detail42:"",
					detail43:"ကုန်စည်စစ်စစ် ၀ န်ဆောင်မှုအာမခံပါသည်",
					detail44:"စစ်မှန်သောလုံခြုံရေး",
					detail45:"ငွေပေးချေပြီး ၂ နာရီအတွင်းပို့ဆောင်ပေးပါသည်",
					detail46:"နှစ်နာရီအတွင်း ပို့ဆောင်ပေးပါသည်။",
					detail47:"ကတိသစ္စာပြန်ပေးသည်",
					detail48:"အစမ်းစင်တာ",
					detail49:"အဖွဲ့အစည်းအတည်ပြုခြင်း",
					detail50:"ကုန်ပစ္စည်းအရည်အသွေးပြဿနာများ၊ ကွဲလွဲဖော်ပြချက်များ (သို့) ကုန်ပစ္စည်းများလက်ခံရရှိရန်ပျက်ကွက်မှုများရှိပါကသင်ငွေပြန်အမ်းရန် (သို့) ပြန်လည်တောင်းခံပိုင်ခွင့်ရှိသည်၊ အသွားအပြန်စာပို့ခကိုရောင်းသူမှကျခံရလိမ့်မည်။",
					detail51:"စားသုံးသူအသင်း၏ အာမခံချက်",
					detail52:"ကိုယ်ပိုင်လုပ်ငန်းလုပ်သော",
					detail53:"စတိုးဆိုင် အဆင့်သတ်မှတ်ချက်",
					detail54:"ကုန်ပစ္စည်းအကြောင်းအရာ",
					detail55:"ရောင်းသူ ၀ န်ဆောင်မှု",
					detail56:"ပေးပို့မြန်နှုန်း",
					detail57:"ဆက်သွယ်ရန်နံပါတ်",
					detail58:"စတိုးဆိုင်ထဲသို့ ၀ င ်၍ လမ်းလျှောက်ပါ",
					detail59:"မှာယူမှုကိုပယ်ဖျက်ပါ",
					detail60:"ဆိုင်ကိုလိုက်သွားပါ။",
					detail61:"ကုန်ပစ္စည်းအသေးစိတ်",
					detail62:"ကုန်စည်ဂုဏ်ရည်",
					detail63:"ထုတ်ကုန်ပြန်လည်သုံးသပ်ခြင်း။",
					detail64:"အားလုံးသုံးသပ်ချက်",
					detail65:"ဆိုင်မှအကြောင်းပြန်သည်",
					detail66:"အပိုဆောင်းအကဲဖြတ်",
					detail67:"ယခင်စာမျက်နှာ",
					detail68:"နောက်စာမျက်နှာ",
					detail69:"ဤထုတ်ကုန်အတွက် သုံးသပ်ချက်မရှိပါ။"
				},
				payment:{
					payment1:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
					payment2:"ပို့ဆောင်ရေးလိပ်စာထည့်ပါ",
					payment3:"တည်းဖြတ်",
					payment4:"ဖျက်ပါ။",
					payment5:"နောက်ထပ်ပို့ဆောင်ရေးလိပ်စာများ",
					payment6:"အတုကုန်ပစ္စည်းများ ၀ ယ်ယူရန်ကုန်သည်သည်သင့်အားဆက်သွယ်နိုင်ရန်သင်၏မိုဘိုင်းလ်ဖုန်းနံပါတ်ကိုဖြည့်ရန်လိုသည်",
					payment7:"မိုဘိုင်းဖုန်းနံပါတ်",
					payment8:"ကျေးဇူးပြု၍ သင်၏ဖုန်းနံပါတ်ကိုထည့်ပါ",
					payment9:"ပို့ဆောင်ရေးလိပ်စာထည့်ပါ",
					payment10:"ပို့ဆောင်ရေးလိပ်စာကို တည်းဖြတ်ပါ။",
					payment11:"နာမည်",
					payment12:"ပို့ဆောင်သူအမည်",
					payment13:"ဆဲလ်ဖုန်း",
					payment14:"ပို့ဆောင်သူ၏မိုဘိုင်းဖုန်းနံပါတ်",
					payment15:"တယ်လီဖုန်း",
					payment16:"Consignee ၏ ပုံသေတယ်လီဖုန်း (ချန်လှပ်ထားနိုင်သည်)",
					payment17:"ဧရိယာ",
					payment18:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
					payment19:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
					payment20:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
					payment21:"လိပ်စာ",
					payment22:"ရပ်ရွာများ၊ လမ်းများ၊ နှင့် ရုံးအဆောက်အဦများကို ရှာဖွေပါ။",
					payment23:"မလုပ်တော့",
					payment24:"သေချာပါတယ်",
					payment25:"",
					payment26:"",
					payment27:"",
					payment28:"",
					payment29:"",
					payment30:"",
					payment31:"လက်ခံရရှိသောအီးမေးလ်လိပ်စာကိုဖြည့်ပါ။",
					payment32:"ငွေတောင်းခံလွှာအကြောင်းအရာ",
					payment33:"ရုံးသုံးပစ္စည်းများ",
					payment34:"ဒစ်ဂျစ်တယ်",
					payment35:"မလုပ်တော့",
					payment36:"သေချာပါတယ်",
					payment37:"ဟန်ချက်ညီအောင် သုံးသလား",
					payment38:"လက်ကျန်ငွေကိုမသုံးပါနှင့်",
					payment39:"လက်ကျန်ပစ္စည်းများကိုသုံးပါ",
					payment40:"စျေးနှုန်း",
					payment41:"ပမာဏ",
					payment42:"ပေးပို့ခြင်းနည်းလမ်း",
					payment43:"ကျေးဇူးပြု၍ ပို့ဆောင်ရေးလိပ်စာကို ဦးစွာထည့်ပါ။",
					payment44:"ကုန်သည်သည် ပေးပို့မှုနည်းလမ်းကို သတ်မှတ်မထားပါ။",
					payment45:"ပွဲများသိမ်းထားသည်",
					payment46:"အုပ်စုလိုက်ဝယ်",
					payment47:"အပိုင်းပိုင်းများမှ၊ အုပ်စု ၀ ယ်စျေးကိုခံစားပါ",
					payment48:"ဝယ်သူမက်ဆေ့ခ်ျ",
					payment49:"စာမထွက်ခင်ကုန်သည်နှင့်ညှိနှိုင်းရန်အကြံပြုသည်",
					payment50:"ဥပဒေကြမ်း",
					payment51:"ငွေတောင်းခံမှုမရှိပါ",
					payment52:"ငွေတောင်းခံလွှာ",
					payment53:"ကုန်ပစ္စည်းပမာဏ",
					payment54:"ကုန်စည်",
					payment55:"အခွန်များ",
					payment56:"လျှော့စျေး",
					payment57:"လက်ကျန်ကိုသုံးပါ။",
					payment58:"အပိုင်းပိုင်း၊ ပေးချေရမည့်ပမာဏ",
					payment59:"အမိန့်ပြေစာ",
					payment60:"စတိုးဆိုင်ကို ရွေးပါ။",
					payment61:"နာမည်",
					payment62:"လိပ်စာ",
					payment63:"အလုပ်ချိန်",
					payment64:"ပို့ဆောင်ချိန်",
					payment65:"ရွေးချယ်ရေးကာလ",
					payment66:"ပိတ်ခြင်း",
					payment67:"ရွေးချယ်မှုကို အတည်ပြုပါ။",
					payment68:"လက်ကျန်ကိုသုံးပါ။",
					payment69:"သင့်အကောင့်လုံခြုံရေးအတွက်၊ ငွေပေးချေမှုစကားဝှက်ကို ဦးစွာသတ်မှတ်ပါ။",
					payment70:"၎င်းကို အဖွဲ့ဝင်စင်တာ, အကောင့်လုံခြုံရေး, ငွေပေးချေမှုစကားဝှက် တွင်သတ်မှတ်နိုင်သည်။",
					payment71:"ယာယီမသတ်မှတ်ထားဘူး။",
					payment72:"ယခုသတ်မှတ်ပါ",
					payment73:"ငွေပေးချေမှုစကားဝှက်",
					payment74:"password မေ့သွားတယ်",
					payment75:"ကျေးဇူးပြု၍ ဖုန်းနံပါတ်ထည့်ပါ",
					payment76:"ပုံစံမမှန်သော ဖုန်းနံပါတ်",
					payment77:"စာရွက်ပြေစာ",
					payment78:"အီလက်ထရွန်းနစ်ငွေတောင်းခံလွှာ",
					payment79:"ကိုယ်ရေးကိုယ်တာ",
					payment80:"စီးပွားရေးလုပ်ငန်း",
					payment81:"ကျေးဇူးပြု၍ ကုန်ပို့သူအား ထည့်သွင်းပါ။",
					payment82:"အရှည် 1 မှ 20 လုံး",
					payment83:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
					payment84:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
					payment85:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
					payment86:"ကျေးဇူးပြု၍ အသေးစိတ်လိပ်စာထည့်ပါ",
					payment87:"ကျေးဇူးပြု၍ ငွေတောင်းခံလွှာအမျိုးအစားကို ရွေးချယ်ပါ။",
					payment88:"ကျေးဇူးပြု၍ ငွေတောင်းခံလွှာခေါင်းစီးအမျိုးအစားကို ရွေးချယ်ပါ။",
					payment89:"ငွေတောင်းခံလွှာပါအကြောင်းအရာကိုရွေးပါ",
					payment90:"ကျေးဇူးပြု၍ ခေါင်းစဉ်ကိုဖြည့်ပါ",
					payment91:"ကျေးဇူးပြု၍ အခွန်ထမ်းသက်သေခံနံပါတ်ဖြည့်ပါ",
					payment92:"ငွေတောင်းခံလွှာပို့ရန်လိပ်စာကိုဖြည့်ပါ",
					payment93:"ကျေးဇူးပြု၍ ပြေစာလက်ခံရေးစာတိုက်ပုံးကို ဖြည့်ပါ။",
					payment94:"ပို့ဆောင်ချိန်ကိုရွေးချယ်ပါ",
					payment95:"အော်ဒါဖန်တီးရန်လိုအပ်သောဒေတာများမရရှိခဲ့ပါ",
					payment96:"အော်ဒါတင်ခြင်း",
					payment97:"ကျေးဇူးပြု၍ သင်၏ဖုန်းနံပါတ်ကိုထည့်ပါ",
					payment98:"ကျေးဇူးပြု၍ မှန်ကန်သောဖုန်းနံပါတ်ကိုထည့်ပါ",
					payment99:"ကျေးဇူးပြု၍ သင်၏ပေးပို့လိပ်စာကို ဦးစွာရွေးချယ်ပါ။",
					payment100:"ဆိုင်",
					payment101:"ပေးပို့သည့်နည်းလမ်းကို မသတ်မှတ်ထားပါ။",
					payment102:"လာယူရန်စတိုးဆိုင်မရှိပါ၊ ကျေးဇူးပြု၍ အခြားပေးပို့သည့်နည်းလမ်းကို ရွေးချယ်ပါ။",
					payment103:"ငွေပေးချေမှုများ"
				},
				groupbuy1:"အုပ်စုလိုက် ၀ ယ်နေပါသည်",
				groupbuy2:"အုပ်စုလိုက်စျေးနှုန်း",
				groupbuy3:"လောလောဆယ်အုပ်စုလိုက် ၀ ယ်ယူသောထုတ်ကုန်များမရှိပါ၊ ၎င်းကိုစစ်ဆေးရန်ပင်မစာမျက်နှာသို့သွားပါ",
				groupbuy4:"ယခင်စာမျက်နှာ",
				groupbuy5:"နောက်စာမျက်နှာ",
				groupbuy6:"အုပ်စုဝယ်ယူမှုပလပ်အင်ကို ထည့်သွင်းမထားပါ။"
			},
			seckill:{
				detail:{
					detail1:"အဆုံးအထိသာကျန်တော့သည်",
					detail2:"ဤဗီဒီယိုကိုယာယီမရနိုင်ပါ၊ ကျေးဇူးပြု၍ နောက်မှထပ်ကြိုးစားပါ",
					detail3:"spike plug-in ကိုထည့်သွင်းမထားပါ",
					detail4:"အချိန်အကန့်အသတ်ရှိသောလှုပ်ရှားမှုသည်မစရသေးပါ",
					detail5:"သင်လော့ဂ်အင်မဝင်ပါ",
					detail6:"ပစ္စည်းကုန်သွားပါပြီ",
					detail7:"၀ ယ်ယူနိုင်သောပမာဏကို ၀ ယ ်၍ မရပါ",
					detail8:"ပွဲကပြီးသွားပြီ",
					detail9:"အချိန်အကန့်အသတ်ရှိသောပွဲပြီးသွားပါပြီ",
					detail10:"အောင်မြင်မှုကိုပယ်ဖျက်ပါ",
					detail11:"လိုက်ခဲ့သည်",
					detail12:"ဗီဒီယို",
					detail13:"ပုံ",
					detail14:"ကုန်ပစ္စည်းကိုလိုက်နာပါ",
					detail15:"Customer Service ကိုဆက်သွယ်ပါ",
					detail16:"အချိန်အကန့်အသတ်ရှိသည်",
					detail17:"ကောင်းကင်",
					detail18:"နာရီ",
					detail19:"မိနစ်",
					detail20:"ဒုတိယအကြိမ်",
					detail21:"ဈေးတက်တယ်။",
					detail22:"မူရင်းဈေး",
					detail23:"စုဆောင်းအကဲဖြတ်ခြင်း",
					detail24:"စုဆောင်းရောင်းချခြင်း",
					detail25:"ကုန်စည်",
					detail26:"အမြန်ပို့ဆောင်ခအခမဲ့ပါ",
					detail27:"ချောသည်မလွဲမသွေဖြစ်သည်",
					detail28:"သို့ပို့ဆောင်",
					detail29:"ကျေးဇူးပြု၍ ပေးပို့ရမည့်လိပ်စာကိုရွေးပါ",
					detail30:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
					detail31:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
					detail32:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
					detail33:"ဝန်ဆောင်မှု",
					detail34:"သင်္ဘောနှင့်ရောင်းချပြီး ၀ န်ဆောင်မှုကိုပေးသည်",
					detail35:"ပမာဏ",
					detail36:"ကုန်ပစ္စည်းလက်ဝယ်ရှိ",
					detail37:"ချက်ချင်းကောက်ကိုင်လိုက်သည်",
					detail38:"ဤထုတ်ကုန်ကိုဖယ်ရှားလိုက်သည်",
					detail39:"ကုန်သည်ဝန်ဆောင်မှု",
					detail40:"အာမခံဝန်ဆောင်မှု",
					detail41:"",
					detail42:"",
					detail43:"စစ်မှန်သောလုံခြုံရေး",
					detail44:"နှစ်နာရီအတွင်း ပို့ဆောင်ပေးပါသည်။",
					detail45:"",
					detail46:"အစမ်းစင်တာ",
					detail47:"အဖွဲ့အစည်းအတည်ပြုခြင်း",
					detail48:"ကုန်ပစ္စည်းအရည်အသွေးပြဿနာများ၊ ကွဲလွဲဖော်ပြချက်များ (သို့) ကုန်ပစ္စည်းများလက်ခံရရှိရန်ပျက်ကွက်မှုများရှိပါကသင်ငွေပြန်အမ်းရန် (သို့) ပြန်လည်တောင်းခံပိုင်ခွင့်ရှိသည်၊ အသွားအပြန်စာပို့ခကိုရောင်းသူမှကျခံရလိမ့်မည်။",
					detail49:"စားသုံးသူအသင်း၏ အာမခံချက်",
					detail50:"ကိုယ်ပိုင်လုပ်ငန်းလုပ်သော",
					detail51:"စတိုးဆိုင် အဆင့်သတ်မှတ်ချက်",
					detail52:"ကုန်ပစ္စည်းအကြောင်းအရာ",
					detail53:"ရောင်းသူ ၀ န်ဆောင်မှု",
					detail54:"ပေးပို့မြန်နှုန်း",
					detail55:"မိနစ်",
					detail56:"ဆက်သွယ်ရန်နံပါတ်",
					detail57:"စတိုးဆိုင်ထဲသို့ ၀ င ်၍ လမ်းလျှောက်ပါ",
					detail58:"မှာယူမှုကိုပယ်ဖျက်ပါ",
					detail59:"ဆိုင်ကိုလိုက်သွားပါ။",
					detail60:"ကုန်ပစ္စည်းအသေးစိတ်",
					detail61:"ကုန်စည်ဂုဏ်ရည်",
					detail62:"ထုတ်ကုန်ပြန်လည်သုံးသပ်ခြင်း။",
					detail63:"အားလုံးသုံးသပ်ချက်",
					detail64:"ဆိုင်မှအကြောင်းပြန်သည်",
					detail65:"အပိုဆောင်းအကဲဖြတ်",
					detail66:"ဆိုင်မှအကြောင်းပြန်သည်",
					detail67:"ယခင်စာမျက်နှာ",
					detail68:"နောက်စာမျက်နှာ",
					detail69:"ဤထုတ်ကုန်အတွက် သုံးသပ်ချက်မရှိပါ။"
				},
				list:{
					list1:"ဆက်ပြီးနားထောင်ပါ",
					list2:"စတင်ပါတော့မည်။",
					list3:"အထိတ်တလန့်ဝယ်သည်",
					list4:"ကျော်လွန်",
					list5:"ဆက်ပြီးနားထောင်ပါ",
					list6:"ဈေးတက်တယ်။",
					list7:"ချက်ချင်းကောက်ကိုင်လိုက်သည်",
					list8:"ယခင်စာမျက်နှာ",
					list9:"နောက်စာမျက်နှာ",
					list10:"လက်ရှိတွင် တိုးများနေသော ထုတ်ကုန်များ မရှိသေးပါ၊ ကြည့်ရှုရန် ပင်မစာမျက်နှာသို့ သွားပါ။",
					list11:"အဆုံးအထိသာကျန်တော့သည်",
					list12:"spike plug-in ကိုထည့်သွင်းမထားပါ",
					list13:"ဆူးကုန်ပြီ။'",
					list14:"ဆူးကမစဘူး",
					list15:"ပွဲက ပြီးသွားပါပြီ။ ပြင်းထန်တဲ့ ပွဲမစသေးတဲ့အတွက် စောင့်မျှော်ကြည့်ရှုလိုက်ပါ။"
				},
				payment:{
					payment1:"ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
					payment2:"ပို့ဆောင်ရေးလိပ်စာထည့်ပါ",
					payment3:"တည်းဖြတ်",
					payment4:"သင်ဤလိပ်စာကိုဖျက်လိုသည်မှာသေချာပါသလား",
					payment5:"ဖျက်ပါ။",
					payment6:"နောက်ထပ်ပို့ဆောင်ရေးလိပ်စာများ",
					payment7:"အတုကုန်ပစ္စည်းများ ၀ ယ်ယူရန်ကုန်သည်သည်သင့်အားဆက်သွယ်နိုင်ရန်သင်၏မိုဘိုင်းလ်ဖုန်းနံပါတ်ကိုဖြည့်ရန်လိုသည်",
					payment8:"မိုဘိုင်းဖုန်းနံပါတ်",
					payment9:"ကျေးဇူးပြု၍ သင်၏ဖုန်းနံပါတ်ကိုထည့်ပါ",
					payment10:"ပို့ဆောင်ရေးလိပ်စာထည့်ပါ",
					payment11:"ပို့ဆောင်ရေးလိပ်စာကို တည်းဖြတ်ပါ။",
					payment12:"နာမည်",
					payment13:"ပို့ဆောင်သူအမည်",
					payment14:"ဆဲလ်ဖုန်း",
					payment15:"ပို့ဆောင်သူ၏မိုဘိုင်းဖုန်းနံပါတ်",
					payment16:"တယ်လီဖုန်း",
					payment17:"Consignee ၏ ပုံသေတယ်လီဖုန်း (ချန်လှပ်ထားနိုင်သည်)",
					payment18:"ဧရိယာ",
					payment19:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
					payment20:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
					payment21:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
					payment22:"ရပ်ရွာများ၊ လမ်းများ၊ နှင့် ရုံးအဆောက်အဦများကို ရှာဖွေပါ။",
					payment23:"ပယ်ဖျက်ပါ",
					payment24:"သေချာပါတယ်",
					payment25:"ငွေတောင်းခံလွှာအချက်အလက်များထည့်ပါ",
					payment26:"ငွေတောင်းခံလွှာအမျိုးအစား",
					payment27:"ငွေတောင်းခံလွှာခေါင်းစီးအမျိုးအစား",
					payment28:"ကျေးဇူးပြု၍ ခေါင်းစဉ်ကိုဖြည့်ပါ",
					payment29:"ကျေးဇူးပြု၍ အခွန်ထမ်းသက်သေခံနံပါတ်ဖြည့်ပါ",
					payment30:"ငွေတောင်းခံလွှာပို့ရန်လိပ်စာကိုဖြည့်ပါ",
					payment31:"လက်ခံရရှိသောအီးမေးလ်လိပ်စာကိုဖြည့်ပါ။",
					payment32:"ငွေတောင်းခံလွှာအကြောင်းအရာ",
					payment33:"ရုံးသုံးပစ္စည်းများ",
					payment34:"ဒစ်ဂျစ်တယ်",
					payment35:"ပယ်ဖျက်ပါ",
					payment36:"သေချာပါတယ်",
					payment37:"ဟန်ချက်ညီအောင် သုံးသလား",
					payment38:"လက်ကျန်ငွေကိုမသုံးပါနှင့်",
					payment39:"လက်ကျန်ကိုသုံးပါ။",
					payment40:"ကုန်စည်",
					payment41:"စျေးနှုန်း",
					payment42:"ပမာဏ",
					payment43:"ပေးပို့ခြင်းနည်းလမ်း",
					payment44:"ကျေးဇူးပြု၍ ပို့ဆောင်ရေးလိပ်စာကို ဦးစွာထည့်ပါ။",
					payment45:"ကုန်သည်သည် ပေးပို့မှုနည်းလမ်းကို သတ်မှတ်မထားပါ။",
					payment46:"ပွဲများသိမ်းထားသည်",
					payment47:"အချိန်အကန့်အသတ်ရှိသည်",
					payment48:"ဝယ်သူမက်ဆေ့ခ်ျ",
					payment49:"ဥပဒေကြမ်း",
					payment50:"ငွေတောင်းခံမှုမရှိပါ",
					payment51:"ငွေတောင်းခံလွှာ",
					payment52:"ကုန်ပစ္စည်းပမာဏ",
					payment53:"ကုန်စည်",
					payment54:"အခွန်များ",
					payment55:"လျှော့စျေး",
					payment56:"လက်ကျန်ကိုသုံးပါ။",
					payment57:"အပိုင်းပိုင်း၊ ပေးချေရမည့်ပမာဏ",
					payment58:"အမိန့်ပြေစာ",
					payment59:"နာမည်",
					payment60:"လိပ်စာ",
					payment61:"အလုပ်ချိန်",
					payment62:"ပို့ဆောင်ချိန်",
					payment63:"ရွေးချယ်ရေးကာလ",
					payment64:"ပိတ်ခြင်း",
					payment65:"ရွေးချယ်မှုကို အတည်ပြုပါ။",
					payment66:"လက်ကျန်ကိုသုံးပါ။",
					payment67:"သင့်အကောင့်လုံခြုံရေးအတွက်၊ ငွေပေးချေမှုစကားဝှက်ကို ဦးစွာသတ်မှတ်ပါ။",
					payment68:"၎င်းကို အဖွဲ့ဝင်စင်တာ, အကောင့်လုံခြုံရေး, ေပေးချေမှုစကားဝှက် တွင်သတ်မှတ်နိုင်သည်။",
					payment69:"ယာယီမသတ်မှတ်ထားဘူး။",
					payment70:"ယခုသတ်မှတ်ပါ",
					payment71:"password မေ့သွားတယ်",
					payment72:"ကျေးဇူးပြု၍ ဖုန်းနံပါတ်ထည့်ပါ",
					payment73:"ပုံစံမမှန်သော ဖုန်းနံပါတ်",
					payment74:"စာရွက်ပြေစာ",
					payment75:"အီလက်ထရွန်းနစ်ငွေတောင်းခံလွှာ",
					payment76:"ကိုယ်ရေးကိုယ်တာ",
					payment77:"စီးပွားရေးလုပ်ငန်း",
					payment78:"ကျေးဇူးပြု၍ ကုန်ပို့သူအား ထည့်သွင်းပါ။",
					payment79:"အရှည် 1 မှ 20 လုံး",
					payment80:"ကျေးဇူးပြု၍ ပြည်နယ်တစ်ခုကိုရွေးပါ",
					payment81:"ကျေးဇူးပြု၍ မြို့တစ်မြို့ကိုရွေးပါ",
					payment82:"ကျေးဇူးပြု၍ ခရိုင်/ခရိုင်ကိုရွေးပါ",
					payment83:"ကျေးဇူးပြု၍ အသေးစိတ်လိပ်စာထည့်ပါ",
					payment84:"ကျေးဇူးပြု၍ ငွေတောင်းခံလွှာအမျိုးအစားကို ရွေးချယ်ပါ။",
					payment85:"ကျေးဇူးပြု၍ ငွေတောင်းခံလွှာခေါင်းစီးအမျိုးအစားကို ရွေးချယ်ပါ။",
					payment86:"ငွေတောင်းခံလွှာပါအကြောင်းအရာကိုရွေးပါ",
					payment87:"ကျေးဇူးပြု၍ ခေါင်းစဉ်ကိုဖြည့်ပါ",
					payment88:"ကျေးဇူးပြု၍ အခွန်ထမ်းသက်သေခံနံပါတ်ဖြည့်ပါ",
					payment89:"ငွေတောင်းခံလွှာပို့ရန်လိပ်စာကိုဖြည့်ပါ",
					payment90:"လက်ခံစာတိုက်ပုံး၌ငွေတောင်းခံလွှာဖြည့်ပါ၊ ပေးပို့ရမည့်အချိန်ကိုရွေးပါ။ မှာယူမှုပြုလုပ်ရန်လိုအပ်သောဒေတာများမရရှိပါ", 
					payment91:"အော်ဒါဖန်တီးရန်လိုအပ်သောဒေတာများမရရှိခဲ့ပါ",
					payment92:"အော်ဒါတင်ခြင်း",
					payment93:"ကျေးဇူးပြု၍ သင်၏ဖုန်းနံပါတ်ကိုထည့်ပါ",
					payment94:"ကျေးဇူးပြု၍ မှန်ကန်သောဖုန်းနံပါတ်ကိုထည့်ပါ",
					payment95:"ကျေးဇူးပြု၍ သင်၏ပေးပို့လိပ်စာကို ဦးစွာရွေးချယ်ပါ။",
					payment96:"ဆိုင်",
					payment97:"ပေးပို့သည့်နည်းလမ်းကို မသတ်မှတ်ထားပါ။",
					payment98:"ဆိုင်",
					payment99:"လာယူရန်စတိုးဆိုင်မရှိပါ၊ ကျေးဇူးပြု၍ အခြားပေးပို့သည့်နည်းလမ်းကို ရွေးချယ်ပါ။'"
				}
			
			},
			topic:{
				topic1:"အကြောင်းအရာအလိုက်အဖြစ်အပျက်များအသေးစိတ်",
				topic2:"ကုန်ပစ္စည်းအသေးစိတ်"
			}
		},
		shop:{
			components:{
				components1:"တည်နေရာ",
				components2:"အရောင်း",
				components3:"အဖွဲ့ဝင်များကို လိုက်နာပါ။"
			},
			list:{
				list1:"ဖော်ပြချက်ကိုက်ညီမှု",
				list2:"ဝန်ဆောင်မှုသဘောထား",
				list3:"ပေးပို့မြန်နှုန်း",
				list4:"တယ်လီဖုန်း",
				list5:"လိပ်စာ",
				list6:"မှာယူမှုကိုပယ်ဖျက်ပါ",
				list7:"ဆိုင်ကိုလိုက်ကြည့်ပါ",
				list8:"ကုန်ပစ္စည်းရှာဖွေပါ",
				list9:"ရှာဖွေမှုရလဒ်များ:",
				list10:"ကျယ်ကျယ်ပြန့်ပြန့်",
				list11:"အရောင်း",
				list12:"စျေးနှုန်း",
				list13:"အခမဲ့ပို့ပေးသည်",
				list14:"အနိမ့်ဆုံးစျေးနှုန်း",
				list15:"အမြင့်ဆုံးစျေးနှုန်း",
				list16:"သေချာပါတယ်",
				list17:"လူတွေကပိုက်ဆံပေးတယ်",
				list18:"အခမဲ့ပို့ပေးသည်",
				list19:"လျှော့စျေးများကိုကန့်သတ်ပါ",
				list20:"သင်ရှာနေသောကုန်ပစ္စည်းကိုမတွေ့ပါ။ အခြားအခြေအနေတစ်ခုကို စမ်းကြည့်ပါ။",
				list21:"ယခင်စာမျက်နှာ",
				list22:"နောက်စာမျက်နှာ",
				list23:"ဆိုင် ID ပျောက်နေသည်",
				list24:"အောင်မြင်စွာ လိုက်မကြည့်ပါ",
				list25:"တိုကင်မရှိပါ",
				list26:"လိုက်ခဲ့သည်"
			},
			shop1:"ကိုယ်ပိုင်လုပ်ငန်းလုပ်သော",
			shop2:"ဖော်ပြချက်ကိုက်ညီမှု",
			shop3:"ဝန်ဆောင်မှုသဘောထား",
			shop4:"ပေးပို့မြန်နှုန်း",
			shop5:"တယ်လီဖုန်း",
			shop6:"လိပ်စာ",
			shop7:"မှာယူမှုကိုပယ်ဖျက်ပါ",
			shop8:"ဆိုင်ကိုလိုက်ကြည့်ပါ",
			shop9:"ကုန်ပစ္စည်းရှာဖွေပါ",
			shop10:"ကျွန်ုပ်တို့၏ဆိုင်တွင်အကြံပြုထားသည်",
			shop11:"လျှော့စျေးများကိုကန့်သတ်ပါ",
			shop12:"ယခင်စာမျက်နှာ",
			shop13:"နောက်စာမျက်နှာ",
			shop14:"အောင်မြင်စွာ လိုက်မကြည့်ပါ",
			shop15:"တိုကင်မရှိပါ",
			shop16:"လိုက်ခဲ့သည်",
			shop17:"ရှာဖွေမှုရလဒ်များ",
			shop18:"ကျယ်ကျယ်ပြန့်ပြန့်",
			shop19:"အရောင်း",
			shop20:"CEDIT",
			shop21:"ဆိုင်ရှာပါ",
			shop22:"အဓိကစီးပွားရေး",
			shop23:"နိုင်ပြီ",
			shop24:"လူတွေက လိုက်နာတယ်။",
			shop25:"လိုချင်တဲ့ဆိုင်ကို ရှာမတွေ့ဘူး။ အခြားအခြေအနေတစ်ခုကို စမ်းကြည့်ပါ။",
			shop26:"ယခင်စာမျက်နှာ",
			shop27:"နောက်စာမျက်နှာ",
			live:{
				live1:"အသက်ရှင်စာရင်း",
				live2:"ဒီအသက်ရှင်တဲ့ ပရိုဂရမ် အဆုံးသတ်ပြီ။ အခြားအသက်ရှင်တဲ့ ပရိုဂရမ်များကို ကြည့်ပါ",
				live3:"အသက်ရှင်တဲ့ လွှမ်းမိုးချက်ကို မစတင်ပါဘူး။ အသက်ရှင်တဲ့ လွှမ်းမိုးချက်ကို စတင်ဖို့ anchor ကို စောင့်ကြည့်ပ",
				live4:"အသက်ရှင်တဲ့ ထုတ်လုပ်ငန်းစာရင်",
				live5:"ကုန်သွယ်မှုမရှိဘူ",
				live6:"အသက်ရှင်တဲ့ လွှမ်းမိုးချက်မဟုတ်ဘူ",
				live7:"Living",
				live8:"End"	
			}
		}
	}
}
