import { render, staticRenderFns } from "./NsAside.vue?vue&type=template&id=5dc6f0ba&scoped=true&"
import script from "./NsAside.vue?vue&type=script&lang=js&"
export * from "./NsAside.vue?vue&type=script&lang=js&"
import style0 from "./NsAside.vue?vue&type=style&index=0&id=5dc6f0ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc6f0ba",
  null
  
)

export default component.exports