export const lang = {

	//公共
	common: {
		//语言包名称
		name: 'English',
		language:'switch language',
		currentlanguage:"current language"
	},
	
	components:{
		cargo:{
			cargo1: "person payment",
			cargo2: "inventory remaining",
			cargo3: "is proprietary",
			cargo4: "package mail",
			cargo5: "virtual goods",
			cargo6: "collection",
			cargo7: "joins the shopping cart",
			cargo8: "collection success",
			cargo9: "commodity title"
		},
		message:{
			message1: "inventory",
			message2: "sales",
			message3: "view merchandise",
			message4: "order number",
			message5: "view order",
			message6: "customer service consulting",
			message7: "you have a merchandise message",
			message8: "you have an order message",
			message9: "you have a picture message",
			message10: "has no chat records",
			message11: "click to load",
			message12: "customer service online",
			message13: "customer service is not online",
			message14: "send",
			message15:"can send up to 150 words at a time!",
			message16: "cannot send blank content"
			
		},
		components1: "merchandise selection"
	},
	layout:{
		components:{
			components1: "cart",
			components2: "my order",
			components3: "my footprint",
			components4: "my attention",
			components5: "my coupon",
			components6: "returns to the top",
			components7: "platform customer service",
			components8: "you have not yet been logged in",
			components9: "friendship links",
			components10: "all goods category",
			components11: "merchandise",
			components12: "store",
			components13: "search",
			components14: "hot search",
			components15: "my cart",
			components16: "newly added products",
			components17: "delete",
			components18: "total",
			components19: "goods, total amount",
			components20: "Ks",
			components21: "go to the shopping cart",
			components22:" your shopping cart is empty. go around and pick out the goods!",
			components23: "merchandise",
			components24: "store",
			components25: "nationwide",
			components26: "switch to the city",
			components27: "hello, please login",
			components28: "exit",
			components29: "free registration",
			components30: "my order",
			components31: "my footprint",
			components32: "my attention",
			components33: "help center",
			components34: "bulletin",
			components35: "mobile phone mall",
			components36: "welcomes you",
			components37: "all goods category",
			components38: "store home page",
			components39: "all merchandise"
		},
		layout1: "welcome to land",
		layout2: "welcome to register"
		
	},
	view:{
		auth:{
			find:{
				find1: "mobile phone number cannot be empty",
				find2: "please enter the correct mobile phone number",
				find3: "please enter a new login password",
				find4: "password length cannot be less than",
				find5: "digits",
				find6: "password should contain",
				find7: "number",
				find8: "small letter",
				find9: "apital letter",
				find10: "special character",
				find11: "please enter a confirmation password",
				find12: "two password input is inconsistent",
				find13: "to get the dynamic code",
				find14: "please enter the verification code",
				find15: "please enter the sms dynamic code",
				find16: "the mobile phone number is not registered",
				find17: "can be retrieved after",
				find18: "enter your mobile phone number",
				find19: "authentication",
				find20: "to reset the password",
				find21: "complete",
				find22: "please enter your registered mobile phone number",
				find23: "next step",
				find24: "please enter the verification code",
				find25: "please enter the sms dynamic code",
				find26: "next step",
				find27: "please enter a new login password",
				find28: "please enter a new password again" ,
				find29: "reset password successfully",
				find30:	"Login again"
			},
			login:{
				login1: "account to log in",
				login2: "please enter the account number",
				login3: "please enter a login password",
				login4: "please enter the verification code",
				login5: "seven days automatic login",
				login6: "forgets the password?",
				login7: "login",
				login8:	"has no account number?",
				login9: "register now",
				login10: "mobile dynamic code login",
				login11: "please enter your mobile phone number",
				login12: "please enter the sms dynamic code",
				login13: "login",
				login14: "register now",
				login15: "mobile phone number cannot be empty",
				login16: "please enter the correct mobile phone number",
				login17: "to get the dynamic code",
				login18: "please enter a login account",
				login19: "login successfully",
				login20: "can be retrieved after"
			},
			auth1: "user registration",
			auth2: "please enter the user name",
			auth3: "please enter a password",
			auth4: "please enter the verification code",
			auth5: "reads and agrees with the",
			auth6: "service agreement",
			auth7: "registration",
			auth8: "already has an account number",
			auth9: "login now",
			auth10: "please enter your mobile phone number",
			auth11: "please enter the verification code",
			auth12: "please enter the sms dynamic code",
			auth13: "mobile phone number cannot be empty",
			auth14: "please enter the correct mobile phone number",
			auth15: "please enter a password",
			auth16: "password length cannot be less than",
			auth17: "digits",
			auth18: "password should contain",
			auth19: "number",
			auth20: "small letter",
			auth21: "capital letter",
			auth22: "special character",
			auth23: "to get the dynamic code",
			auth24: "platform registration is not enabled",
			auth25: "please read the protocol first and check"
		},
		cms:{
			help:{
				help1: "home page",
				help2: "help lists",
				help3: "help details",
				help4: "help"
			},
			noctice:{
				noctice1: "home page",
				noctice2: "bulletin list",
				noctice3: "announcement details",
				noctice4: "bulletin",
				noctice5: "latest announcement",
				noctice6: "shopping mall announcement",
				noctice7: "previous page",
				noctice8: "next page"
			}
		},
		goods:{
			cart:{
				cart1:" sure you want to delete the item?",
				cart2: "tip information",
				cart3: "determines that",
				cart4: "cancel",
				cart5: "was deleted successfully",
				cart6: "please select the item to delete",
				cart7:" confirm that it wants to empty these goods?",
				cart8: "commodity information",
				cart9: "price",
				cart10: "number",
				cart11: "minor count",
				cart12: "operation",
				cart13: "is proprietary",
				cart14: "delete",
				cart15: "failure goods",
				cart16: "part",
				cart17: "failure",
				cart18: "select all",
				cart19: "clear invalid babbaby",
				cart20: "selected item",
				cart21: "part",
				cart22: "total (excluding freight)",
				cart23: "settlement",
				cart24:" your shopping cart is empty. go around and pick out the goods!"
			},
			detail:{
				detail1: "distance ends only",
				detail2: "this video is not currently available, please try again later",
				detail3: "you have not yet been logged in",
				detail4: "non-existent",
				detail5: " Ks ",
				detail6: "part",
				detail7: "full",
				detail8: "minus",
				detail9: "send",
				detail10: "integration",
				detail11: "sends a piece of",
				detail12: "discount coupon",
				detail13: "coupon",
				detail14: "package mail",
				detail15: "get a success",
				detail16: "merchandise is sold out",
				detail17: "purchases cannot be 0",
				detail18: "joins the shopping cart successfully",
				detail19: "commodity purchase limit",
				detail20: "has now purchased",
				detail21: "activity has ended",
				detail22: "cancel succeeded",
				detail23: "focus on success",
				detail24: "video",
				detail25: "picture",
				detail26: "focuses on merchandise",
				detail27: "contact customer service",
				detail28: "limited time discount",
				detail29: "day",
				detail30: "hours",
				detail31: "minutes",
				detail32: "seconds",
				detail33: "sales price",
				detail34: "original price",
				detail35: "market price",
				detail36: "cumulative evaluation",
				detail37: "sales",
				detail38: "shipping",
				detail39: "express free mail",
				detail40: "express is unavoidable mail",
				detail41: "coupon",
				detail42: "fold",
				detail43: "full minus",
				detail44: "delivery to",
				detail45: "please select the distribution address",
				detail46: "please select the province",
				detail47: "please select the city",
				detail48: "please select the district / county",
				detail49: "services",
				detail50: "was created by",
				detail51: "ships and provides after-sales service",
				detail52: "number",
				detail53: "inventory",
				detail54: "is shipped by the official flagship store and offers after-sales service",
				detail55: "buy it now",
				detail56: "joins the shopping cart",
				detail57: "stock inventory",
				detail58: "cell phone buy",
				detail59: "guaranteed services",
				detail60: "meets the premise of 7 days of return application without reason, package goods need to the buyer to bear the return postage, non-package goods need the buyer to bear the delivery and return postage",
				detail61: "7 days return",
				detail62: "goods support genuine warranty services",
				detail63: "genuine guarantee",
				detail64: "delivery within 2 hours after payment",
				detail65: "two-hour delivery",
				detail66: "return commitment",
				detail67: "trial center",
				detail68: "entity validation",
				detail69: "if you have any quality problems, inconsistent description or no receipt, you will have the right to apply for a refund or return of the goods, and the postage will be borne by the seller.",
				detail70: "consumer association guarantee",
				detail71: "self-support",
				detail72: "store rating",
				detail73: "commodity description",
				detail74: "seller service",
				detail75: "shipping speed",
				detail76: "points",
				detail77: "contact number",
				detail78: "enters the shop",
				detail79: "unfollow",
				detail80: "focus on the store",
				detail81: "combination package price",
				detail82: "buy it now",
				detail83: "merchandise details",
				detail84: "commodity properties",
				detail85: "commodity evaluation",
				detail86: "all reviews",
				detail87: "store home reply",
				detail88: "additional evaluation",
				detail89: "previous page",
				detail90: "next page",
				detail91: "this product has not been evaluated oh",
				detail92: "aftermarket support"
			},
			list:{
				list1: "search results",
				list2: "brand",
				list3: "all brands",
				list4: "multiple select",
				list5: "integrated",
				list6: "sales",
				list7: "price",
				list8: "package mail",
				list9: "is proprietary",
				list10: "minimum price",
				list11: "top price",
				list12: "determine",
				list13: "person payment",
				list14: "limited time discount",
				list15: "previous page",
				list16: "next page",
				list17: "didn't find the item you want.try it with different conditions ",
				list18: "joins the shopping cart successfully",
				list19: "collection success",
				list20: "cancel collection successfully"
			},
			goods1: "brand zone",
			goods2: "has no more brands, go to the home page",
			goods3: "previous page",
			goods4: "next page",
			goods5: "has no merchandise category",
			goods6: "coupon center",
			goods7: "saves more, gets more coupons",
			goods8: "time tickets",
			goods9: "overlay is using",
			goods10: "is of a wide variety",
			goods11: "my coupon",
			goods12: "store coupons",
			goods13: "platform coupons",
			goods14: "fold",
			goods15: "uses the store",
			goods16: "no threshold coupon",
			goods17: "full",
			goods18: "can use",
			goods19: "receives the date",
			goods20: "is valid within the days",
			goods21: "is valid until",
			goods22: "get it immediately",
			goods23: "goes to use",
			goods24: "has no coupon",
			goods25: "coupon plugin is not installed",
			goods26: "get a success",
			goods27: "does not exist"
		},
		index:{
			index:{
				index1: "distance end",
				index2: "merchandise",
				index3: "store",
				index4: "activity has ended",
				index5: "get a success",
				index6: "does not exist"	
			},
			components:{
				components1: "merchandise pictures",
				components2: "brand pictures"
			},
			index1: "direct search for",
			index2: "please enter the city name",
			index3: "hot cities",
			index4: "select by pinyin initials",
			index5: "nationwide",
			index6: "city site plugin is not installed",
			index7: "the web site is closed",
			index8: "returns to the previous page",
			index9: "returns to the homepage",
			index10: "login",
			index11: "registration",
			index12: "shopping mall information",
			index13: "more",
			index14: "shopping mall services",
			index15: "investment entry",
			index16: "business center",
			index17: "store management",
			index18: "get voucher center",
			index19: "more",
			index20: "full",
			index21: "is available",
			index22: "get it immediately",
			index23: "goes to use",
			index24: "limited seconds kill",
			index25: "more goods",
			index26: "person payment",
			index27: "package mail",
			index28: "limited time discount",
			index29: "merchandise",
			index30: "store",
			index31: "search",
			index32: "my shopping cart"
		},
		member:{
			security:{
				security1: "login password",
				security2: "There is a risk of theft of your internet account. It is recommended that you change your password regularly to protect your account security",
				security3: "modification",
				security4: "mailbox binding",
				security5: "after verification, it can be used to quickly retrieve the login password and payment password",
				security6: "cell phone verification",
				security7: "after erification, it can be used to quickly retrieve the login password and payment password, and receive the account balance change reminder.",
				security8: "modify login password",
				security9: "original password",
				security10: "current password",
				security11: "new password",
				security12: "confirmation password",
				security13: "please confirm the new password",
				security14: "save",
				security15: "cancel",
				security16: "captcha",
				security17: "please enter the verification code",
				security18: "dynamic code",
				security19: "please enter a dynamic code",
				security20: " clicking on get dynamic code will bind to your mobile phone number",
				security21: "send verification code",
				security22: "bound mailbox",
				security23: "current mailbox",
				security24: "mailbox",
				security25: "please enter the mailbox",
				security26: "binding phone number",
				security27: "current mobile phone number",
				security28: "phone number",
				security29: "binding payment password",
				security30: "please enter a 6-bit payment password, recommended not using duplicate or continuous numbers",
				security31: "the captcha was sent to",
				security32: "new password cannot be the original password",
				security33: "please enter the password again",
				security34: "two input password inconsistent",
				security35: "please enter a new password",
				security36: "please enter the correct mailbox",
				security37: "please enter the correct mobile phone number",
				security38: "please enter the original password",
				security39: "please enter the correct mailbox",
				security40: "to get the verification code",
				security41: "input an sms verification code",
				security42: "to get the dynamic code",
				security43:" you have not yet bound your mobile phone number yet, please bind your mobile phone number first?",
				security44: "tip information",
				security45: "determines that",
				security46: "cancel",
				security47: "modified the password successfully",
				security48: "the has been sent",
				security49: "do not repeat the click",
				security50: "mailbox binding was successfully",
				security51: "the mobile phone number was bound successfully",
				security52: "please set the payment password",
				security53: "please enter again",
				security54: "please enter the number",
				security55: "modified the payment password successfully",
				security56: "two password input is different"
			},
			member1: "my available balance",
			member2: "cash withdrawal",
			member3: "recharge",
			member4: "cash withdrawal balance",
			member5: "non-withdrawable balance",
			member6: "source",
			member7: "amount",
			member8: "details",
			member9: "time",
			member10: "previous page",
			member11: "next page",
			member12: "edit account",
			member13: "name",
			member14: "please enter your real name",
			member15: "cell phone",
			member16: "please enter your mobile phone number",
			member17: "account type",
			member18: "please select the account type",
			member19: "bank name",
			member20: "please enter a bank name",
			member21: "cash withdrawal account",
			member22: "please enter the withdrawal account",
			member23: "save",
			member24: "the mobile phone number cannot be empty",
			member25: "please enter the correct mobile phone number",
			member26: "please enter your real name",
			member27: "please select the account type",
			member28: "please enter a bank name",
			member29: "please enter the withdrawal account",
			member30: "account list",
			member31: "new account",
			member32: "default",
			member33: "cell phone number",
			member34: "cash withdrawal account",
			member35: "account type",
			member36: "bank name",
			member37: "the is set to the default",
			member38: "editor",
			member39: "delete",
			member40: "edit receiving address",
			member41: "name",
			member42: "consignenee name",
			member43: "cell phone",
			member44: "consignee mobile phone number",
			member45: "phone number",
			member46: "consignee landline",
			member47: "address",
			member48: "please select the province",
			member49: "please select the city",
			member50: "please select the district / county",
			member51: "detailed address",
			member52: "default",
			member53: "yes",
			member54: "no",
			member55: "please enter the consignee name",
			member56: "please enter a detailed address",
			member57: "account balance",
			member58: "withdrawal application",
			member59: "makes cash withdrawals to",
			member60: "temporarily does not support wechat withdrawal, please choose alipay",
			member61: "cash withdrawal account",
			member62: "please select a cash withdrawal account",
			member63: "cash amount",
			member64: "the withdrawable balance is",
			member65: "minimum cash amount is",
			member66: "the handling fee is",
			member67: "Withdrawal to",
			member68: "please add withdrawal method",
			member69: "please enter the cash amount",
			member70: "cash amount exceeds available cash amount",
			member71: "cash amount less than the minimum amount",
			member72: "Withdrawal application succeeded",
			member73: "dear",
			member74: "You haven't paid attention to the product yet",
			member75: "store",
			member76: "is proprietary",
			member77: "commodity description",
			member78: "seller service",
			member79: "shipping speed",
			member80: "contact number",
			member81: "points",
			member82: "you haven't followed the store yet!",
			member83: "unfocus success",
			member84: "my coupon",
			member85: "store coupons",
			member86: "platform coupons",
			member87: "not used",
			member88: "Used",
			member89: "expired",
			member90: "discount",
			member91: "uses the store",
			member92: "satisfy",
			member93: "available",
			member94: "no threshold coupon",
			member95: "receives the date",
			member96: "valid within days",
			member97: "the is valid until",
			member98: "you don't have any coupons yet, oh ",
			member99: "the coupon plugin is not installed",
			member100: "you haven't used the coupons yet, oh ",
			member101: "you haven't had expired coupons yet oh ",
			member102: "receiving address",
			member103: "add a receiving address",
			member104: "my footprint",
			member105: "temporarily has no footprint",
			member106: "member centre",
			member107: "welcome page",
			member108: "personal information",
			member109: "account security",
			member110: "my footprint",
			member111: "my attention",
			member112: "trading center",
			member113: "my order",
			member114: "refund / after-sale",
			member115: "desk",
			member116: "write record",
			member117: "account center",
			member118: "account balance",
			member119: "withdrawal record",
			member120: "my coupon",
			member121: "my points",
			member122: "account list",
			member123: "growth value",
			member124: "is not logged in",
			member125: "coupon",
			member126: "integration",
			member127: "balance",
			member128: "my attention",
			member129: "store focus",
			member130: "commodity focus on",
			member131: "my order",
			member132: "pending payment",
			member133: "waiting to be shipped",
			member134: "is to be received",
			member135: "remains to be evaluated",
			member136: "view details",
			member137: "you buy too few things, here is empty, go and pick the right goods",
			member138: "basic information",
			member139: "account #",
			member140: "mailbox",
			member141: "to debind",
			member142: "phone number",
			member143: "nickname",
			member144: "save",
			member145: "head picture preview",
			member146:" improve your profile and help you meet more friends.",
			member147:" the best default image size is 120x120 pixels.",
			member148: "save",
			member149: "please enter a nickname",
			member150: "has a maximum length of 30 characters",
			member151: "changes nickname success",
			member152: "avatar modified successfully",
			member153: "my points",
			member154: "source",
			member155: "integration",
			member156: "details",
			member157: "time",
			member158: "account balance",
			member159: "recharge package list",
			member160: "top-up package details",
			member161: "extra bonus",
			member162: "integration",
			member163: "extra bonus",
			member164: "growth value",
			member165: "to recharge immediately",
			member166: "package name",
			member167: "face value",
			member168: "giving points",
			member169: "gift growth value",
			member170: "operation",
			member171: "recharge record",
			member172: "package name",
			member173: "face value",
			member174: "purchase price",
			member175: "giving points",
			member176: "gift growth value",
			member177: "order number",
			member178: "No relevant orders",
			member179: "withdrawal record",
			member180: "account type",
			member181: "cash amount",
			member182: "withdrawtime",
			member183: "withdrawal status",
			member184: "operation",
			member185: "details",
			member186: "has no cash withdrawal record",
			member187: "withdrawdetails",
			member188: "current status",
			member189: "transaction number",
			member190: "handling fee",
			member191: "application time",
			member192: "audit time",
			member193: "bank name",
			member194: "collection account number",
			member195: "rejects the reason",
			member196: "transfer method name",
			member197: "transfer time"
		},
		order:{
			payment:{
				payment1: "receiving address",
				payment2: "add a receiving address",
				payment3: "editor",
				payment4: "are you sure want to delete this address",
				payment5: "delete",
				payment6: "more shipping address",
				payment7: "needs to fill in your mobile phone number to facilitate merchants to contact you",
				payment8: "phone number",
				payment9: "please enter your cell phone number",
				payment10: "edit receiving address",
				payment11: "name",
				payment12: "consignenee name",
				payment13: "cell phone",
				payment14: "consignee mobile phone number",
				payment15: "phone number",
				payment16: "consignee landline number (optional)",
				payment17: "region",
				payment18: "please select the province",
				payment19: "please select the city",
				payment20: "please select the district / county",
				payment21: "detailed address",
				payment22: "take, eliminate",
				payment23: "is true, definite",
				payment24: "add invoice information",
				payment25: "invoice type",
				payment26: "invoice head type",
				payment27: "please fill in the head-up name",
				payment28: "please fill in the taxpayer id number",
				payment29: "please fill in the invoice mailing address",
				payment30: "please fill in the receiving mailbox",
				payment31: "invoice content",
				payment32: "Use the balance to pay",
				payment33: "unused balance",
				payment34: "use balance",
				payment35: "merchandise",
				payment36: "price",
				payment37: "number",
				payment38: "minor count",
				payment39: "delivery method",
				payment40: "please add shipping address",
				payment41: "merchants do not configured distribution mode",
				payment42: "invoice information",
				payment43: "no invoice",
				payment44: "Invoice required",
				payment45: "paper invoice",
				payment46: "electronic invoice",
				payment47: "personal",
				payment48: "enterprise",
				payment49: "modification",
				payment50: "please set up the invoice information",
				payment51: "settings",
				payment52: "coupon",
				payment53: "selected coupon, offer",
				payment54: "does not use the coupons",
				payment55: "store offers",
				payment56: "full minus",
				payment57: "full package mail",
				payment58: "full",
				payment59: "package mail",
				payment60: "buyers message ",
				payment61: "recommends coordinating with merchants before leaving a message",
				payment62: "platform coupons",
				payment63: "selected coupon, offer",
				payment64: "does not use the coupons",
				payment65: "commodity amount",
				payment66: "shipping",
				payment67: "tax and fee",
				payment68: "offers",
				payment69: "store coupons",
				payment70: "platform coupons",
				payment71: "to use the balance",
				payment72: "total",
				payment73: "part",
				payment74: "amount payable",
				payment75: "order settlement",
				payment76: "select coupons",
				payment77: "name",
				payment78: "offers",
				payment79: "fold",
				payment80: "uses the",
				payment81: "term",
				payment82: "cancel",
				payment83: "to confirm the selection",
				payment84: "select platform coupons",
				payment85: "name",
				payment86: "offers",
				payment87: "uses the",
				payment88: "full",
				payment89: "is available",
				payment90: "any amount is available",
				payment91: "term",
				payment92: "cancel",
				payment93: "to confirm the selection",
				payment94: "select store",
				payment95: "name",
				payment96: "address",
				payment97: "business hours",
				payment98: "time of service",
				payment99: "select time",
				payment100: "to use the balance",
				payment101: "for your account security, please set the payment password",
				payment102: "can be set'in member center', 'account security', 'payment password'",
				payment103: "Not set temporarily",
				payment104: "set up now",
				payment105: "payment password",
				payment106: "forgets the password",
				payment107: "please enter your mobile phone number",
				payment108: "Mobile phone number format error",
				payment109: "paper invoice",
				payment110: "electronic invoice",
				payment111: "personal",
				payment112: "enterprise",
				payment113: "please enter the consignee",
				payment114: "length in 1 to 20 characters",
				payment115: "please select the province",
				payment116: "please select the city",
				payment117: "please select the district / county",
				payment118: "please enter a detailed address",
				payment119: "please select the invoice type",
				payment120: "please select the invoice header type",
				payment121: "please select the invoice content",
				payment122: "please fill in the head-up name",
				payment123: "please fill in the taxpayer id number",
				payment124: "please fill in the invoice mailing address",
				payment125: "please fill in the invoice receipt mailbox",
				payment126: "select delivery time",
				payment127: "please fill out the information",
				payment128:" did not get the data required to create the order!",
				payment129: "full",
				payment130: "is available",
				payment131: "any amount is available",
				payment132:" order submission in...",
				payment133: "please enter your cell phone number",
				payment134: "please enter the correct cell phone number",
				payment135: "please first select your shipping address",
				payment136: "does not set the delivery mode",
				payment137: "shop",
				payment138: "has no pick-up stores",
				payment139: "payment in"
			},
			order1: "refund / after-sale",
			order2: "commodity information",
			order3: "refund amount",
			order4: "reback type",
			order5: "refund status",
			order6: "operation",
			order7: "reback number",
			order8: "reback success",
			order9: "refund in",
			order10: "refund",
			order11: "returns",
			order12: "refund details",
			order13: "No relevant orders",
			order14: "previous page",
			order15: "next page",
			order16: "refund / after-sale",
			order17: "refund details",
			order18: "platform rights protection",
			order19: "merchandise",
			order20: "number",
			order21: "amount",
			order22: "refund amount",
			order23: "refund reasons",
			order24: "please select",
			order25: "refund instructions",
			order26: "please enter a refund description (optional)",
			order27: "submission",
			order28: "negotiation record",
			order29: "negotiation record",
			order30: "buyer",
			order31: "seller",
			order32: "platform",
			order33: "merchandise",
			order34: "number",
			order35: "refund amount",
			order36: "refund reasons",
			order37: "refund amount",
			order38: "reback number",
			order39: "application time",
			order40: "refund time",
			order41: "did not get the refund information for this order",
			order42: "please select a refund reason",
			order43: "After cancellation, this application will be closed. If there are still problems in the follow-up, you can initiate the application again",
			order44: "tip",
			order45: "confirms the revocation",
			order46: "does not revoke",
			order47: "revocation succeeded",
			order48: "order list",
			order49: "order details",
			order50: "order status",
			order51: "payment",
			order52: "evaluation",
			order53: "chreview",
			order54: "order information",
			order55: "store",
			order56: "order type",
			order57: "order number",
			order58: "order transaction number",
			order59: "delivery method",
			order60: "creation time",
			order61: "close time",
			order62: "payment method",
			order63: "payment time",
			order64: "store activity",
			order65: "buyers message ",
			order66: "receiving information",
			order67: "consignee",
			order68: "cell phone number",
			order69: "receiving address",
			order70: "invoice information",
			order71: "invoice type",
			order72: "paper invoice",
			order73: "electronic invoice",
			order74: "personal",
			order75: "enterprise",
			order76: "invoice head type",
			order77: "invoice up",
			order78: "invoice content",
			order79: "invoice mail address address",
			order80: "invoice receive mailbox",
			order81: "commodity information",
			order82: "unit price",
			order83: "number",
			order84: "minor count",
			order85: "operation",
			order86: "refund",
			order87: "view reback",
			order88: "commodity amount",
			order89: "shipping",
			order90: "tax and fee",
			order91: "order adjustment",
			order92: "offers",
			order93: "coupon amount",
			order94: "platform coupon amount",
			order95: "to use the balance",
			order96: "real payment",
			order97: "did not get the order information",
			order98: "order list",
			order99: "order details",
			order100: "order status",
			order101: "payment",
			order102: "evaluation",
			order103: "chreview",
			order104: "order information",
			order105: "store",
			order106: "order type",
			order107: "order number",
			order108: "order transaction number",
			order109: "delivery method",
			order110: "creation time",
			order111: "close time",
			order112: "payment method",
			order113: "payment time",
			order114: "store activity",
			order115: "buyers message ",
			order116: "receiving information",
			order117: "consignee",
			order118: "cell phone number",
			order119: "receiving address",
			order120: "invoice information",
			order121: "invoice type",
			order122: "paper invoice",
			order123: "electronic invoice",
			order124: "personal",
			order125: "enterprise",
			order126: "invoice head type",
			order127: "invoice up",
			order128: "invoice content",
			order129: "invoice mail address address",
			order130: "invoice receive mailbox",
			order131: "commodity information",
			order132: "unit price",
			order133: "number",
			order134: "minor count",
			order135: "operation",
			order136: "refund",
			order137: "view reback",
			order138: "commodity amount",
			order139: "shipping",
			order140: "tax and fee",
			order141: "order adjustment",
			order142: "offers",
			order143: "coupon",
			order144: "to use the balance",
			order145: "real payment",
			order146: "did not get the order information",
			order147: "order list",
			order148: "order details",
			order149: "order status",
			order150: "payment",
			order151: "evaluation",
			order152: "chreview",
			order153: "from point",
			order154: "pickup code",
			order155: "business hours",
			order156: "contact information",
			order157: "detailed address",
			order158: "commodity information",
			order159: "unit price",
			order160: "number",
			order161: "minor count",
			order162: "operation",
			order163: "order information",
			order164: "store",
			order165: "order type",
			order166: "order number",
			order167: "order transaction number",
			order168: "delivery method",
			order169: "creation time",
			order170: "close time",
			order171: "payment method",
			order172: "payment time",
			order173: "store activity",
			order174: "buyers message ",
			order175: "receiving information",
			order176: "consignee",
			order177: "cell phone number",
			order178: "receiving address",
			order179: "invoice information",
			order180: "invoice type",
			order181: "paper invoice",
			order182: "electronic invoice",
			order183: "personal",
			order184: "enterprise",
			order185: "invoice head type",
			order186: "invoice up",
			order187: "invoice content",
			order188: "invoice mail address address",
			order189: "invoice receive mailbox",
			order190: "commodity information",
			order191: "unit price",
			order192: "number",
			order193: "minor count",
			order194: "operation",
			order195: "refund",
			order196: "view reback",
			order197: "commodity amount",
			order198: "shipping",
			order199: "tax and fee",
			order200: "order adjustment",
			order201: "offers",
			order202: "coupon",
			order203: "to use the balance",
			order204: "real payment",
			order205: "did not get the order information",
			order206: "order list",
			order207: "order details",
			order208: "order status",
			order209: "payment",
			order210: "evaluation",
			order211: "chreview",
			order212: "application information",
			order213: "verification code",
			order214: "write status: cancelled",
			order215: "application time",
			order216: "order information",
			order217: "store",
			order218: "order type",
			order219: "order number",
			order220: "order transaction number",
			order221: "delivery method",
			order222: "creation time",
			order223: "close time",
			order224: "payment method",
			order225: "payment time",
			order226: "store activity",
			order227: "buyers message ",
			order228: "receiving information",
			order229: "consignee",
			order230: "cell phone number",
			order231: "receiving address",
			order232: "invoice information",
			order233: "invoice type",
			order234: "paper invoice",
			order235: "electronic invoice",
			order236: "personal",
			order237: "enterprise",
			order238: "invoice head type",
			order239: "invoice up",
			order240: "invoice content",
			order241: "invoice mail address address",
			order242: "invoice receive mailbox",
			order243: "commodity information",
			order244: "unit price",
			order245: "number",
			order246: "minor count",
			order247: "operation",
			order248: "refund",
			order249: "view reback",
			order250: "commodity amount",
			order251: "shipping",
			order252: "tax and fee",
			order253: "order adjustment",
			order254: "offers",
			order255: "coupon",
			order256: "to use the balance",
			order257: "real payment",
			order258: "did not get the order information",
			order259: "description match",
			order260: "Praise",
			order261: "medium evaluation",
			order262: "bad comments",
			order263: "please enter your evaluation here",
			order264: "please enter your chase review here",
			order265: "6 in total, which can be uploaded",
			order266: "page",
			order267: "delivery services",
			order268: "description match",
			order269: "service attitude",
			order270: "anonymous",
			order271: "submission",
			order272: "The maximum number of uploaded pictures is six",
			order273: "merchandise reviews can't be empty. ",
			order274: "merchandise reviews can't be empty. ",
			order275: "evaluation for success",
			order276: "write record",
			order277: "commodity information",
			order278: "unit price",
			order279: "number",
			order280: "verification",
			order281: "No relevant orders",
			order282: "previous page",
			order283: "next page",
			order284: "write record",
			order285: "verification verification",
			order286: "number",
			order287: "application type",
			order288: "write status: cancelled",
			order289: "application",
			order290: "application time",
			order291: "confirms using",
			order292: "desk",
			order293: "input write code",
			order294: "write",
			order295: "please enter the write code",
			order296: "confirmation",
			order297: "refund / after-sale",
			order298: "refund details",
			order299: "if the merchant refuses, you can restart the application",
			order300: "will give you by application and refund",
			order301: "if the merchant fails to process it overdue, the platform will automatically apply and refund you",
			order302: "will refund you if the merchant confirms the receipt",
			order303: "if the merchant refuses to receive the goods, the refund will close and you can reinitiate a refund",
			order304: "negotiation record",
			order305: "return address",
			order306: "merchandise",
			order307: "number",
			order308: "refund amount",
			order309: "refund method",
			order310: "for a refund only",
			order311: "refund return",
			order312: "refund reasons",
			order313: "refund amount",
			order314: "reback number",
			order315: "application time",
			order316: "refund time",
			order317: "platform rights protection",
			order318: "input shipping logistics",
			order319: "logistics company",
			order320: "please enter the logistics company",
			order321: "logistics order number",
			order322: "please enter the logistics order number",
			order323: "logistics instructions",
			order324: "select",
			order325: "take, eliminate",
			order326: "is true, definite",
			order327: "did not get the refund information for this order",
			order328: "please enter the logistics company",
			order329: "please enter the logistics order number",
			order330: "did not get the refund information for this order",
			order331: "After cancellation, this application will be closed. If there are still problems in the follow-up, you can initiate the application again",
			order332: "tip",
			order333: "confirms the revocation",
			order334: "does not revoke",
			order335: "revocation succeeded",
			order336: "my order",
			order337: "order details",
			order338: "refund",
			order339: "merchandise",
			order340: "number",
			order341: "amount",
			order342: "reback type",
			order343: "refund requires no return",
			order344: "return reback",
			order345: "refund amount",
			order346: "refund reasons",
			order347: "please select",
			order348: "refund instructions",
			order349: "please enter a refund description (optional)",
			order350: "submission",
			order351: "did not get the refund information for this order",
			order352: "application successfully",
			order353: "please select a refund reason",
			order354: "order list",
			order355: "logistics details",
			order356: "shipping order number",
			order357: "logistics company",
			order358: "did not get the order parcel information",
			order359: "my order",
			order360: "full order",
			order361: "commodity information",
			order362: "unit price",
			order363: "number",
			order364: "real payment",
			order365: "order status",
			order366: "operation",
			order367: "order number",
			order368: "order details",
			order369: "evaluation",
			order370: "chreview",
			order371: "No relevant orders",
			order372: "previous page",
			order373:"payment proof",
			order374:"Unsure"
		},
		pay:{
			pay1: "your order was successfully submitted and is pending processing",
			pay2: "amount payable",
			pay3: "order information",
			pay4: "transaction order number",
			pay5: "order content",
			pay6: "order amount",
			pay7: "creation time",
			pay8: "payment method",
			pay9: "merchant has not configured the payment method",
			pay10: "pay immediately",
			pay11: "before completes the payment, please click on the button below according to your situation",
			pay12: "returns to the homepage",
			pay13: "has completed the payment",
			pay14: "please scan the wechat code to pay",
			pay15: "alipay pay",
			pay16: "wechat pay",
			pay17: "payment success",
			pay18: "payment failed",
			pay19: "payment amount",
			pay20: "member centre",
			pay21: "returns to the homepage",
			pay22: "not no payment information",
			pay23: 'upload payment proof',
			pay24: 'kindly wait patiently for the customer service to confirm the payment!',
			pay25: 'Bank Name',
			pay26: 'Account Bank',
			pay27: 'Bank Account'
		},
		promotion:{
			combo:{
				payment:{
					payment1: "receiving address",
					payment2: "add a receiving address",
					payment3: "editor",
					payment4:" sure you want to delete the address?",
					payment5: "delete",
					payment6: "more shipping address",
					payment7: "needs to fill in your mobile phone number to facilitate merchants to contact you",
					payment8: "cell phone number",
					payment9: "please enter your cell phone number",
					payment10: "add a receiving address",
					payment11: "edit receiving address",
					payment12: "name",
					payment13: "consignenee name",
					payment14: "cell phone",
					payment15: "consignee mobile phone number",
					payment16: "phone number",
					payment17: "consignee landline (optional)",
					payment18: "region",
					payment19: "please select the province",
					payment20: "please select the city",
					payment21: "please select the district / county",
					payment22: "detailed address",
					payment23: "positioning communities, streets, office buildings",
					payment24: "take, eliminate",
					payment25: "is true, definite",
					payment26: "add invoice information",
					payment27: "invoice type",
					payment28: "invoice head type",
					payment29: "please fill in the head-up name",
					payment30: "please fill in the taxpayer id number",
					payment31: "please fill in the invoice mailing address",
					payment32: "please fill in the receiving mailbox",
					payment33: "invoice content",
					payment34: "office supplies",
					payment35: "digital",
					payment36: "Payment with balance?",
					payment37: "Unused balance",
					payment38: "Use balance",
					payment39: "merchandise",
					payment40: "merchandise",
					payment41: "price",
					payment42: "number",
					payment43: "delivery method",
					payment44: "please add shipping address",
					payment45: "merchants do not configured distribution mode",
					payment46: "coupon",
					payment47: "selected coupon, offer",
					payment48: "does not use the coupons",
					payment49: "store offers",
					payment50: "full minus",
					payment51: "full package mail",
					payment52: "event offers",
					payment53: "combination package",
					payment54: "offers",
					payment55: "buyers message ",
					payment56: "recommends coordinating with merchants before leaving a message",
					payment57: "invoice",
					payment58: "no invoice",
					payment59: "invoicing",
					payment60: "platform coupons",
					payment61: "commodity amount",
					payment62: "shipping",
					payment63: "tax and fee",
					payment64: "offers",
					payment65: "store coupons",
					payment66: "platform coupons",
					payment67: "to use the balance",
					payment68: "piece, amount payable",
					payment69: "order settlement",
					payment70: "name",
					payment71: "address",
					payment72: "business hours",
					payment73: "select platform coupons",
					payment74: "name",
					payment75: "offers",
					payment76: "uses the",
					payment77:" full {{scope.row.at_least}} is available ",
					payment78: "any amount is available",
					payment79: "term",
					payment80: "cancel",
					payment81: "to confirm the selection",
					payment82: "select coupons",
					payment83: "time of service",
					payment84: "select time",
					payment85: "close",
					payment86: "to confirm the selection",
					payment87: "to use the balance",
					payment88: "for your account security, please set the payment password",
					payment89: "can be set'in member center', 'account security', 'payment password'",
					payment90: "Not set temporarily",
					payment91: "set up now",
					payment92: "payment password",
					payment93: "forgets the password",
					payment94: "please enter your mobile phone number",
					payment95: "Mobile phone number format error",
					payment96: "paper invoice",
					payment97: "electronic invoice",
					payment98: "personal",
					payment99: "enterprise",
					payment100: "please enter the consignee",
					payment101: "length in 1 to 20 characters",
					payment102: "please select the province",
					payment103: "please select the city",
					payment104: "please select the district / county",
					payment105: "please enter a detailed address",
					payment106: "please select the invoice type",
					payment107: "please select the invoice header type",
					payment108: "please select the invoice content",
					payment109: "please fill in the head-up name",
					payment110: "please fill in the taxpayer id number",
					payment111: "please fill in the invoice mailing address",
					payment112: "please fill in the invoice receipt mailbox",
					payment113: "did not get the data required to create the order",
					payment114: "full",
					payment115: "is available",
					payment116: "any amount is available",
					payment117: "order submission in",
					payment118: "please first select your shipping address",
					payment119: "The store has not set the delivery method",
					payment120: "stores have no pickup stores, please choose another delivery method",
					payment121: "payment in"
				},
				combo1: "commodity information",
				combo2: "price",
				combo3: "number",
				combo4: "underinventory, remaining",
				combo5: "purchase quantity",
				combo6: "save you on",
				combo7: "package price",
				combo8: "buy now"
			},
			groupbuy:{
				detail:{
					detail1: "distance ends only",
					detail2: "this video is not currently available, please try again later",
					detail3: "Group purchase plug-in not installed",
					detail4: "Activity ended",
					detail5: "part",
					detail6: "you have not yet been logged in",
					detail7: "merchandise is sold out",
					detail8: "purchases cannot be 0",
					detail9: "group-buying campaign is over",
					detail10: "cancel succeeded",
					detail11: "focus on success",
					detail12: "video",
					detail13: "picture",
					detail14: "focuses on the merchandise",
					detail15: "contact customer service",
					detail16: "group buying",
					detail17: "day",
					detail18: "hours",
					detail19: "minutes",
					detail20: "seconds",
					detail21: "group purchase price",
					detail22: "original price",
					detail23: "cumulative evaluation",
					detail24: "Cumulative sales",
					detail25: "express free mail",
					detail26: "express is unavoidable mail",
					detail27: "delivery to",
					detail28: "please select the distribution address",
					detail29: "please select the province",
					detail30: "please select the city",
					detail31: "please select the district / county",
					detail32: "services",
					detail33: "ships and provides after-sales service",
					detail34: "number",
					detail35: "inventory",
					detail36: "start purchase",
					detail37: "immediately snapped up the",
					detail38: "the item has fallen off",
					detail39: "qr code picture",
					detail40: "guaranteed services",
					detail41: "meets the premise of 7 days of return application without reason, package goods need to the buyer to bear the return postage, non-package goods need the buyer to bear the delivery and return postage",
					detail42: "7 day",
					detail43: "goods support genuine warranty services",
					detail44: "genuine guarantee",
					detail45: "Delivery within 2 hours after payment",
					detail46: "two-hour shipping",
					detail47: "return commitment",
					detail48: "trial center",
					detail49: "entity validation",
					detail50: "if you have any quality problems, inconsistent description or no receipt, you will have the right to apply for a refund or return of the goods, and the postage will be borne by the seller.",
					detail51: "consumer association guarantee",
					detail52: "is proprietary",
					detail53: "store rating",
					detail54: "commodity description",
					detail55: "seller service",
					detail56: "shipping speed",
					detail57: "contact number",
					detail58: "enters the shop",
					detail59: "unfollow",
					detail60: "focus on the store",
					detail61: "merchandise details",
					detail62: "commodity properties",
					detail63: "commodity evaluation",
					detail64: "all reviews",
					detail65: "store home reply",
					detail66: "additional evaluation",
					detail67: "previous page",
					detail68: "next page",
					detail69: "this product has not been evaluated oh"
				},
				payment:{
					payment1: "receiving address",
					payment2: "add a receiving address",
					payment3: "editor",
					payment4: "delete",
					payment5: "more shipping address",
					payment6: "needs to fill in your mobile phone number to facilitate merchants to contact you",
					payment7: "cell phone number",
					payment8: "please enter your cell phone number",
					payment9: "add a receiving address",
					payment10: "edit receiving address",
					payment11: "name",
					payment12: "consignenee name",
					payment13: "cell phone",
					payment14: "consignee mobile phone number",
					payment15: "phone number",
					payment16: "consignee landline number (optional)",
					payment17: "region",
					payment18: "please select the province",
					payment19: "please select the city",
					payment20: "please select the district / county",
					payment21: "detailed address",
					payment22: "positioning communities, streets, office buildings",
					payment23: "take, eliminate",
					payment24: "is true, definite",
					payment25: "add invoice information",
					payment26: "invoice type",
					payment27: "invoice head type",
					payment28: "please fill in the head-up name",
					payment29: "please fill in the taxpayer id number",
					payment30: "please fill in the invoice mailing address",
					payment31: "please fill in the receiving mailbox",
					payment32: "invoice content",
					payment33: "office supplies",
					payment34: "digital",
					payment35: "take, eliminate",
					payment36: "definite",
					payment37: "Payment with balance?",
					payment38: "Unused balance",
					payment39: "Use balance goods",
					payment40: "price",
					payment41: "number",
					payment42: "delivery method",
					payment43: "please add shipping address",
					payment44: "merchants do not configured distribution mode",
					payment45: "store activity",
					payment46: "group buying",
					payment47: "starting from pieces, enjoy the group purchase price",
					payment48: "buyers message ",
					payment49: "recommends coordinating with merchants before leaving a message",
					payment50: "invoice",
					payment51: "no invoice",
					payment52: "invoicing",
					payment53: "commodity amount",
					payment54: "shipping",
					payment55: "tax and fee",
					payment56: "offers",
					payment57: "to use the balance",
					payment58: "piece, amount payable",
					payment59: "order settlement",
					payment60: "select store",
					payment61: "name",
					payment62: "address",
					payment63: "business hours",
					payment64: "time of service",
					payment65: "select time",
					payment66: "close",
					payment67: "to confirm the selection",
					payment68: "to use the balance",
					payment69: "for your account security, please set the payment password",
					payment70: "can be set'in member center', 'account security', 'payment password'",
					payment71: "Not set temporarily",
					payment72: "set up now",
					payment73: "payment password",
					payment74: "forgets the password",
					payment75: "please enter your mobile phone number",
					payment76: "Mobile phone number format error",
					payment77: "paper invoice",
					payment78: "electronic invoice",
					payment79: "personal",
					payment80: "enterprise",
					payment81: "please enter the consignee",
					payment82: "length in 1 to 20 characters",
					payment83: "please select the province",
					payment84: "please select the city",
					payment85: "please select the district / county",
					payment86: "please enter a detailed address",
					payment87: "please select the invoice type",
					payment88: "please select the invoice header type",
					payment89: "please select the invoice content",
					payment90: "please fill in the head-up name",
					payment91: "please fill in the taxpayer id number",
					payment92: "please fill in the invoice mailing address",
					payment93: "please fill in the invoice receipt mailbox",
					payment94: "select delivery time",
					payment95: "did not get the data required to create the order",
					payment96: "order submission in",
					payment97: "please enter your cell phone number",
					payment98: "please enter the correct cell phone number",
					payment99: "please first select your shipping address",
					payment100: "store",
					payment101: "does not set the delivery mode",
					payment102: "has no pick-up stores, please choose another delivery method",
					payment103: "payment in"
				},
				groupbuy1: "group buying is in progress",
				groupbuy2: "group purchase price",
				groupbuy3: "is not in the group purchase of goods, go to the first page to see.",
				groupbuy4: "previous page",
				groupbuy5: "next page",
				groupbuy6: "Group purchase plug-in not installed"
			},
			seckill:{
				detail:{
					detail1: "distance ends only",
					detail2: "this video is not currently available, please try again later",
					detail3: "seckill plugin is not installed",
					detail4: "limited seconds kill activity has not yet started",
					detail5: "you have not yet been logged in",
					detail6: "merchandise is sold out",
					detail7: "purchases cannot be 0",
					detail8: "Activity ended",
					detail9: "seconds kill activity ended",
					detail10: "cancel succeeded",
					detail11: "focus on success",
					detail12: "video",
					detail13: "picture",
					detail14: "focuses on the merchandise",
					detail15: "contact customer service",
					detail16: "limited seconds kill",
					detail17: "day",
					detail18: "hours",
					detail19: "minutes",
					detail20: "seconds",
					detail21: "second kill",
					detail22: "original price",
					detail23: "cumulative evaluation",
					detail24: "Cumulative sales",
					detail25: "shipping",
					detail26: "express free mail",
					detail27: "express is unavoidable mail",
					detail28: "delivery to",
					detail29: "please select the distribution address",
					detail30: "please select the province",
					detail31: "please select the city",
					detail32: "please select the district / county",
					detail33: "services",
					detail34: "ships and provides after-sales service",
					detail35: "number",
					detail36: "inventory",
					detail37: "immediately snapped up the",
					detail38: "the item has fallen off",
					detail39: "merchant services",
					detail40: "guaranteed services",
					detail41: "meets the premise of 7 days of return application without reason, package goods need to the buyer to bear the return postage, non-package goods need the buyer to bear the delivery and return postage",
					detail42: "7 day",
					detail43: "genuine guarantee",
					detail44: "two-hour shipping",
					detail45: "return commitment",
					detail46: "trial center",
					detail47: "entity validation",
					detail48: "if you have any quality problems, inconsistent description or no receipt, you will have the right to apply for a refund or return of the goods, and the postage will be borne by the seller.",
					detail49: "Consumer Association guarantee",
					detail50: "is proprietary",
					detail51: "store rating",
					detail52: "commodity description",
					detail53: "seller service",
					detail54: "shipping speed",
					detail55: "points",
					detail56: "contact number",
					detail57: "enters the shop",
					detail58: "unfollow",
					detail59: "focus on the store",
					detail60: "merchandise details",
					detail61: "commodity properties",
					detail62: "commodity evaluation",
					detail63: "all reviews",
					detail64: "store home reply",
					detail65: "additional evaluation",
					detail66: "store home reply",
					detail67: "previous page",
					detail68: "next page",
					detail69: "this product has not been evaluated oh"
				},
				list:{
					list1: "please look forward to",
					list2: "begin in a minute",
					list3: "snap up",
					list4: "has ended",
					list5: "please look forward to",
					list6: "second kill",
					list7: "immediately snapped up the",
					list8: "previous page",
					list9: "next page",
					list10: "has no seconds to kill the goods, go to the home page",
					list11: "distance ends only",
					list12: "Second kill plug-in not installed",
					list13: "The second kill is over",
					list14: "seckill has not started",
					list15: "activity has ended seconds kill activity has not yet opened, please look forward to"
				},
				payment:{
					payment1: "receiving address",
					payment2: "add a receiving address",
					payment3: "editor",
					payment4: "is sure you want to delete the address",
					payment5: "delete",
					payment6: "more shipping address",
					payment7: "needs to fill in your mobile phone number to facilitate merchants to contact you",
					payment8: "cell phone number",
					payment9: "please enter your cell phone number",
					payment10: "add a receiving address",
					payment11: "edit receiving address",
					payment12: "name",
					payment13: "consignenee name",
					payment14: "cell phone",
					payment15: "consignee mobile phone number",
					payment16: "phone number",
					payment17: "consignee landline (optional)",
					payment18: "region",
					payment19: "please select the province",
					payment20: "please select the city",
					payment21: "please select the district / county",
					payment22: "positioning communities, streets, office buildings",
					payment23: "take, eliminate",
					payment24: "is true, definite",
					payment25: "add invoice information",
					payment26: "invoice type",
					payment27: "invoice head type",
					payment28: "please fill in the head-up name",
					payment29: "please fill in the taxpayer id number",
					payment30: "please fill in the invoice mailing address",
					payment31: "please fill in the receiving mailbox",
					payment32: "invoice content",
					payment33: "office supplies",
					payment34: "digital",
					payment35: "take, eliminate",
					payment36: "is true, definite",
					payment37: "Use the balance to pay",
					payment38: "Unused balance",
					payment39: "to use the balance",
					payment40: "merchandise",
					payment41: "price",
					payment42: "number",
					payment43: "delivery method",
					payment44: "please add shipping address",
					payment45: "merchants do not configured distribution mode",
					payment46: "store activity",
					payment47: "limited seconds kill",
					payment48: "buyers message ",
					payment49: "invoice",
					payment50: "no invoice",
					payment51: "invoicing",
					payment52: "commodity amount",
					payment53: "shipping",
					payment54: "tax and fee",
					payment55: "offers",
					payment56: "to use the balance",
					payment57: "piece, amount payable",
					payment58: "order settlement",
					payment59: "name",
					payment60: "address",
					payment61: "business hours",
					payment62: "time of service",
					payment63: "select time",
					payment64: "close",
					payment65: "to confirm the selection",
					payment66: "to use the balance",
					payment67: "for your account security, please set the payment password",
					payment68: "can be set' in member center', 'account security', 'payment password'",
					payment69: "Not set temporarily",
					payment70: "set up now",
					payment71: "forgets the password",
					payment72: "please enter your mobile phone number",
					payment73: "Mobile phone number format error",
					payment74: "paper invoice",
					payment75: "electronic invoice",
					payment76: "personal",
					payment77: "enterprise",
					payment78: "please enter the consignee",
					payment79: "length in 1 to 20 characters",
					payment80: "please select the province",
					payment81: "please select the city",
					payment82: "please select the district / county",
					payment83: "please enter a detailed address",
					payment84: "please select the invoice type",
					payment85: "please select the invoice header type",
					payment86: "please select the invoice content",
					payment87: "please fill in the head-up name",
					payment88: "please fill in the taxpayer id number",
					payment89: "please fill in the invoice mailing address",
					payment90:" please fill in the invoice receipt mailbox select delivery time did not get the data needed to create the order!",
					payment91: "did not get the data required to create the order",
					payment92: "order submission in",
					payment93: "please enter your cell phone number",
					payment94: "please enter the correct cell phone number",
					payment95: "please first select your shipping address",
					payment96: "store",
					payment97: "does not set the delivery mode",
					payment98: "store",
					payment99: "has no pickup stores, please choose another delivery method"
				}
			
			},
			topic:{
				topic1: "special event details",
				topic2: "commodity details"
			}
		},
		shop:{
			components:{
				components1: "location",
				components2: "sales",
				components3: "follow members"
			},
			list:{
				list1: "description matches the",
				list2: "service attitude",
				list3: "shipping speed",
				list4: "phone number",
				list5: "address",
				list6: "unfollow",
				list7: "focus on the store",
				list8: "search, merchandise",
				list9: "search result",
				list10: "integrated",
				list11: "sales",
				list12: "price",
				list13: "package mail",
				list14: "minimum price",
				list15: "top price",
				list16: "determines that",
				list17: "person payment",
				list18: "package mail",
				list19: "limited time discount",
				list20: "didn't find the item you want.try it with different conditions ",
				list21: "previous page",
				list22: "next page",
				list23: "lacks store id",
				list24: "unfocus success",
				list25: "token does not exist",
				list26: "focus on success"
			},
			shop1: "is proprietary",
			shop2: "description match",
			shop3: "service attitude",
			shop4: "shipping speed",
			shop5: "phone number",
			shop6: "address",
			shop7: "unfollow",
			shop8: "focus on the store",
			shop9: "search, merchandise",
			shop10: "this store recommended",
			shop11: "limited time discount",
			shop12: "previous page",
			shop13: "next page",
			shop14: "unfocus success",
			shop15: "token does not exist",
			shop16: "focus on success",
			shop17: "search results",
			shop18: "integrated",
			shop19: "sales",
			shop20: "credit",
			shop21: "search, shop",
			shop22: "main camp",
			shop23: "score",
			shop24: "people follow",
			shop25: "didn't find the store you want.try it with different conditions ",
			shop26: "previous page",
			shop27: "next page",
			live:{
				live1:"live list",
				live2:"This live broadcast has ended. Please watch other live broadcasts",
				live3:"The live broadcast has not started yet. Please wait for the anchor to start the live broadcast",
				live4:"Live product list",
				live5:"No commodity",
				live6:"Not live broadcast",
				live7:"Living",
				live8:"End of live broadcast"			
			}
		}
	}
}
