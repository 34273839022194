<template>
    <div>
        <div class="header-top">
            <div class="top-content">
                <div class="top-left" v-if="addonIsExit.city">
                    <span>
                        <i class="el-icon-location"></i>
                        <template v-if="city && city.title">
                            {{ city.title }}
                        </template>
                        <template v-else>
                            {{ $lang('layout.components.components25') }}
                        </template>
                    </span>
                    <el-tag size="mini" effect="plain" type="info" class="change-city" @click="$router.push('/change_city')">{{ $lang('layout.components.components26') }}</el-tag>
                </div>
				
				<div class="top-left" >
					<span style="margin-left: 10px;">
						{{ $lang('common.currentlanguage') }}： {{langList[langIndex].name}}
					</span>
				    <span style="margin-left: 10px;">
						<el-dropdown @command="handleCommand">
							<span class="el-dropdown-link" style="color: #409EFF;">
							    {{ $lang('common.language') }}
							    <i class="el-icon-arrow-down el-icon--right"></i>
							</span>
						  <el-dropdown-menu slot="dropdown" >
						    <el-dropdown-item v-for="(list,i) in langList" :command="i">{{list.name}}</el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>				
				        
				    </span>
				    
				</div>
				
                <div class="top-right">
                    <router-link v-if="!logined" to="/login" class="ns-text-color">{{ $lang('layout.components.components27') }}</router-link>
                    <div v-if="logined" class="member-info">
                        <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
                        <span @click="logout">{{ $lang('layout.components.components28') }}</span>
                    </div>
                    <router-link v-if="!logined" to="/register">{{ $lang('layout.components.components29') }}</router-link>
                    <router-link to="/member/order_list">{{ $lang('layout.components.components30') }}</router-link>
					<router-link to="/member/footprint">{{ $lang('layout.components.components31') }}</router-link>
					<router-link to="/member/collection">{{ $lang('layout.components.components32') }}</router-link>
					
                    <router-link to="/cms/help">{{ $lang('layout.components.components33') }}</router-link>
					<router-link to="/cms/notice">{{ $lang('layout.components.components34') }}</router-link>
                    <el-dropdown v-if="qrcode">
                        <span class="el-dropdown-link">
                            {{ $lang('layout.components.components35') }}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div class="mobile-qrcode"><img src="@/assets/images/index/oseShop.png" /></div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { getToken } from "@/utils/auth"

    export default {
        props: {},
        data() {
            return {
				langList:[],
				langIndex:0				
			}
        },
        created() {
            this.$store.dispatch("site/qrCodes")
            this.$store.dispatch("member/member_detail")
            this.$store.dispatch("site/defaultFiles")
            this.$store.dispatch("site/addons")
			this.initLang();
			// window.langConfig.change('en-us');
        },
        mounted() {},
        watch: {},
        methods: {
            logout() {
                this.$store.dispatch("member/logout")
            },
			initLang() {
				//获取语言列表
				this.langList = window.langConfig.list();
				var ss=localStorage.getItem('lang') || 'md-mm';
				for(var i=0;i<this.langList.length;i++){
					var s=this.langList[i].value;
					if(ss==s){
						this.langIndex=i;
					}
				}
				
			},
			handleCommand(command) {//debugger;
				this.langIndex=command;
				window.langConfig.change(this.langList[this.langIndex].value);
				debugger;
				//this.$router.push({path:"/index",query:{time:new Date().getTime()}})
				window.location.reload();
		    }
        },
        components: {},
        computed: {
            ...mapGetters(["wapQrcode", "member", "addonIsExit", "city"]),
            qrcode: function() {
                return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
            },
            logined: function() {
                return this.member !== undefined && this.member !== "" && this.member !== {}
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-top {
        width: 100%;
        height: 31px;
        font-size: 12px;
        background-color: #f5f5f5;

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }
        .top-content {
            width: $width;
            height: 100%;
            margin: 0 auto;
            .top-left {
                height: 100%;
                float: left;
                height: 100%;
                line-height: 31px;
                .change-city {
                    cursor: pointer;
                    margin-left: 5px;
                    &:hover {
                        color: $base-color;
                        border-color: $base-color;
                    }
                }
            }
            .top-right {
                height: 100%;
                float: right;
                font-size: $ns-font-size-sm;
                color: $ns-text-color-black;
                a {
                    float: left;
                    line-height: 31px;
                    padding: 0 10px;
                    &:hover {
                        color: $base-color;
                    }
                }
                div {
                    float: left;
                    height: 100%;
                    margin-left: 10px;
                    cursor: pointer;
                    line-height: 31px;
                    padding: 0 5px;

                    &.member-info {
                        margin-left: 0;
                        span:first-child {
                            margin-right: 10px;
                        }
                    }
                    span:hover {
                        color: $base-color;
                    }
                    &.el-dropdown:hover {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .mobile-qrcode {
        padding: 10px 0;
    }
	.router-link-active{
		color:$base-color;
	}
</style>
